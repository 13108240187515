import QuizIcon from '@mui/icons-material/Quiz';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import { TableButton } from '@silinfo/front-end-template';
import useOpen from '../../../../utils/useOpen';
import ContentCourse from './Content';
import ContentStudent from './ContentStudent';

interface ViewEvaluationsProps {
    id: string;
    tarStudentId: string;
    tenantId: string;
    view: 'course' | 'student';
}

export default function ViewEvaluations({ id, tarStudentId, tenantId, view }: ViewEvaluationsProps) {
    const openState = useOpen();
    const { isOpen, open, close } = openState;

    const Content = view === 'course' ? ContentCourse : ContentStudent;
    const contentId = view === 'course' ? id : tarStudentId;

    return (
        <Grid item>
            <TableButton color="primary" tooltip="Kurzusértékelések megtekintése" onClick={open}>
                <QuizIcon />
            </TableButton>
            <Dialog open={isOpen} onClose={close} fullWidth fullScreen>
                <Content id={contentId} tenantId={tenantId} openState={openState} />
            </Dialog>
        </Grid>
    );
}
