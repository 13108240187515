import { GridColumns } from '@mui/x-data-grid';
import instance from '../../api';
import { endpoints } from '../../utils/endPoints';
import Operations from './Operations';
import { AxiosResponse } from 'axios';
import Tooltip from '@mui/material/Tooltip';

const header = (tenant: string | null) => {
    return {
        title: 'Kérdőívek',
        project: tenant || 'TAR',
        breadcrumbs: {
            questionnaires: 'Kérdőívek',
        },
    };
};
const columns: GridColumns = [
    {
        headerName: '',
        field: 'indicator',
        sortable: false,
        resizable: false,
        minWidth: 3,
        width: 3,
        renderCell: (params) => {
            if (params.row?.sharedType === false) {
                return <></>;
            }

            return (
                <Tooltip title={'Megosztott kurzus'}>
                    <div style={{ background: '#35A899', width: '10px', height: '100%', marginLeft: '-10px' }} />
                </Tooltip>
            );
        },
    },
    {
        headerName: 'Kérdőív',
        field: 'questionnaire',
        renderCell: (params) => {
            return (
                <>
                    {params.row.courseName} ({params.row.courseCode})
                </>
            );
        },
        align: 'left',
        headerAlign: 'center',
        flex: 3,
        minWidth: 300,
    },
    {
        headerName: 'Kitöltési határidő',
        field: 'deadline',
        align: 'center',
        headerAlign: 'center',
        valueGetter: (params) => params.value || '-',
        flex: 1,
        minWidth: 120,
    },
    {
        headerName: '',
        field: 'operations',
        disableColumnMenu: true,
        sortable: false,
        align: 'center',
        flex: 1,
        renderCell: Operations,
        minWidth: 100,
    },
];

const filter = async (semester: string): Promise<AxiosResponse> => {
    const { data, ...rest } = await instance.get(endpoints.students.questionnaires.list + '?semester=' + semester);
    return { ...rest, data: { data } };
};

export { header, columns, filter };
