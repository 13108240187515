import { theme } from '../../../../utils/theme';
import { SuccessIcon, DisabledIcon, ErrorIcon } from './Icons';
import { TStatusKey } from './types';
import PendingIcon from '@mui/icons-material/Pending';
import EditNoteIcon from '@mui/icons-material/Edit';

export const IconMap: Record<string, JSX.Element> = {
    success: <SuccessIcon />,
    disabled: <DisabledIcon />,
    error: <ErrorIcon />,
    errorStudent: <EditNoteIcon />,
    errorAdministrator: <PendingIcon />,
};

const AVAILABLE = 'available';
const UNAVAILABLE = 'none';
const WAITING = 'wait_to_fill';
const WAITING_STUDENT = 'wait_to_fill_student';
const WAITING_ADMINISTRATOR = 'wait_to_fill_administrator';
const COMPLETED = 'filled';
const UPLOAD = 'wait_to_upload';
const UPLOADED = 'uploaded';
const EMPTY = 'empty';

export const STATUS = {
    AVAILABLE,
    UNAVAILABLE,
    WAITING,
    WAITING_STUDENT,
    WAITING_ADMINISTRATOR,
    COMPLETED,
    UPLOAD,
    UPLOADED,
};

const unavailable = {
    value: UNAVAILABLE,
    label: 'Nem elérhető',
};

const available = {
    value: AVAILABLE,
    label: 'Elérhető',
};

const waiting = {
    value: WAITING,
    label: 'Kitöltésre vár',
};

const waitingAdminstrator = {
    value: WAITING_ADMINISTRATOR,
    label: 'Adminisztrátor általi kitöltésre vár',
};

const waitingStudent = {
    value: WAITING_STUDENT,
    label: 'Hallgató általi kitöltésre vár',
};

const waitingAdmin = {
    value: WAITING,
    label: 'Nem elérhető',
};

const completed = {
    value: COMPLETED,
    label: 'Kitöltve',
};

const happened = {
    value: true,
    label: 'Megtörtént',
};

const notHappened = {
    value: false,
    label: 'Még nem történt meg',
};

const upload = {
    value: UPLOAD,
    label: 'Feltöltésre vár',
};

const uploaded = {
    value: UPLOADED,
    label: 'Feltöltve',
};

const empty = {
    value: EMPTY,
    label: '',
};

const previousSummary = {
    disabled: unavailable,
    success: available,
};

const selfAssessmentQuestionnaire = {
    disabled: unavailable,
    success: completed,
    error: waiting,
    errorStudent: waitingStudent,
    errorAdministrator: waitingAdminstrator,
};

const selfAssessmentConversation = {
    disabled: unavailable,
    success: happened,
    error: notHappened,
};

/*
const summary = {
    disabled: unavailable,
    success: completed,
    error: waiting,
};
*/

const universityIndex = {
    error: upload,
    success: uploaded,
    disabled: unavailable,
};

const completionSheet = {
    disabled: unavailable,
    empty,
};

const careerGuidanceSheet = {
    none: unavailable,
    success: available,
};

const commissionEvaluationSheet = {
    disabled: waitingAdmin,
    none: unavailable,
    success: completed,
};

export const colors: Record<Exclude<TStatusKey, 'empty'>, string> = {
    disabled: theme.palette.grey[500],
    success: theme.palette.success.main,
    error: theme.palette.error.main,
    errorStudent: '#004E89',
    errorAdministrator: '#22344C',
};

export const status = {
    'previousSummary': previousSummary,
    'selfAssessmentQuestionnaire': selfAssessmentQuestionnaire,
    'selfAssessmentConversation': selfAssessmentConversation,
    //'summaryDocument' : summary,
    'commissionEvaluationSheet': commissionEvaluationSheet,
    'universityIndex': universityIndex,
    'careerGuidanceSheet': careerGuidanceSheet,
    'completionSheet': completionSheet,
};
