import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import { TableButton } from '@silinfo/front-end-template';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createExportHandleClick, ExportButtonProps } from '../ExportButton';

export default function TableExportButton(props: ExportButtonProps): JSX.Element {
    const { exportContext, setPageLoading, otherLoading, setOtherLoading } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    return (
        <TableButton
            color="success"
            tooltip={props.title || 'Exportálás'}
            disabled={isLoading || otherLoading}
            onClick={createExportHandleClick(
                exportContext,
                dispatch,
                setIsLoading,
                setPageLoading,
                setOtherLoading,
                props.format || 'xlsx',
            )}
        >
            {isLoading ? <CircularProgress size={20} /> : <DownloadIcon />}
        </TableButton>
    );
}
