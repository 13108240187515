import { GridAlignment, GridColDef } from '@mui/x-data-grid-pro';
import { Input } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { Option } from '../../../utils/AppConst';
import { DocumentCellRenderer, OperationsCellRenderer } from './CellRenderers';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TSelfAssessmentDocumentStatus } from './types';
import { ExportContextWithSemester } from './ExportButton';

export const breadcrumbs = {
    administration: 'Önértékelések',
    selfEvaluation: 'Önértékelési riport',
};

export const SESSION_KEY = 'selfAssesment';

export const header = (tenant: string | null) => {
    return {
        title: 'Önértékelési riport',
        breadcrumbs: {},
        project: tenant || 'TAR',
    };
};

export interface IStudentSelfAssessment {
    id: number;
    semester: string;
    student: string;
    previousSummary?: string;
    selfAssessmentQuestionnaire?: string;
    selfAssessmentQuestionnaireFilled?: boolean;
    selfAssessmentConversation?: string;
    summaryDocument?: string;
    universityIndex?: string;
    careerGuidanceSheet?: string;
    completionSheet?: string;
}

export const documentColumns = [
    {
        field: 'previousSummary',
        headerName: 'Korábbi célkitűzés',
    },
    {
        field: 'selfAssessmentQuestionnaire',
        headerName: 'Önértékelés és célkitűzés',
    },
    {
        field: 'selfAssessmentConversation',
        headerName: 'Önértékelési beszélgetés',
    },
    {
        field: 'commissionEvaluation',
        headerName: 'Bizottsági értékelő lap',
    },
    {
        field: 'universityIndex',
        headerName: 'Egyetemi index',
    },
    {
        field: 'careerGuidanceSheet',
        headerName: 'Pályaorientációs lap',
    },
    {
        field: 'completionSheet',
        headerName: 'MCC teljesítési lap',
    },
].map((column) => ({
    ...column,
    align: 'center' as GridAlignment,
    renderCell: DocumentCellRenderer,
}));

export const columns = (toggleRefresh: () => void, exportUniqueContext: ExportContextWithSemester): GridColDef[] =>
    [
        {
            field: 'student',
            headerName: 'Hallgató',
            sortable: true,
            align: 'left' as GridAlignment,
        },
        ...documentColumns,
        {
            field: 'operations',
            headerName: '',
            renderCell: (params: GridRenderCellParams<TSelfAssessmentDocumentStatus>) => (
                <OperationsCellRenderer
                    {...params}
                    toggleRefresh={toggleRefresh}
                    exportUniqueContext={exportUniqueContext}
                />
            ),
        },
    ].map((column) => ({
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        ...column,
    }));

export const filterFields = (options: Record<string, Option[]>): Input[] => [
    {
        name: 'student',
        label: 'Hallgató',
        type: 'multiselect',
        options: options.student,
    },
    {
        name: 'campus',
        label: 'Campus',
        type: 'multiselect',
        options: options.campus,
    },
    {
        name: 'trainingLevel',
        label: 'Képzési szint',
        type: 'multiselect',
        options: options.trainingLevel,
    },
    {
        name: 'collegeYear',
        label: 'Évfolyam',
        type: 'multiselect',
        options: options.collegeYear,
    },
    {
        name: 'trainingProgram',
        label: 'Képzési program',
        type: 'multiselect',
        options: options.trainingProgram,
    },
    {
        name: 'selfEvaluationStatus',
        label: 'Önértékelés és célkitűzés státusza',
        type: 'multiselect',
        options: options.selfEvaluationStatus || [],
    },
    {
        name: 'selfEvaluationConversationStatus',
        label: 'Önértékelési beszélgetés státusza',
        type: 'multiselect',
        options: options.selfEvaluationConversationStatus || [],
    },
    {
        name: 'commissionEvaluationDocumentStatus',
        label: 'Bizottsági értékelő lap státusza',
        type: 'multiselect',
        options: options.commissionEvaluationDocumentStatus || [],
    },
    {
        name: 'universityIndexStatus',
        label: 'Egyetemi index státusza',
        type: 'multiselect',
        options: options.universityIndexStatus || [],
    },
];

const autoload = true as const;

export const asyncFunction = async (data: IStudentSelfAssessment[]): Promise<AxiosResponse> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                data: {
                    data: data,
                    metadata: {
                        'page': 1,
                        'lastPage': 0,
                        'allCount': 0,
                        'filteredCount': 0,
                    },
                },
                config: {},
                headers: {},
                status: 200,
                statusText: 'OK',
            });
        }, 1000);
    });
};

export const tableProps = (
    semester: string,
    options: Record<string, Option[]>,
    toggleRefresh: () => void,
    exportUniqueContext: ExportContextWithSemester,
    tenant?: string,
) => ({
    autoload,
    header: header(tenant || 'TAR'),
    columns: columns(toggleRefresh, exportUniqueContext),
    filter: { fields: filterFields(options) },
    refresh: semester,
    defaultSort: { student: 'asc' as const },
    responseKey: 'data',
    tableProps: {
        columnBuffer: columns(toggleRefresh, exportUniqueContext).length,
        disableSelectionOnClick: true,
        rowHeight: 70,
    },
});

export const tooltipHTML = `
<div>
    <p>A szűrt hallgatók összesített mappáinak letöltése előtt célszerű frissíteni a hallgatók vonatkozó dokumentumait a “Dokumentumok frissítése” gombra kattintva, hogy naprakész adatok jelenjenek meg a mappákban.</p>
</div>
`;
