import PreviewTemplate from '../../../components/PreviewTemplate';
import { endpoints } from '../../../utils/endPoints';
import { useParams } from 'react-router-dom';
import { useCallback, useRef, useState } from 'react';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { setRefreshLocalStorage } from '../../../components/PreviewTemplate/utils';
import selfEvaluationReportService from '../../../services/selfEvalution/report';
import { FormToPrintProps } from '../../../studentPages/SelfAssessment/CellRenderers/Operations/Download/types';
import FormToPrint from '../../../studentPages/SelfAssessment/CellRenderers/Operations/Download/FormToPrint';
import { IResponse } from '../../../components/PreviewTemplate/types';
import { generatePdf, HiddenFormRenderer } from '../../../studentPages/SelfAssessment/utils';

const keyPrefix = 'selfEvaluationReportPreview';

export default function ReportPreview() {
    const { id } = useParams<{ id: string }>();
    const refToPrint = useRef<HTMLDivElement>(null);

    const [data, setData] = useState<IResponse | null>(null);

    const FormToPrintByType = useCallback(
        (formToPrintProps: Omit<FormToPrintProps, 'type'>) => (
            <FormToPrint type={'selfEvaluation'} {...formToPrintProps} />
        ),
        [],
    );

    const handleGenerateAndUploadPdf = useCallback(
        async (values: IForm, formIndex: number, finalization?: boolean) => {
            try {
                const newData = await selfEvaluationReportService.pdfInfo(id ?? '0', values, formIndex);
                setRefreshLocalStorage();
                setData(newData?.data);
                await generatePdf(
                    refToPrint,
                    id ?? '0',
                    'portrait',
                    'adminSelfEvaluation',
                    values,
                    formIndex,
                    finalization ?? false,
                    (id, values, formIndex, finalization) =>
                        selfEvaluationReportService.writeFill(id ?? '0', values, formIndex, !!finalization),
                );
            } catch (error) {
                console.error('Error generating or uploading PDF:', error);
            }
        },
        [id],
    );

    if (!id) return null;

    return (
        <>
            <PreviewTemplate
                type="selfEvaluation"
                url={endpoints.selfEvaluation.report.preview(id)}
                keyPrefix={keyPrefix}
                onSubmit={handleGenerateAndUploadPdf}
            />
            <HiddenFormRenderer refToPrint={refToPrint} data={data} FormToPrintByType={FormToPrintByType} />
        </>
    );
}
