import { IAddress } from '../utils';

const getFirstEmptyIndex = (external: boolean, addresses: IAddress[]) => {
    if (external) {
        return null;
    }

    const addressCount = addresses.length;

    const firstEmpty = addresses.findIndex((address) => address.room === '');
    return firstEmpty === -1 ? addressCount - 1 : firstEmpty;
};

export { getFirstEmptyIndex };
