import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useCallback, useContext, useEffect, useState } from 'react';
import Form from '../../../../../components/Form/Form';
import instance from '../../../../../api';
import { LoadingButton } from '@mui/lab';
import { TextField, useFetch } from '@silinfo/front-end-template';
import { SectionRow } from './SectionGenerator';
import { useMentoring } from './fields';
import { useSelector } from 'react-redux';
import DataTable from './DataTable';
import { RootState } from '../../../../../store';
import { useParams } from 'react-router-dom';
import { endpoints } from '../../../../../utils/endPoints';
import { DynamicSection } from './interfaces';
import { ReadOnlyContext } from './StudentsForm';
import { Option } from '../../../../../utils/AppConst';
import Autocomplete from '@mui/material/Autocomplete';

export default function MentoringField() {
    const [row, setRow] = useState<SectionRow | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const id = useParams().id as string;
    const { count } = useSelector((state: RootState) => state.loading);
    const section: DynamicSection = {
        name: 'Mentoring',
        fields: useMentoring(),
        dynamic: true,
        nameInForm: 'mentoring',
        url: endpoints.masterData.students.mentoring.main.replace('{studentId}', id),
    };
    const readOnly = useContext(ReadOnlyContext);

    const { data: userOptions, loading: usersLoading } = useFetch<Option[]>(
        endpoints.masterData.students.mentoring.list,
        '',
    );
    const [user, setUser] = useState<Option | null>(null);

    const handleUserChange = useCallback(
        (_: unknown, value: Option<string> | null) => {
            setUser(value);
        },
        [setUser],
    );

    const handleSubmit = async () => {
        await instance.post(
            endpoints.masterData.students.mentoring.update
                .replace('{studentId}', id)
                .replace('{studentProcessId}', row?.id || ''),
            { mentorId: user?.value },
        );
        setOpen(false);
        setRow(undefined);
        setRefresh((prev) => !prev);
        setUser(null);
    };

    useEffect(() => {
        if (userOptions && row) {
            const option = userOptions.find((e) => e.label === row.mentorName);
            setUser(option || null);
        }
    }, [row, userOptions]);

    return (
        <>
            <Grid item xs={12} justifyContent="flex-end">
                <Dialog open={open || !!row} fullWidth>
                    <DialogTitle>{'Mentoring szerkesztése'}</DialogTitle>
                    <Form fields={[]} onSubmit={handleSubmit} hideButtons>
                        {(props) => (
                            <Grid item container xs={12}>
                                <Grid item xs={12}>
                                    <DialogContent>
                                        {!usersLoading && (
                                            <Autocomplete
                                                options={userOptions}
                                                value={user}
                                                onChange={handleUserChange}
                                                size="small"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Mentor"
                                                        helperText={props.errors.studentId}
                                                        error={!!props.errors.studentId}
                                                    />
                                                )}
                                            />
                                        )}
                                    </DialogContent>
                                </Grid>
                                <Grid item xs={12}>
                                    <DialogActions>
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                setOpen(false);
                                                setRow(undefined);
                                            }}
                                        >
                                            Mégsem
                                        </Button>
                                        <LoadingButton loading={count > 0} variant="contained" type="submit">
                                            Mentés
                                        </LoadingButton>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        )}
                    </Form>
                </Dialog>
            </Grid>
            <Grid item xs={12} style={{ overflow: 'auto' }}>
                <DataTable
                    section={section}
                    setRow={setRow}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    readOnly={readOnly}
                    responseKey={'data'}
                    hideDeleteButton
                />
            </Grid>
        </>
    );
}
