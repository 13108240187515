import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { FormToPrintProps } from './types';
import { getDescriptionAndDetails } from './utils';

const titleMap = {
    selfEvaluation: 'Önértékelés és célkitűzés',
    summary: 'Összefoglaló dokumentum',
    commissionEvaluation: 'Bizottsági értékelő lap',
    previousSummary: 'Korábbi célkitűzés',
    admission: 'Felvételi kérdőív',
};

export default function FormToPrint({ data, type, givenUser }: FormToPrintProps) {
    const userFromStore = useSelector((state: RootState) => state.auth).user;
    const user = givenUser || userFromStore;
    const title = titleMap[type];
    const descriptionAndDetails = getDescriptionAndDetails(data);

    return (
        <>
            <h1>{title}</h1>
            <h1>
                {user.lastName} {user.firstName}, MCC azonosító: {user.mccId || '-'} ({new Date().toLocaleDateString()})
            </h1>
            <Grid container spacing={4}>
                {descriptionAndDetails.map((groupedAnswersAndDetailsWithDescriptions, fkey) => {
                    return (
                        <>
                            {descriptionAndDetails.length > 1 && (
                                <Grid container item xs={12} key={'form_' + fkey} spacing={2}>
                                    <h4>{data.forms[fkey].name ?? ''}</h4>
                                </Grid>
                            )}
                            {Object.entries(groupedAnswersAndDetailsWithDescriptions).map(([page, panels]) => (
                                <Grid container item xs={12} key={page} spacing={2}>
                                    {Object.entries(panels).map(([panel, answersAndDetails]) => (
                                        <Grid item xs={12} key={panel}>
                                            <fieldset style={{ width: '800px', padding: '16px' }}>
                                                <legend>
                                                    <h3>{panel}</h3>
                                                </legend>
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    spacing={3}
                                                    style={{ marginBottom: '16px' }}
                                                >
                                                    {answersAndDetails.map(({ question, answer }) => (
                                                        <Grid item xs={12} key={question} style={{ margin: '0 8px' }}>
                                                            <Typography fontWeight="bolder">{question}</Typography>
                                                            <Typography>{answer}</Typography>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </fieldset>
                                        </Grid>
                                    ))}
                                </Grid>
                            ))}
                        </>
                    );
                })}
            </Grid>
        </>
    );
}
