import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useContext } from 'react';
import SemesterContext from '../../../../components/SemesterSelector/SemesterContextProvider';
import DownloadTemplate, {
    DownloadTemplateMobile,
    DownloadTemplateProps,
    DownloadTemplatePropsMobile,
} from './Download/DownloadTemplate';
import { openStudentPreview } from './utils';

interface FillQuestionnaireProps extends DownloadTemplateProps {
    errorStatus: string | string[] | boolean | null;
}

interface FillQuestionnaireMobileProps extends DownloadTemplatePropsMobile {
    errorStatus: string | string[] | boolean | null;
}
export default function FillQuestionnaire({
    url,
    filename,
    Icon,
    status,
    errorStatus,
    ...params
}: FillQuestionnaireProps) {
    const semester = useContext(SemesterContext);
    if (params.row?.otherInfo?.selfAssessmentQuestionnaireFilled) {
        url += '?target=2';
    }
    const handleClick = openStudentPreview(semester, url);

    if (Array.isArray(errorStatus) && errorStatus.includes(params.row.status)) {
        return (
            <Tooltip title="Kérdőív kitöltése">
                <IconButton
                    size="small"
                    color="primary"
                    sx={{ borderStyle: 'solid', borderWidth: '1px' }}
                    onClick={handleClick}
                >
                    <DriveFileRenameOutlineIcon />
                </IconButton>
            </Tooltip>
        );
    } else if (typeof errorStatus === 'string' && errorStatus === params.row.status) {
        return (
            <Tooltip title="Kérdőív kitöltése">
                <IconButton
                    size="small"
                    color="primary"
                    sx={{ borderStyle: 'solid', borderWidth: '1px' }}
                    onClick={handleClick}
                >
                    <DriveFileRenameOutlineIcon />
                </IconButton>
            </Tooltip>
        );
    }
    return <DownloadTemplate url={url} filename={filename} Icon={Icon} status={status} {...params} />;
}

export function FillQuestionnaireMobile({
    url,
    filename,
    Icon,
    status,
    errorStatus,
    ...params
}: FillQuestionnaireMobileProps) {
    const semester = useContext(SemesterContext);
    if (params.row?.otherInfo?.selfAssessmentQuestionnaireFilled) {
        url += '?target=2';
    }
    const handleClick = openStudentPreview(semester, url);

    if (Array.isArray(errorStatus) && errorStatus.includes(params.row.status ?? '')) {
        return (
            <Tooltip title="Kérdőív kitöltése">
                <IconButton
                    size="small"
                    color="primary"
                    sx={{ borderStyle: 'solid', borderWidth: '1px' }}
                    onClick={handleClick}
                >
                    <DriveFileRenameOutlineIcon />
                </IconButton>
            </Tooltip>
        );
    } else if (typeof errorStatus === 'string' && errorStatus === params.row.status) {
        return (
            <Tooltip title="Kérdőív kitöltése">
                <IconButton
                    size="small"
                    color="primary"
                    sx={{ borderStyle: 'solid', borderWidth: '1px' }}
                    onClick={handleClick}
                >
                    <DriveFileRenameOutlineIcon />
                </IconButton>
            </Tooltip>
        );
    }
    return <DownloadTemplateMobile url={url} filename={filename} Icon={Icon} status={status} {...params} />;
}
