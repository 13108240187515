import { Input } from '@silinfo/front-end-template';
import axios from 'axios';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import courseListService from '../../../services/courseManagement/courseList';
import { create } from '../../../store/notification';
import { Option } from '../../../utils/AppConst';
import usersService from '../../../services/masterData/users';

type OptionKey = 'semesters' | 'campuses';

export const useCourseFields = (
    isFilter = false,
): {
    loading: boolean;
    fields: Input[];
    options: { [key in OptionKey]: (Option & { default?: boolean })[] };
} => {
    const [courseManagerOptions, setCourseManagerOptions] = useState([]); // TODO: ez hiányzik más
    const [campusOptions, setCampusOptions] = useState([]);
    const [semesterOptions, setSemesterOptions] = useState([]);
    const [courseTypeOptions, setCourseTypeOptions] = useState([]);
    const [trainingProgramOptions, setTrainingProgramOptions] = useState<Option[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useDispatch();

    const fetchData = useCallback(() => {
        axios
            .all([
                courseListService.campuses(isFilter).then((res) => {
                    setCampusOptions(res.data);
                }),
                courseListService.semesters().then((res) => {
                    setSemesterOptions(res.data);
                }),
                courseListService.courseTypes().then((res) => {
                    setCourseTypeOptions(res.data);
                }),
                courseListService.courseManagers().then((res) => {
                    setCourseManagerOptions(res.data);
                }),
                usersService.trainingPrograms().then((res) => {
                    const special = res.data[res.data.length - 1];
                    setTrainingProgramOptions([...res.data.splice(-1)]);
                    setTrainingProgramOptions([...res.data, { value: special.value, label: 'Egyéb szervező' }]);
                }),
            ])
            .catch(() =>
                dispatch(
                    create({
                        type: 'error',
                        message: 'Hiba a betöltés során!',
                    }),
                ),
            )
            .finally(() => {
                setLoading(false);
            });
    }, [dispatch, isFilter]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);
    return {
        loading,
        options: {
            semesters: semesterOptions,
            campuses: campusOptions,
        },
        fields: [
            {
                name: 'courseName',
                label: 'Kurzus neve',
                format: { xs: 12, md: 6, lg: 4 },
            },
            {
                name: 'courseCode',
                label: 'Kurzuskód',
                format: { xs: 12, md: 6, lg: 4 },
            },
            {
                name: 'courseType',
                label: 'Kurzustípus',
                format: { xs: 12, md: 6, lg: 4 },
                type: 'multiselect',
                options: courseTypeOptions,
            },
            {
                name: 'campus',
                label: 'Campus',
                format: { xs: 12, md: 3, lg: 4 },
                type: 'multiselect',
                options: campusOptions,
            },
            {
                name: 'organisers',
                label: 'Szervező',
                format: { xs: 12, md: 3, lg: 4 },
                type: 'multiselect',
                options: trainingProgramOptions,
            },
            {
                name: 'courseManager',
                label: 'Kurzuskezelő',
                format: { xs: 12, md: 3, lg: 4 },
                type: 'multiselect',
                options: courseManagerOptions,
            },
            {
                name: 'instructor',
                label: 'Oktató',
                format: { xs: 12, md: 3, lg: 4 },
            },
            {
                name: 'point',
                label: 'Érték',
                format: { xs: 12, md: 3, lg: 4 },
            },
            {
                name: 'scoringType',
                label: 'Pontozás típusa',
                format: { xs: 12, md: 3, lg: 4 },
                type: 'multiselect',
                options: [
                    {
                        value: 'point',
                        label: 'Pont',
                    },
                    {
                        value: 'copper',
                        label: 'Garas',
                    },
                ],
            },
            {
                name: 'headCount',
                label: 'Aktuális létszám',
                format: { xs: 12, md: 3, lg: 4 },
            },
            {
                name: 'semester',
                label: 'Szemeszter',
                format: { xs: 12, md: 3, lg: 4 },
                type: 'multiselect',
                options: semesterOptions,
            },
            {
                name: 'closed',
                label: 'Zárt',
                format: { xs: 12, md: 3, lg: 4 },
                type: 'multiselect',
                options: [
                    {
                        value: '1',
                        label: 'Igen',
                    },
                    {
                        value: '0',
                        label: 'Nem',
                    },
                ],
            },
            {
                name: 'status',
                label: 'Státusz',
                format: { xs: 12, md: 3, lg: 4 },
                type: 'multiselect',
                options: [
                    { label: 'Nyílt', value: 'open' },
                    { label: 'Zárt', value: 'closed' },
                    { label: 'Értékelhető', value: 'evaluable' },
                    { label: 'Zárható', value: 'closable' },
                ],
            },
            {
                name: 'courseStartDate',
                label: 'Kurzus kezdete',
                format: { xs: 12, md: 6, lg: 4 },
                type: 'date',
            },
            {
                name: 'courseEndDate',
                label: 'Kurzus vége',
                format: { xs: 12, md: 6, lg: 4 },
                type: 'date',
            },
        ],
    };
};
