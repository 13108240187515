import React, { useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TSelfAssessmentDocumentStatus } from '../types';
import { Link } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import RestoreIcon from '@mui/icons-material/Restore';
import Tooltip from '@mui/material/Tooltip';
import useAccessCheck from '../../../../utils/useAccessCheck';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import StatusResetDialog from './StatusResetDialog';
import { endpoints } from '../../../../utils/endPoints';
import api from '../../../../api';
import { create } from '../../../../store/notification';
import CommissionStatusResetDialog from './CommissionStatusResetDialog';
import TableExportButton from '../../../../components/Buttons/TableExportButton';
import Box from '@mui/material/Box';
import { ExportContextWithSemester } from '../ExportButton';
import EndpointProvider from '../../../../components/EndpointProvider';

interface OperationsCellRendererProps extends GridRenderCellParams<TSelfAssessmentDocumentStatus> {
    toggleRefresh: () => void;
    exportUniqueContext: ExportContextWithSemester;
}
const OperationsCellRenderer = ({ toggleRefresh, exportUniqueContext, ...params }: OperationsCellRendererProps) => {
    const { user } = useSelector((state: RootState) => state.auth);
    const editAccess = useAccessCheck(['md_general_students_edit_detailed']);
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [openCommissionDialog, setOpenCommissionDialog] = useState(false);

    const buttonStyles = {
        borderWidth: '1px',
        borderStyle: 'solid',
        width: '36px',
        height: '36px',
        marginLeft: '2px',
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenCommissionDialog = () => {
        setOpenCommissionDialog(true);
    };

    const handleCloseCommissionDialog = () => {
        setOpenCommissionDialog(false);
    };

    const handleResetStatus = (
        newStatus: 'wait_to_fill_student' | 'wait_to_fill_administrator' | 'wait_to_fill_commission',
    ) => {
        api.post(endpoints.selfEvaluation.report.reverseStatus(params.row.id), {
            status: newStatus,
        })
            .then(() => {
                dispatch(create({ type: 'success', message: 'Státusz sikeresen visszaállítva!' }));
                toggleRefresh();
            })
            .catch(() => {
                dispatch(create({ type: 'error', message: 'Sikeretelen státusz visszaállítás!' }));
            });

        handleCloseDialog();
        handleCloseCommissionDialog();
    };

    const dynamicExportContext = {
        ...exportUniqueContext,
        filter: {
            ...exportUniqueContext.filter,
            studentSelfEvaluationId: params.row.id,
        },
    };

    return (
        <>
            {!user.archive && (
                <Link
                    to={
                        editAccess
                            ? clientEndPoints.md_general_students_edit_detailed.replace(
                                  ':id',
                                  '' + params.row.studentId,
                              ) + '?tab=3'
                            : clientEndPoints.md_general_students_list_read_only_show.replace(
                                  ':id',
                                  '' + params.row.studentId,
                              ) + '?tab=3'
                    }
                >
                    <Tooltip title="Hallgató önértékeléseinek megtekintése">
                        <IconButton color="primary" sx={buttonStyles}>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                </Link>
            )}
            <EndpointProvider
                endpoints={['self_assessment_report_fill', 'self_assessment_commission_evaluation_sheet_fill']}
            >
                {params.row.selfAssessmentQuestionnaire === 'filled' && (
                    <>
                        <Tooltip title="Önértékelés és célkitűzés visszaállítása">
                            <IconButton
                                color="primary"
                                sx={{ ...buttonStyles, color: '#FF6961' }}
                                onClick={handleOpenDialog}
                            >
                                <SettingsBackupRestoreIcon />
                            </IconButton>
                        </Tooltip>

                        <StatusResetDialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            onResetStatus={handleResetStatus}
                            studentName={params.row.student}
                            studentEmail={params.row.studentEmail}
                            semester={params.row.questionnaireProgramSemester}
                            isGroupTemplate={params.row.isGroupTemplate}
                        />
                    </>
                )}
                {params.row.commissionEvaluationSheet === 'filled' && (
                    <>
                        <Tooltip title="Bizottsági értékelő lap visszaállítása">
                            <IconButton
                                color="primary"
                                sx={{ ...buttonStyles, color: '#F3CA12' }}
                                onClick={handleOpenCommissionDialog}
                            >
                                <RestoreIcon />
                            </IconButton>
                        </Tooltip>

                        <CommissionStatusResetDialog
                            open={openCommissionDialog}
                            onClose={handleCloseCommissionDialog}
                            onResetStatus={handleResetStatus}
                            studentName={params.row.student}
                            studentEmail={params.row.studentEmail}
                            semester={params.row.questionnaireProgramSemester}
                        />
                    </>
                )}
            </EndpointProvider>
            <Box
                sx={{ display: 'inline-flex', alignItems: 'center', width: '36px', height: '36px', marginLeft: '2px' }}
            >
                <TableExportButton title="Önértékelés exportálása" exportContext={dynamicExportContext} format="zip" />
            </Box>
        </>
    );
};

export default OperationsCellRenderer;
