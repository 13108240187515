import Delete from '@mui/icons-material/Delete';
import DialogContent from '@mui/material/DialogContent';
import { Dialog, SubmitButton, TableButton } from '@silinfo/front-end-template';
import { useDispatch } from 'react-redux';
import courseTargetAudienceService from '../../../../services/courseManagement/courseTargetAudience';
import { create } from '../../../../store/notification';
import { ITargetAudience } from './types';

export default function DeleteTargetAudience({
    courseId,
    row,
    setLoading,
    courseTenant,
}: {
    courseId: number;
    row: ITargetAudience;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    courseTenant: string | null;
}) {
    const dispatch = useDispatch();

    const handleDelete = () => {
        courseTargetAudienceService
            .delete(courseId, row.id, courseTenant)
            .then(() => {
                setLoading((prev) => !prev);
            })
            .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen művelet' })));
    };

    // FIXME: ez a dialog a base-ből kellene, hogy jöjjön
    return (
        <Dialog
            title="Célközönség törlése"
            opener={
                <TableButton color="error" tooltip="Célközönség törlése">
                    <Delete />
                </TableButton>
            }
            action={<SubmitButton onClick={handleDelete}>Igen</SubmitButton>}
            cancelText="Mégsem"
        >
            <DialogContent>
                Biztosan törli a(z) {row.campus as unknown as string} {row.collegeYear as unknown as string}{' '}
                célközönséget?
            </DialogContent>
        </Dialog>
    );
}
