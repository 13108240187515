import { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import questionnaireTemplateService from '../../../services/selfEvalution/questionnaireTemplate';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { endpoints } from '../../../utils/endPoints';
import { setRefreshLocalStorage } from '../../../components/PreviewTemplate/utils';
import CommissionForm from '../../../pages/Administration/QuestionnaireTemplate/CommissionForm';
import { IResponse } from '../../../components/PreviewTemplate/types';
import { FormToPrintProps } from '../CellRenderers/Operations/Download/types';
import FormToPrint from '../CellRenderers/Operations/Download/FormToPrint';
import { generatePdf, HiddenFormRenderer } from '../utils';

export default function CommissionEvaluationSheet() {
    const { id } = useParams() as { id: string };
    const refToPrint = useRef<HTMLDivElement>(null);

    const [data, setData] = useState<IResponse | null>(null);

    const FormToPrintByType = useCallback(
        (formToPrintProps: Omit<FormToPrintProps, 'type'>) => (
            <FormToPrint type={'commissionEvaluation'} {...formToPrintProps} />
        ),
        [],
    );

    const handleGenerateAndUploadPdf = useCallback(
        async (values: IForm, formIndex: number, finalization?: boolean) => {
            try {
                const newData = await questionnaireTemplateService.commissionPdfInfo(id ?? '0', values);
                setRefreshLocalStorage();
                setData(newData?.data);

                await generatePdf(
                    refToPrint,
                    id ?? '0',
                    'portrait',
                    'commissionEvaluation',
                    values,
                    formIndex,
                    finalization ?? false,
                    (id, values, formIndex, finalization) =>
                        questionnaireTemplateService.fillCommissionEvaluation(id ?? '0', values, !!finalization),
                );
            } catch (error) {
                console.error('Error generating or uploading PDF:', error);
            }
        },
        [id],
    );

    return (
        <>
            <CommissionForm
                url={endpoints.selfEvaluation.report.previewCommissionEvaluation(id)}
                keyPrefix="commission_evaluation_fill"
                onSubmit={handleGenerateAndUploadPdf}
                formName="Bizottsági értékelő lap"
                type="commissionEvaluation"
            />
            <HiddenFormRenderer refToPrint={refToPrint} data={data} FormToPrintByType={FormToPrintByType} />
        </>
    );
}
