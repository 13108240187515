import { useEffect } from 'react';

export default function usePreventIndexing(preventIndexing = true) {
    useEffect(() => {
        if (preventIndexing) {
            let meta = document.querySelector('meta[name="robots"]') as HTMLMetaElement;
            let remove = false;

            if (!meta) {
                remove = true;
                meta = document.createElement('meta');
                meta.name = 'robots';
                meta.content = 'noindex';
                document.head.appendChild(meta);
            }

            return () => {
                if (remove && meta && meta.parentNode) {
                    document.head.removeChild(meta);
                }
            };
        }
    }, [preventIndexing]);
}
