import DialogBasic from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useState, Dispatch, SetStateAction } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Form from '../../../../components/Form/Form';
import courseTargetAudienceService from '../../../../services/courseManagement/courseTargetAudience';
import AddTargetAudienceForm from './AddTargetAudienceForm';
import { initialTargetAudienceForm } from './types';
import { useDispatch } from 'react-redux';
import { create } from '../../../../store/notification';
import { transformApiErrors } from '../../../../utils/AppConst';
import { AxiosError } from 'axios';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

export default function AddTargetAudienceButton({
    title,
    submitBtnTitle,
    opener,
    objectId,
    setTableLoading,
    onUpdate,
}: {
    title: string;
    submitBtnTitle: string;
    opener: JSX.Element;
    objectId?: number;
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    onUpdate: () => void;
}) {
    const dispatch = useDispatch();
    const { courseId } = useParams() as { courseId: string };
    const [open, setOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const courseTenant = searchParams.get('courseTenant');

    const classes = useStyles();
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getEvent = async () => {
        if (!objectId) return;
        const { data } = await courseTargetAudienceService.get(+courseId, objectId, courseTenant);
        return {
            ...data,
            data: {
                ...data.data,
            },
        };
    };

    return (
        <>
            {React.cloneElement(opener, {
                onClick: () => {
                    handleOpen();
                },
            })}
            <DialogBasic open={open} onClose={handleClose} fullWidth PaperProps={{ style: { overflow: 'visible' } }}>
                <DialogTitle className={classes.title}>{title}</DialogTitle>
                <DialogContent style={{ marginTop: '1rem' }}>
                    <Form
                        urlParam="courseId"
                        hideButtons
                        fields={[]}
                        onSubmit={(form, formikHelpers) => {
                            if (objectId) {
                                return courseTargetAudienceService
                                    .update({ ...form, course: parseInt(courseId) }, +courseId, objectId, courseTenant)
                                    .then(() => {
                                        handleClose();
                                        setTableLoading((prev) => !prev);
                                    });
                            }
                            courseTargetAudienceService
                                .create({ ...form, course: parseInt(courseId) }, +courseId, courseTenant)
                                .then((resp) => {
                                    const addedCount = resp?.data?.addedCount ?? 0;
                                    const text =
                                        'Sikeres mentés! ' +
                                        (addedCount > 0 ? addedCount + ' db elem került hozzáadásra.' : '');
                                    dispatch(
                                        create({
                                            type: 'success',
                                            message: text,
                                        }),
                                    );
                                    handleClose();
                                    setTableLoading((prev) => !prev);
                                    onUpdate();
                                })
                                .catch((error: AxiosError) => {
                                    if (error.response?.status === 422)
                                        formikHelpers.setErrors(transformApiErrors(error.response?.data.violations));
                                    dispatch(
                                        create({
                                            type: 'error',
                                            message: 'Hiba a mentés során!',
                                        }),
                                    );
                                });
                        }}
                        initialValues={initialTargetAudienceForm}
                        {...(objectId
                            ? {
                                  getterFunction: getEvent,
                              }
                            : {})}
                    >
                        {(props) => (
                            <AddTargetAudienceForm
                                submitBtnTitle={submitBtnTitle}
                                handleClose={handleClose}
                                {...props}
                            />
                        )}
                    </Form>
                </DialogContent>
            </DialogBasic>
        </>
    );
}
