import { default as API } from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';
import { endpoints } from '../../utils/endPoints';
import { fixDateRangeFields } from '../../utils/AppConst';

const selfEvaluationReportService = {
    initiateExport: (form: { format: string }, filter: IForm) => {
        return API.post(endpoints.selfEvaluation.report.export.initiate(filter.semester as string), form, {
            params: fixDateRangeFields(filter),
        });
    },
    checkExport: (id: number, semesterId?: string) =>
        API.get(endpoints.selfEvaluation.report.export.check(semesterId ?? '', id as unknown as string)),
    downloadExport: (id: number, semesterId?: string) =>
        API.get(endpoints.selfEvaluation.report.export.download(semesterId ?? '', id as unknown as string), {
            responseType: 'arraybuffer',
        }),
    studentSearch: (term: string) => API.get(endpoints.selfEvaluation.report.studentSearch + '?term=' + term),
    writeFill: (
        studentSelfEvaluationId: string,
        form: IForm,
        formIndex: number,
        finalization?: boolean,
        pdfGen?: boolean,
    ) =>
        API.post(
            endpoints.selfEvaluation.report.fillForm(studentSelfEvaluationId) +
                '?formIndex=' +
                formIndex +
                '&finalization=' +
                (finalization ? '1' : '0') +
                '&pdfGen=' +
                (pdfGen ? '1' : '0'),
            form,
        ),
    pdfInfo: (studentSelfEvaluationId: string, form: IForm, formIndex: number) =>
        API.post(endpoints.selfEvaluation.report.pdfInfo(studentSelfEvaluationId) + '?formIndex=' + formIndex, form),
    commissionPdfInfo: (evaluationId: string, form: IForm) =>
        API.post(endpoints.selfEvaluation.report.commissionPdfInfo(evaluationId), form),
    initiateUniqueExport: (form: { format: string }, filter: IForm) => {
        const exportFormat = { format: 'zip' };
        return API.post(
            endpoints.selfEvaluation.report.export.initiateUniqueExport(filter.semester as string),
            exportFormat,
            {
                params: fixDateRangeFields(filter),
            },
        );
    },
    checkUniqueExport: (id: number) =>
        API.get(endpoints.selfEvaluation.report.export.checkUniqueExport(id as unknown as string)),
    downloadUniqueExport: (id: number) =>
        API.get(endpoints.selfEvaluation.report.export.downloadUniqueExport(id as unknown as string), {
            responseType: 'arraybuffer',
        }),

    initiateAggregatedExport: (form: { format: string }, filter: IForm) => {
        const exportFormat = { format: 'zip' };
        return API.post(
            endpoints.selfEvaluation.report.export.initiateAggregatedExport(filter.semester as string),
            exportFormat,
            {
                params: fixDateRangeFields(filter),
            },
        );
    },
    checkAggregatedExport: (id: number) =>
        API.get(endpoints.selfEvaluation.report.export.checkAggregatedExport(id as unknown as string)),
    downloadAggregatedExport: (id: number) =>
        API.get(endpoints.selfEvaluation.report.export.downloadAggregatedExport(id as unknown as string), {
            responseType: 'arraybuffer',
        }),
};

export default selfEvaluationReportService;
