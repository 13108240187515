import Grid from '@mui/material/Grid';
import { FormikListPage, Header, Loading, Tooltip, useFetch } from '@silinfo/front-end-template';
import { useCallback, useState, useEffect, useRef } from 'react';
import instance from '../../../api';
import EndpointProvider from '../../../components/EndpointProvider';
import SemesterSelector from '../../../components/SemesterSelector';
import { SemesterContextProvider } from '../../../components/SemesterSelector/SemesterContextProvider';
import IEntityBase from '../../../interface/IEntityBase';
import selfEvaluationReportService from '../../../services/selfEvalution/report';
import { RefreshContextProvider } from '../../../studentPages/SelfAssessment/RefreshContextProvider';
import { Option, initialInfo } from '../../../utils/AppConst';
import { IForm, StudentOption, TrainingProgramOption } from '../../../utils/Interfaces/interfaces';
import { endpoints } from '../../../utils/endPoints';
import ExportButton, { ExportContextWithSemester } from './ExportButton';
import RefreshButton from './RefreshButton';
import { breadcrumbs, header, tableProps, tooltipHTML, SESSION_KEY } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import ExportButtonAggregate from '../../../components/Buttons/ExportButton';
import { LoadingButton } from '@mui/lab';
import CachedIcon from '@mui/icons-material/Cached';
import { FormToPrintProps } from '../../../studentPages/SelfAssessment/CellRenderers/Operations/Download/types';
import FormToPrint from '../../../studentPages/SelfAssessment/CellRenderers/Operations/Download/FormToPrint';
import { generatePdf, HiddenCompletionComponent, HiddenFormRenderer } from '../../../studentPages/SelfAssessment/utils';
import { IResponse } from '../../../components/PreviewTemplate/types';
import { SelfEvaluationDocumentType } from '../../../studentPages/SelfAssessment/CellRenderers/Operations/Download/DownloadTemplate';
import questionnaireTemplateService from '../../../services/selfEvalution/questionnaireTemplate';
import { ICompletionData, ITrainingCompletionData } from '../../MasterData/General/Students/StudentView/types';
import ComponentToPrint from '../../MasterData/General/Students/StudentView/CollapsibleTable/ComponentToPrint';
import careerGuidanceService from '../../../services/selfEvalution/careerGuidance';
import { useSearchParams } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { create } from '../../../store/notification';
import { useTenant } from '../../../components/TenantContext';

export default function SelfAssessment() {
    const { user } = useSelector((state: RootState) => state.auth);
    const semesterState = useState<Option | null>(null);
    const [semester] = semesterState;
    const dispatch = useDispatch();
    const [filterInfo, setFilterInfo] = useState<IForm>(initialInfo({ student: 'asc' }));
    const [refresh, setRefresh] = useState(false);
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');
    const [selfEvalOptions, setSelfEvalOptions] = useState({
        selfEvaluationStatus: [],
        selfEvaluationConversationStatus: [],
        commissionEvaluationDocumentStatus: [],
        universityIndexStatus: [],
    });
    const [syncLoading, setSyncLoading] = useState(false);
    const [selfEvalLoading, setSelfEvalLoading] = useState(true);
    const toggleRefresh = useCallback(() => setRefresh((prev) => !prev), []);
    const [students, setStudents] = useState<StudentOption[]>([]);
    const [studentsLoading, setStudentsLoading] = useState<boolean>(true);
    const [, setSearchParams] = useSearchParams();

    const { data: campus, loading: campusLoading } = useFetch<IEntityBase[]>(
        endpoints.masterData.campuses.main,
        'hydra:member',
    );
    const { data: collegeYear, loading: collegeYearLoading } = useFetch<Option[]>(
        endpoints.masterData.collegeYears.options,
        '',
    );
    const { data: trainingLevel, loading: trainingLevelLoading } = useFetch<IEntityBase[]>(
        endpoints.masterData.trainingLevels.main,
        'hydra:member',
    );
    const { data: trainingProgram, loading: trainingProgramLoading } = useFetch<TrainingProgramOption[]>(
        endpoints.masterData.trainingPrograms.main,
        'hydra:member',
    );

    useEffect(() => {
        setSearchParams({});
        setFilterInfo({ semester: semester?.value || '0' });

        if (semester?.value) {
            setStudentsLoading(true);
            instance
                .get<StudentOption[]>(
                    endpoints.masterData.students.studentOptions.replace('{semesterId}', semester.value),
                )
                .then((data) => {
                    setStudents(data.data);
                    setStudentsLoading(false);
                })
                .catch(() => {
                    setStudentsLoading(false);
                });
        } else {
            setStudents([]);
            setStudentsLoading(false);
        }
    }, [semester?.value, setSearchParams]);

    const options: Record<string, Option[]> = {
        campus: campus
            .map((item) => ({ label: item.name, value: '' + item.id }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        collegeYear,
        trainingLevel: trainingLevel
            .map((item) => ({ label: item.name, value: '' + item.id }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        trainingProgram: trainingProgram
            .map((item: TrainingProgramOption) => ({
                label: (item.trainingLevel ? item.trainingLevel.name + ' / ' : '') + item.name,
                value: '' + item.id,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        student: students
            .map((item: StudentOption) => ({
                label: item.fullName,
                value: item.id.toString(),
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        ...selfEvalOptions,
    };

    const loading =
        campusLoading || collegeYearLoading || trainingLevelLoading || trainingProgramLoading || studentsLoading;

    const handleFilter = useCallback(
        (filter: IForm) => {
            setFilterInfo({ ...filter, semester: semester?.value || '0' });
            return instance.get(endpoints.selfEvaluation.report.filter(semester?.value || '0'), { params: filter });
        },
        [semester],
    );

    useEffect(() => {
        if (!loading && semester?.value) {
            const urlWithSemesterId = endpoints.selfEvaluation.report.selfEvaluationStatus.replace(
                '{semesterId}',
                semester.value,
            );
            setSelfEvalLoading(true);
            instance.get(urlWithSemesterId).then((response) => {
                const {
                    self_evaluation_statuses,
                    self_evaluation_conversation_statuses,
                    commission_evaluation_statuses,
                    university_index_statuses,
                } = response.data;

                setSelfEvalOptions({
                    selfEvaluationStatus: self_evaluation_statuses,
                    selfEvaluationConversationStatus: self_evaluation_conversation_statuses,
                    commissionEvaluationDocumentStatus: commission_evaluation_statuses,
                    universityIndexStatus: university_index_statuses,
                });
                setSelfEvalLoading(false);
            });
        }
    }, [loading, semester?.value, refresh]);

    const exportContext: ExportContextWithSemester = {
        initiateExport: selfEvaluationReportService.initiateExport,
        checkExport: selfEvaluationReportService.checkExport,
        downloadExport: selfEvaluationReportService.downloadExport,
        info: initialInfo({ student: 'asc' }),
        filter: filterInfo,
        semesterId: semester?.value || '0',
    };

    const exportUniqueContext: ExportContextWithSemester = {
        initiateExport: selfEvaluationReportService.initiateUniqueExport,
        checkExport: selfEvaluationReportService.checkUniqueExport,
        downloadExport: selfEvaluationReportService.downloadUniqueExport,
        info: initialInfo({ student: 'asc' }),
        filter: filterInfo,
        semesterId: semester?.value || '0',
    };

    const exportAggregatedContext: ExportContextWithSemester = {
        initiateExport: selfEvaluationReportService.initiateAggregatedExport,
        checkExport: selfEvaluationReportService.checkAggregatedExport,
        downloadExport: selfEvaluationReportService.downloadAggregatedExport,
        info: initialInfo({ student: 'asc' }),
        filter: filterInfo,
        semesterId: semester?.value || '0',
    };

    const [data, setData] = useState<IResponse | null>(null);
    const [type, setType] = useState<SelfEvaluationDocumentType>('selfEvaluation');

    const FormToPrintByType = useCallback(
        (formToPrintProps: Omit<FormToPrintProps, 'type'>) => <FormToPrint type={type} {...formToPrintProps} />,
        [type],
    );
    const refToPrintRenderer = useRef<HTMLDivElement>(null);
    const refToPrintCompletion = useRef<HTMLDivElement>(null);
    const [buttonText, setButtonText] = useState('');
    const [completionData, setCompletionData] = useState<ICompletionData | ITrainingCompletionData | null>(null);

    const handleSyncAllPdf = useCallback(
        async (filter: IForm) => {
            if (!semester?.value) {
                console.error('Semester ID is not selected.');
                return;
            }

            try {
                setSyncLoading(true);
                const response = await instance.get(endpoints.selfEvaluation.report.syncAllPdf(semester.value), {
                    params: filter,
                });
                const data = response?.data;
                if (data && Array.isArray(data)) {
                    const totalForms = data.length;
                    let processedForms = 0;

                    for (const item of data) {
                        const { id, questionnaireForms, questionnaireFill, commissionForms, commissionFill } = item;

                        const completionData = await instance.get(
                            endpoints.selfEvaluation.report.pdf(semester.value, '' + id),
                        );
                        setCompletionData(completionData.data.row);
                        await generatePdf(refToPrintCompletion, id, 'landscape');
                        await careerGuidanceService.initiateDirect({ id: id, format: 'docx' });

                        if (questionnaireForms?.length && questionnaireFill?.length) {
                            const formCount = Math.min(questionnaireForms.length, questionnaireFill.length);
                            const groupForm = [];
                            const groupFill = [];
                            for (let formIndex = 0; formIndex < formCount; formIndex++) {
                                const form = questionnaireForms[formIndex];
                                const fill = questionnaireFill[formIndex];
                                groupForm.push(form);
                                groupFill.push(fill);

                                if (groupForm.length > 0 && groupFill.length > 0) {
                                    setData({
                                        forms: groupForm,
                                        fill: groupFill,
                                        student: item.student,
                                        dateFilled: false,
                                    });
                                    await generatePdf(
                                        refToPrintRenderer,
                                        id ?? '0',
                                        'portrait',
                                        'adminSelfEvaluation',
                                        fill,
                                        formIndex,
                                        false,
                                        (id, values, formIndex, finalization) =>
                                            selfEvaluationReportService.writeFill(
                                                id ?? '0',
                                                values,
                                                formIndex,
                                                !!finalization,
                                                true,
                                            ),
                                    );
                                }
                            }
                        }

                        if (commissionForms && commissionFill) {
                            setType('commissionEvaluation');
                            const form = commissionForms[0];
                            const fill = commissionFill[0];

                            if (form && fill) {
                                setData({
                                    forms: [form],
                                    fill: [fill],
                                    student: item.student,
                                    dateFilled: false,
                                });
                                await generatePdf(
                                    refToPrintRenderer,
                                    id ?? '0',
                                    'portrait',
                                    'commissionEvaluation',
                                    fill,
                                    0,
                                    false,
                                    (id, values, formIndex, finalization) =>
                                        questionnaireTemplateService.fillCommissionEvaluation(
                                            id ?? '0',
                                            values,
                                            !!finalization,
                                            true,
                                        ),
                                );
                            }
                        }
                        processedForms += 1;
                        updateButtonText(processedForms, totalForms);
                    }
                }
                setData(response?.data);
                setSyncLoading(false);
                dispatch(create({ type: 'success', message: 'Sikeres frissítés!' }));
                setButtonText('');
            } catch (error) {
                console.error('Error syncing PDFs:', error);
                setSyncLoading(false);
                dispatch(create({ type: 'error', message: 'Hiba történt a frissítés során!' }));
                setButtonText('');
            }
        },
        [dispatch, semester?.value],
    );

    const updateButtonText = (processed: number, total: number) => {
        const buttonLabel = `(${processed}/${total})`;
        setButtonText(buttonLabel);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Header {...{ ...headerWithTenant, breadcrumbs }} />
                </Grid>
                <Grid item xs={11}>
                    <SemesterSelector
                        defaultActive
                        semesterState={semesterState}
                        url={endpoints.selfEvaluation.report.semesters}
                    />
                </Grid>
                {!user.archive && (
                    <EndpointProvider endpoints={['self_evaluation_edit']}>
                        <RefreshButton semester={semester?.value || ''} toggleRefresh={toggleRefresh} />
                    </EndpointProvider>
                )}
                <Grid item xs={12}>
                    <RefreshContextProvider refresh={toggleRefresh}>
                        <SemesterContextProvider semester={semester?.value || ''}>
                            {!loading && !selfEvalLoading ? (
                                <FormikListPage
                                    {...tableProps(
                                        semester?.value || '',
                                        options,
                                        toggleRefresh,
                                        exportUniqueContext,
                                        tenant,
                                    )}
                                    service={{ filter: handleFilter }}
                                    refresh={JSON.stringify([refresh, semester?.value || ''])}
                                    sessionKey={SESSION_KEY}
                                    customButtons={
                                        semester && !user.archive ? (
                                            <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                                                <Grid item>
                                                    <ExportButton
                                                        exportContext={exportContext}
                                                        otherLoading={syncLoading}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <ExportButtonAggregate
                                                        exportContext={exportAggregatedContext}
                                                        title="Összesített export"
                                                        format="zip"
                                                        otherLoading={syncLoading}
                                                    />
                                                </Grid>
                                                <EndpointProvider
                                                    endpoints={[
                                                        'self_assessment_report_fill',
                                                        'self_assessment_commission_evaluation_sheet_fill',
                                                    ]}
                                                >
                                                    <Grid item>
                                                        <LoadingButton
                                                            variant="contained"
                                                            disabled={syncLoading}
                                                            loading={syncLoading}
                                                            loadingPosition="start"
                                                            onClick={() => handleSyncAllPdf(filterInfo)}
                                                            startIcon={<CachedIcon />}
                                                        >
                                                            Dokumentumok frissítése {buttonText}
                                                        </LoadingButton>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        sx={{ marginTop: '8px', marginRight: '8px', marginLeft: '8px' }}
                                                    >
                                                        <Tooltip
                                                            title={
                                                                <div
                                                                    dangerouslySetInnerHTML={{ __html: tooltipHTML }}
                                                                ></div>
                                                            }
                                                            sx={{ color: '#364354' }}
                                                        >
                                                            <InfoIcon />
                                                        </Tooltip>
                                                    </Grid>
                                                </EndpointProvider>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )
                                    }
                                />
                            ) : (
                                <Loading noHeight />
                            )}
                        </SemesterContextProvider>
                    </RefreshContextProvider>
                </Grid>
            </Grid>
            <HiddenFormRenderer refToPrint={refToPrintRenderer} data={data} FormToPrintByType={FormToPrintByType} />
            <HiddenCompletionComponent
                refToPrint={refToPrintCompletion}
                data={completionData}
                ComponentToPrint={ComponentToPrint}
            />
        </>
    );
}
