import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { AutoloadTable, Page } from '@silinfo/front-end-template';
import useOpen from '../../utils/useOpen';
import { RefreshContextProvider } from '../SelfAssessment/RefreshContextProvider';
import { header, columns, filter } from './utils';
import useCheckRefresh from '../../utils/useCheckRefresh';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../utils/theme';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import OperationsMobile, { Questionnaire } from './OperationsMobile';
import Tooltip from '@mui/material/Tooltip';
import { useTenant } from '../../components/TenantContext';
import BackendSelect from '../components/BackendSelect';
import { Option } from '../../utils/AppConst';
import { endpoints } from '../../utils/endPoints';
import NoSemesterAlert from '../../components/SemesterSelector/NoSemesterAlert';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    headingMain: {
        color: theme.palette.info.main,
        fontSize: '18px',
    },
    headingPaper: {
        backgroundColor: '#D9D9D9',
        padding: theme.spacing(1.5),
        marginBottom: theme.spacing(2.5),
        fontSize: '18px',
    },
    q: {
        padding: theme.spacing(1.5),
    },
    qContainer: {
        display: 'flex',
        border: '1px solid #647C9B',
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(1.2),
        paddingRight: theme.spacing(1.5),
        boxSizing: 'border-box',
    },
    qBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '8px',
    },
    courseName: {
        fontSize: '12px',
    },
    courseCode: {
        fontSize: '11px',
    },
    deadline: {
        marginTop: theme.spacing(0.5),
        fontSize: '11px',
    },
    operations: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(1),
    },
}));

const findActiveSemester = (semesters: (Option & { active: boolean; startDate: string })[]) => {
    // Try to find the active semester first
    const activeSemester = semesters.find((item) => item.active);

    if (activeSemester) {
        return activeSemester;
    }

    // If there is no active semester, find the most recent one based on startDate
    return semesters.reduce((mostRecent, current) => {
        return new Date(current.startDate) > new Date(mostRecent.startDate) ? current : mostRecent;
    }, semesters[0]);
};

export default function Questionnaires() {
    const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);
    const [semester, setSemester] = useState<Option | null>(null);
    const [initialSemester, setInitialSemester] = useState<Option | null>(null);
    const { user } = useSelector((state: RootState) => state.auth);
    const { isOpen: refresh, toggleOpen: toggleRefresh } = useOpen();
    const refreshCheck = useCheckRefresh();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const classes = useStyles();
    const tenant = useTenant();

    useEffect(() => {
        const fetchData = async () => {
            if (semester?.value) {
                const response = await filter(semester.value);
                setQuestionnaires(response.data.data);
            }
        };
        fetchData();
    }, [semester]);

    useEffect(() => {
        if (initialSemester === null) {
            setInitialSemester(semester);
        }
    }, [initialSemester, semester]);

    return matches ? (
        <Page header={header(tenant || 'TAR')}>
            <Grid item xs={12}>
                <BackendSelect<Option & { active: boolean; startDate: string }>
                    url={endpoints.students.selfEvaluation.semesters}
                    label={initialSemester ? 'Szemeszter' : 'Nincs felvett szemesztered!'}
                    responseKey=""
                    value={semester}
                    setter={setSemester}
                    defaultValueCondition={findActiveSemester}
                    setRefresh={toggleRefresh}
                />
            </Grid>
            {semester?.value ? (
                <Grid item xs={12}>
                    <RefreshContextProvider refresh={toggleRefresh}>
                        <Paper>
                            <AutoloadTable
                                columns={user.archive ? columns.slice(0, -1) : columns}
                                filter={() => filter(semester.value)}
                                noAccordionHeader
                                responseKey="data"
                                hideFooter
                                refresh={JSON.stringify([refresh, refreshCheck])}
                                getRowId={(row) => row.courseId}
                            />
                        </Paper>
                    </RefreshContextProvider>
                </Grid>
            ) : (
                <div style={{ width: '100%' }}>
                    <NoSemesterAlert />
                </div>
            )}
        </Page>
    ) : (
        <>
            <Typography className={classes.headingMain}>Kérdőívek</Typography>
            <Grid item xs={12}>
                <BackendSelect<Option & { active: boolean; startDate: string }>
                    url={endpoints.students.selfEvaluation.semesters}
                    label={initialSemester ? 'Szemeszter' : 'Nincs felvett szemesztered!'}
                    responseKey=""
                    value={semester}
                    setter={setSemester}
                    defaultValueCondition={findActiveSemester}
                    setRefresh={toggleRefresh}
                />
            </Grid>
            {semester?.value ? (
                <Grid item xs={12}>
                    <Paper className={classes.paper} style={{ paddingTop: '16px' }} elevation={3}>
                        {questionnaires?.length > 0 ? (
                            questionnaires.map((item) => (
                                <div key={item.courseId} className={classes.qContainer}>
                                    <div>
                                        {item.sharedType && (
                                            <Tooltip title="Megosztott kurzus">
                                                <div
                                                    style={{
                                                        background: '#35A899',
                                                        width: '10px',
                                                        height: '100%',
                                                        marginLeft: '-10px',
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </div>
                                    <div className={classes.qBox}>
                                        <Typography className={classes.courseName}>{item.courseName}</Typography>
                                        <Typography className={classes.courseCode}>{item.courseCode}</Typography>
                                        <Typography className={classes.deadline}>
                                            {`Kitöltési határidő: ${item.deadline ?? 'nincs'}`}
                                        </Typography>
                                    </div>
                                    <div className={classes.operations}>
                                        <OperationsMobile row={item} />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                Nincsenek megjelenítendő sorok
                            </div>
                        )}
                    </Paper>
                </Grid>
            ) : (
                <div style={{ width: '100%' }}>
                    <NoSemesterAlert />
                </div>
            )}
        </>
    );
}
