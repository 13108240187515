import Add from '@mui/icons-material/Add';
import { Input } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { useContext, useEffect, useState } from 'react';
import EditContext from './EditContext';
import { IForm } from '../utils/Interfaces/interfaces';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTabState } from '../pages/CourseManagement/Courses/Events/TabStateContext';
import { DynamicFieldsProps, Field } from './DynamicFields';
import RoomContext from '../pages/CourseManagement/Courses/Events/RoomSearch/RoomContext';

interface ISpecialInput {
    type: 'location';
    name: string;
    gifts: [];
    itRequests: [];
}

type DynamicFieldInputType = Input | ISpecialInput;

const isFunction = (
    input: DynamicFieldInputType[] | ((index: number) => DynamicFieldInputType[]),
): input is (index: number) => DynamicFieldInputType[] => typeof input === 'function';

const getValue = (values: IForm, keyName: string): unknown => {
    const keys = keyName.split('.');
    if (keys.length === 1) {
        return values[keyName];
    }

    return getValue(values[keys[0]] as IForm, keys.slice(1).join('.'));
};

export default function EventDynamicFields<T extends IForm>({
    keyName,
    errorKey,
    label,
    inputFields,
    header,
    required,
    rawFields,
    readOnly,
}: DynamicFieldsProps) {
    const formikProps = useFormikContext<T>();
    const values = getValue(formikProps.values, keyName) as IForm[];
    const count = values?.length;
    const [fields, setFields] = useState<number[]>(
        Array(count)
            .fill(0)
            .map((_, i) => Date.now() + i),
    );
    const editState = useContext(EditContext);
    const disabled = editState?.[0] === false;
    const { setTabState } = useTabState();

    const { setSelectedRoom, triggerRefresh, setChangedRoomId, setCurrentLocationIndex } = useContext(RoomContext);

    useEffect(() => {
        if (count < fields.length) {
            setFields((prev) => prev.slice(0, count));
        }
    }, [count, fields.length]);

    const [numElements, setNumElements] = useState(0);

    const handleDelete = (id: number, index: number) => {
        const newValues = values.filter((_, i) => i !== index);
        const lastNonEmptyRoom = newValues[numElements - 1]?.room ? (newValues[numElements - 1]?.room as number) : null;

        // Törlés esetén visszaállás az előző addressre, ha van
        if (lastNonEmptyRoom) {
            setSelectedRoom(null);
            setCurrentLocationIndex(numElements - 1);
            setChangedRoomId(lastNonEmptyRoom);
        } else {
            triggerRefresh();
            setCurrentLocationIndex(0);
        }

        setFields((prev) => prev.filter((val) => val !== id));
        formikProps.setFieldValue(keyName, newValues);

        setTabState((prevTabState) => prevTabState.filter((_, i) => i !== index));

        setNumElements((prev) => prev - 1);
    };

    const handleAdd = () => {
        if (numElements >= 10) {
            return;
        }

        // Új addressnél szűrő törlése
        setSelectedRoom(null);
        setChangedRoomId(null);
        triggerRefresh();

        const affectedRoom = values[numElements]?.room || null;
        const isPrevEmpty = !affectedRoom;

        if (!isPrevEmpty) {
            setCurrentLocationIndex(numElements + 1);
        }

        setNumElements((prev) => prev + 1);
        setFields((prev) => [...prev, Date.now()]);
        const fieldsToValues = rawFields || (isFunction(inputFields) ? inputFields(0) : inputFields);
        const newValues = fieldsToValues.reduce((acc, curr) => {
            acc[curr.name] = curr.type === 'multiselect' ? [] : '';
            return acc;
        }, {} as IForm);

        formikProps.setFieldValue(keyName, [...values, newValues]);
    };

    const canAddMore = values?.length < 10;

    return (
        <>
            {fields.length > 0 ? (
                fields.map((id, index) => (
                    <Field
                        key={id}
                        id={id}
                        index={index}
                        label={label}
                        inputFields={inputFields}
                        required={required}
                        disabled={disabled || !!readOnly}
                        handleDelete={handleDelete}
                        header={header}
                        keyName={keyName}
                        errorKey={errorKey}
                    />
                ))
            ) : (
                <Grid item xs={12} textAlign="center" color="text.secondary">
                    <Typography>
                        <i>Nincs {label} megadva.</i>
                    </Typography>
                </Grid>
            )}
            {!disabled && !readOnly ? (
                <Grid item xs={12} textAlign="right">
                    <Tooltip title="Hozzáadás">
                        <>
                            {canAddMore && (
                                <IconButton color="success" onClick={handleAdd}>
                                    <Add />
                                </IconButton>
                            )}
                        </>
                    </Tooltip>
                </Grid>
            ) : null}
        </>
    );
}
