import DialogBasic from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useState, Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import Form from '../../../../components/Form/Form';
import courseTargetAudienceService from '../../../../services/courseManagement/courseTargetAudience';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import EditTargetAudienceForm from './EditTargetAudienceForm';
import { initialTargetAudienceForm } from './types';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

export default function EditTargetAudienceButton({
    title,
    submitBtnTitle,
    opener,
    objectId,
    setTableLoading,
    onUpdate,
    courseTenant,
}: {
    title: string;
    submitBtnTitle: string;
    opener: JSX.Element;
    objectId?: number;
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    onUpdate: () => void;
    courseTenant: string | null;
}) {
    const { courseId } = useParams() as { courseId: string };
    const [open, setOpen] = useState(false);

    const classes = useStyles();
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getEvent = async () => {
        if (!objectId) return;
        const { data } = await courseTargetAudienceService.get(+courseId, objectId, courseTenant);
        return {
            ...data,
            data: {
                ...data.data,
            },
        };
    };

    return (
        <>
            {React.cloneElement(opener, {
                onClick: () => {
                    handleOpen();
                },
            })}
            <DialogBasic open={open} onClose={handleClose} fullWidth>
                <DialogTitle className={classes.title}>{title}</DialogTitle>
                <DialogContent style={{ marginTop: '1rem' }}>
                    <Form
                        urlParam="courseId"
                        hideButtons
                        fields={[]}
                        onSubmit={(form: IForm) => {
                            objectId
                                ? courseTargetAudienceService
                                      .update(
                                          { ...form, course: parseInt(courseId) },
                                          +courseId,
                                          objectId,
                                          courseTenant,
                                      )
                                      .then(() => {
                                          setTableLoading(true);
                                          setTableLoading(false);
                                          handleClose();
                                      })
                                : courseTargetAudienceService
                                      .create({ ...form, course: parseInt(courseId) }, +courseId, courseTenant)
                                      .then(() => {
                                          setTableLoading(true);
                                          setTableLoading(false);
                                          handleClose();
                                      });

                            onUpdate();
                        }}
                        initialValues={initialTargetAudienceForm}
                        {...(objectId
                            ? {
                                  getterFunction: getEvent,
                              }
                            : {})}
                    >
                        {(props) => (
                            <EditTargetAudienceForm
                                submitBtnTitle={submitBtnTitle}
                                handleClose={handleClose}
                                {...props}
                            />
                        )}
                    </Form>
                </DialogContent>
            </DialogBasic>
        </>
    );
}
