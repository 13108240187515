import { LoadingButton } from '@mui/lab';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { CancelButton, Loading, Select, SubmitButton, TextField, useFetch } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import questionnaireProgramService from '../../../../services/selfEvalution/questionnaireProgram';
import { create } from '../../../../store/notification';
import { endpoints } from '../../../../utils/endPoints';
import { QuestionnaireProgram, TYPE_QUESTIONNAIRE, typeOptions } from '../utils';

type UpdateTemplateDialogFormProps = {
    row: QuestionnaireProgram;
    setRefresh: Dispatch<SetStateAction<boolean>>;
    handleClose: () => void;
    searchType: string;
    type: string;
};
const InputTemplate = (params: AutocompleteRenderInputParams) => <TextField {...params} label="Kérdőívsablon" />;
const InputGroup = (params: AutocompleteRenderInputParams) => <TextField {...params} label="Kérdőívcsoport" />;

export default function UpdateTemplateDialogForm(props: UpdateTemplateDialogFormProps) {
    const { row, setRefresh, handleClose, searchType, type } = props;
    const templateSelected = type === TYPE_QUESTIONNAIRE ? row.questionnaireTemplate : row.evaluationSheetQuestionnaire;
    const templateGroupSelected =
        type === TYPE_QUESTIONNAIRE ? row.questionnaireTemplateGroup : row.evaluationSheetQuestionnaire;
    const [template, setTemplate] = useState<Option<number>>(
        templateSelected ? { value: templateSelected.id, label: templateSelected.name } : { value: 0, label: '' },
    );
    const [templateGroup, setTemplateGroup] = useState<Option<number>>(
        templateGroupSelected
            ? { value: templateGroupSelected.id, label: templateGroupSelected.name }
            : { value: 0, label: '' },
    );
    const [formLoading, setFormLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleDialogOpen = useCallback(() => setDialogOpen(true), [setDialogOpen]);
    const handleDialogClose = useCallback(() => setDialogOpen(false), [setDialogOpen]);
    const { data: templateOptions, loading: templateLoading } = useFetch<Option<number>[]>(
        endpoints.selfEvaluation.questionnaireProgram.listTemplatesByQuestionnaireProgram.replace(
            '{questionnaireProgramId}',
            row.id as unknown as string,
        ) + `?type=${searchType}`,
        '',
    );

    const { data: groupOptions, loading: groupLoading } = useFetch<Option<number>[]>(
        endpoints.selfEvaluation.questionnaireGroup.groupOptions,
        '',
    );
    const dispatch = useDispatch();
    const [selectedType, setSelectedType] = useState<string>('template');
    const [typeManuallyChanged, setTypeManuallyChanged] = useState<boolean>(false);

    useEffect(() => {
        if (!typeManuallyChanged) {
            if (template?.value !== 0) {
                setSelectedType('template');
            } else if (templateGroup?.value !== 0) {
                setSelectedType('template_group');
            }
        }
    }, [template, templateGroup, typeManuallyChanged]);

    const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedType(event.target.value as string);
        setTypeManuallyChanged(true);
    };

    const handleTemplateChange = useCallback(
        (_: unknown, value: Option<number> | null) => setTemplate(value as unknown as Option<number>),
        [setTemplate],
    );

    const handleTemplateGroupChange = useCallback(
        (_: unknown, value: Option<number> | null) => setTemplateGroup(value as unknown as Option<number>),
        [setTemplateGroup],
    );

    const isOptionEqualToValue = useCallback(
        (option: Option<number, string>, value: Option<number, string>) => option?.value === value?.value,
        [],
    );

    const handleSubmit = useCallback(() => {
        setFormLoading(true);
        let templateIdToSend = null;
        let templateGroupIdToSend = null;

        if (selectedType === 'template') {
            templateIdToSend = template?.value ?? null;
        } else if (selectedType === 'template_group') {
            templateGroupIdToSend = templateGroup?.value ?? null;
        }

        (type === TYPE_QUESTIONNAIRE
            ? questionnaireProgramService.updateTemplate(
                  row.id as unknown as string,
                  templateIdToSend,
                  templateGroupIdToSend,
                  type,
              )
            : questionnaireProgramService.updateTemplate(
                  row.id as unknown as string,
                  templateIdToSend,
                  templateGroupIdToSend,
                  type,
              )
        )
            .then(() => {
                handleDialogClose();
                handleClose();
                dispatch(
                    create({
                        type: 'success',
                        message: 'Sikeres mentés!',
                    }),
                );
                setRefresh((prev) => !prev);
            })
            .catch((e) => {
                dispatch(
                    create({
                        type: 'error',
                        message:
                            e?.response?.data?.violations?.[0]?.message ||
                            e?.response?.data?.message ||
                            'Hiba történt a mentés során!',
                    }),
                );
            })
            .finally(() => {
                setFormLoading(false);
            });
    }, [dispatch, handleClose, handleDialogClose, row.id, selectedType, template, templateGroup, setRefresh, type]);

    if (templateLoading || groupLoading) {
        return <Loading noHeight />;
    }

    const defaultOption = typeOptions.find((option) => option.value === 'template');

    return (
        <>
            <Grid item xs={12}>
                {type === TYPE_QUESTIONNAIRE && (
                    <Select
                        label={'Típus'}
                        options={typeOptions}
                        defaultValue={defaultOption?.value}
                        value={selectedType}
                        onChange={handleTypeChange}
                    />
                )}
                {selectedType === 'template' && (
                    <Autocomplete
                        options={templateOptions}
                        value={template}
                        onChange={handleTemplateChange}
                        size="small"
                        renderInput={InputTemplate}
                        isOptionEqualToValue={isOptionEqualToValue}
                        filterSelectedOptions={true}
                    />
                )}
                {selectedType === 'template_group' && (
                    <Autocomplete
                        options={groupOptions}
                        value={templateGroup}
                        onChange={handleTemplateGroupChange}
                        size="small"
                        renderInput={InputGroup}
                        isOptionEqualToValue={isOptionEqualToValue}
                        filterSelectedOptions={true}
                    />
                )}
            </Grid>
            <Grid item container spacing={2} justifyContent="flex-end" style={{ marginTop: '4px' }}>
                <Grid item>
                    {row.activatedAt &&
                    !row.active &&
                    row.questionnaireTemplate?.id !== template?.value &&
                    row.filledStudentSelfEvaluationExists ? (
                        <>
                            <SubmitButton onClick={handleDialogOpen}>Mentés</SubmitButton>
                            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                                <Grid item container>
                                    <Grid item xs={12}>
                                        <DialogTitle>Kérdőívsablon megváltoztatása</DialogTitle>
                                    </Grid>
                                    <Grid item container xs={12}>
                                        <DialogContent>
                                            Ehhez a képzéshez kapcsolódóan már rendelkezésre áll kitöltött önértékelési
                                            kérdőív. A kérdőívsablon megváltoztatásával az eddigi összes kitöltés
                                            törlésre kerül. Biztosan lecseréled a sablont?
                                        </DialogContent>
                                    </Grid>
                                    <Grid item container xs={12} justifyContent="flex-end">
                                        <DialogActions>
                                            <LoadingButton
                                                loading={formLoading}
                                                variant="contained"
                                                onClick={handleSubmit}
                                            >
                                                Igen
                                            </LoadingButton>
                                            <Grid item>
                                                <CancelButton onClick={handleDialogClose}>Nem</CancelButton>
                                            </Grid>
                                        </DialogActions>
                                    </Grid>
                                </Grid>
                            </Dialog>
                        </>
                    ) : (
                        <LoadingButton loading={formLoading} variant="contained" onClick={handleSubmit}>
                            Mentés
                        </LoadingButton>
                    )}
                </Grid>
                <Grid item>
                    <CancelButton onClick={handleClose}>Bezár</CancelButton>
                </Grid>
            </Grid>
        </>
    );
}
