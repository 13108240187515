import { GridAlignment, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Dispatch, SetStateAction } from 'react';
import { StatusCellRenderer } from './CellRenderers';
import OperationsCellRenderer from './CellRenderers/Operations';
import { fillDateCellRenderer } from './CellRenderers/Status';
import jsPDF from 'jspdf';
import { boldFont, font, italicFont } from '../../components/PDFDownloader/utils';
import instance from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';
import { endpoints } from '../../utils/endPoints';
import { IFillerStudent, IResponse } from '../../components/PreviewTemplate/types';
import { ICompletionData, ITrainingCompletionData } from '../../pages/MasterData/General/Students/StudentView/types';

export const header = (tenant: string | null) => {
    return {
        title: 'Önértékelés',
        project: tenant || 'TAR',
        breadcrumbs: {
            selfAssesment: 'Önértékelés',
        },
    };
};

const order = [
    'previousSummary',
    'selfAssessmentQuestionnaire',
    'selfAssessmentConversation',
    'summaryDocument',
    'commissionEvaluationSheet',
    'universityIndex',
    'careerGuidanceSheet',
    'completionSheet',
];

const translations = [
    'Korábbi célkitűzés',
    'Önértékelés és célkitűzés',
    'Önértékelési beszélgetés',
    'Összefoglaló dokumentum',
    'Bizottsági értékelő lap',
    'Egyetemi index',
    'Pályaorientációs lap',
    'MCC teljesítési lap',
];

export const names: Record<string, string> = order.reduce(
    (acc, curr, index) => ({
        ...acc,
        [curr]: translations[index],
    }),
    {},
);

interface HiddenFormRendererProps {
    refToPrint: React.RefObject<HTMLDivElement>;
    data: IResponse | null;
    FormToPrintByType: React.ComponentType<{ data: IResponse; givenUser: IFillerStudent }>;
}

interface HiddenCompletionComponentProps {
    refToPrint: React.RefObject<HTMLDivElement>;
    data: ICompletionData | ITrainingCompletionData | null;
    ComponentToPrint: React.ComponentType<{ data: ICompletionData | ITrainingCompletionData }>;
}

export const HiddenFormRenderer: React.FC<HiddenFormRendererProps> = ({ refToPrint, data, FormToPrintByType }) => {
    if (!data || !data.student) return null;

    return (
        <div style={{ position: 'fixed', left: -10000 }}>
            <div ref={refToPrint} style={{ margin: '0 72pt', width: '1278px' /* !!! BŰVÖS SZÁM NE NYÚLJ HOZZÁ !!! */ }}>
                <FormToPrintByType data={data} givenUser={data.student} />
            </div>
        </div>
    );
};

export const HiddenCompletionComponent: React.FC<HiddenCompletionComponentProps> = ({
    refToPrint,
    data,
    ComponentToPrint,
}) => {
    if (!data) return null;

    return (
        <div style={{ position: 'fixed', left: -15000 }}>
            <div ref={refToPrint} style={{ margin: '0 72pt', width: '1278px' /* !!! BŰVÖS SZÁM NE NYÚLJ HOZZÁ !!! */ }}>
                <ComponentToPrint data={data} />
            </div>
        </div>
    );
};

export const generatePdf = async (
    refToPrint: React.RefObject<HTMLDivElement>,
    id: string,
    orientation: 'portrait' | 'landscape',
    type?: string,
    values?: IForm,
    formIndex?: number,
    finalization?: boolean,
    serviceCallback?: (id: string, updatedValues: IForm, formIndex: number, finalization?: boolean) => void,
) => {
    const doc = new jsPDF(orientation, 'px', 'a4', false);
    doc.addFileToVFS('Roboto-Black-normal.ttf', font);
    doc.addFileToVFS('Roboto-Black-bold.ttf', boldFont);
    doc.addFileToVFS('Roboto-Black-italic.ttf', italicFont);
    doc.addFont('Roboto-Black-normal.ttf', 'Roboto', 'normal');
    doc.addFont('Roboto-Black-bold.ttf', 'Roboto', 'bold');
    doc.addFont('Roboto-Black-italic.ttf', 'Roboto', 'italic');
    doc.setFont('Roboto');

    const element = refToPrint.current;
    if (!element) {
        console.error('No element to render PDF from');
        return;
    }

    await doc.html(element, {
        margin: [24, 0, 24, 0],
        callback: (done) => {
            const blob = done.output('blob');
            const formData = new FormData();
            formData.append('file', blob, 'blob');

            const endpoint = type
                ? endpoints.selfEvaluation.report.storePdf(id) + `?type=${type}`
                : endpoints.selfEvaluation.report.storeCompletionPdf(id);

            instance
                .post(endpoint, formData)
                .then((response) => {
                    if (serviceCallback && values && formIndex !== undefined) {
                        const updatedValues = { ...values, pdfFile: response.data };
                        serviceCallback(id, updatedValues, formIndex, finalization);
                    }
                })
                .catch((error) => console.error('Error uploading PDF:', error));
        },
        html2canvas: {
            scale: 0.43,
            letterRendering: true,
        },
        autoPaging: 'text',
    });
};

const columns = (setRefresh: Dispatch<SetStateAction<boolean>>): GridColDef[] =>
    [
        {
            field: 'name',
            headerName: 'Megnevezés',
            valueGetter: (params: GridValueGetterParams) => names[params.value],
        },
        {
            field: 'status',
            headerName: 'Státusz',
            renderCell: StatusCellRenderer,
        },
        {
            field: 'fillDate',
            headerName: 'Véglegesítés ideje',
            renderCell: fillDateCellRenderer,
        },
        {
            field: 'operations',
            headerName: 'Műveletek',
            flex: 0.5,
            renderCell: (params: GridRenderCellParams) => (
                <OperationsCellRenderer setRefresh={setRefresh} params={params} />
            ),
            align: 'center' as GridAlignment,
            headerAlign: 'center' as GridAlignment,
        },
    ].map((column) => ({
        flex: 1,
        minWidth: 200,
        sortable: false,
        ...column,
    }));

export default {
    header,
    columns,
    order,
};
