import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { GridRenderCellParams } from '@mui/x-data-grid';
import axios from 'axios';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { STATUS } from '../../../../studentPages/SelfAssessment/CellRenderers/Status/utils';
import { getJWTToken } from '../../../../utils/AuthHelper';
import { endpoints } from '../../../../utils/endPoints';
import { TSelfAssessmentDocumentStatus } from '../types';
import { componentMap, dateStyle, universityIndexMapper } from './utils';

const handleClick = (documentUrl: string) => window.open(documentUrl, '_blank');

export default function UniversityIndexCellRenderer(
    params: GridRenderCellParams<{ status: TSelfAssessmentDocumentStatus; file: { filename: string; id: number } }>,
) {
    const key = universityIndexMapper.get(params.value?.status || STATUS.UPLOAD) || 'none';
    const Core = componentMap[key];
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const onClick = useCallback(() => {
        if (params.value?.file?.id && params.value?.file?.filename) {
            const AUTH_TOKEN = getJWTToken();
            setLoading(true);
            axios
                .get(
                    `${
                        endpoints.fileManagement.download
                    }?id=${params.value.file.id.toString()}&type=university-index&extraId=${
                        id || params.row.studentId
                    }`,
                    {
                        responseType: 'blob',
                        headers: {
                            Authorization: `Bearer ${AUTH_TOKEN}`,
                        },
                    },
                )
                .then((response) => handleClick(URL.createObjectURL(response.data)))
                .finally(() => setLoading(false));
        }
    }, [params.value, params.row.studentId, id]);

    if (params.value?.status === STATUS.UPLOADED) {
        return (
            <div style={{ textAlign: 'center' }}>
                <IconButton onClick={onClick}>{loading ? <CircularProgress size={24} /> : Core}</IconButton>
                <div style={dateStyle}>{params.row?.universityIndexUploadDate || ''}</div>
            </div>
        );
    }

    return Core;
}
