import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { FieldGenerator, Option } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import instance from '../../../../../api';
import { create } from '../../../../../store/notification';
import { IAddress, IEventForm, IExternalAddress } from '../utils';
import { endpoints } from '../../../../../utils/endPoints';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SeparateLocationsCheckBox from '../SeparateLocationsCheckBox';
import Tooltip from '@mui/material/Tooltip';
import { useTabState } from '../TabStateContext';
0;
const DownloadButton = ({ fileName }: { fileName?: string | null }) => {
    const dispatch = useDispatch();
    const formikProps = useFormikContext<IEventForm>();
    const formLayoutId = formikProps.values.addresses[0]?.roomLayout;
    const [loading, setLoading] = useState(false);
    const handleDownload = async () => {
        try {
            setLoading(true);
            const url = `${endpoints.fileManagement.download}?id=${formLayoutId}&type=room-layout`;
            const response = await instance.get(url, {
                responseType: 'blob',
            });
            const urlCreator = window.URL || window.webkitURL;
            const imageUrl = urlCreator.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = imageUrl;
            link.download = fileName || 'teremelrendezes.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            dispatch(create({ type: 'error', message: 'Hiba történt a letöltés közben' }));
        } finally {
            setLoading(false);
        }
    };

    if (!fileName) return null;

    return (
        <Grid item>
            <LoadingButton onClick={handleDownload} loading={loading} variant="contained">
                Teremelrendezés letöltése
            </LoadingButton>
        </Grid>
    );
};

const Inputs = ({ readOnly, currentTab }: { readOnly: boolean | undefined; currentTab?: number }) => {
    const formikProps = useFormikContext<IEventForm>();
    const addresses = formikProps.values.addresses;
    const currentIndex = currentTab ? currentTab : 0;
    const formLayoutId = addresses[currentIndex]?.roomLayout;
    const [rawOptions, setRawOptions] = useState<(Option & { fileName?: string | null })[]>([]);
    const fileName = rawOptions.find((option) => formLayoutId === option.value)?.fileName;

    const handleSelectChangeWithArray = (value: Option<string, string>[] | string[] | null, fieldName: string) => {
        if (currentTab !== undefined) {
            formikProps.setFieldValue(
                'addresses.' + currentIndex + '.' + fieldName,
                value?.map((e) => (typeof e === 'string' ? e : e.value)),
            );
        } else {
            addresses?.forEach((_, index) => {
                formikProps.setFieldValue(
                    'addresses.' + index + '.' + fieldName,
                    value?.map((e) => (typeof e === 'string' ? e : e.value)),
                );
            });
        }
    };

    const handleSelectChange = (value: Option | null, fieldName: string) => {
        formikProps.setFieldValue('addresses.' + currentIndex + '.customRoomLayoutFileName', '');
        formikProps.setFieldValue('addresses.' + currentIndex + '.customRoomLayoutFilePath', '');
        if (currentTab !== undefined) {
            formikProps.setFieldValue('addresses.' + currentIndex + '.' + fieldName, value?.value ?? '');
        } else {
            addresses?.forEach((_, index) => {
                formikProps.setFieldValue('addresses.' + index + '.' + fieldName, value?.value ?? '');
            });
        }
    };

    const handleChange = (value: string, fieldName: string) => {
        if (currentTab !== undefined) {
            formikProps.setFieldValue('addresses.' + currentIndex + '.' + fieldName, value);
        } else {
            addresses?.forEach((_, index) => {
                formikProps.setFieldValue('addresses.' + index + '.' + fieldName, value);
            });
        }
    };

    return (
        <Grid item xs={12} container spacing={2} key="editable">
            <Grid item xs={12} key="visualAppearances">
                {FieldGenerator({
                    name: 'visualAppearances',
                    label: 'Arculati megjelenés',
                    type: 'backendSelect',
                    url: endpoints.courseManagement.courseList.listVisualAppearances,
                    format: {
                        xs: 12,
                    },
                    multiple: true,
                    justValue: true,
                    ...formikProps,
                    props: {
                        key: 'visualAppearances',
                        disabled: readOnly,
                        value: addresses && addresses[currentIndex].visualAppearances,
                        onChange: (_: unknown, value: Option<string, string>[] | null) =>
                            handleSelectChangeWithArray(value, 'visualAppearances'),
                    },
                    fieldType: 'base',
                })}
                {(
                    (formikProps.values.addresses &&
                        (formikProps.values.addresses[currentIndex].visualAppearances as unknown as string[])) ||
                    []
                ).includes('other') && (
                    <Grid item xs={12} key={'visualAppearancesDescription'}>
                        {FieldGenerator({
                            name: 'visualAppearancesDescription',
                            label: 'Leírás',
                            format: {
                                xs: 12,
                            },
                            ...formikProps,
                            props: {
                                key: 'visualAppearancesDescription',
                                disabled: readOnly,
                                value: addresses && addresses[currentIndex].visualAppearancesDescription,
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChange(e.target.value, 'visualAppearancesDescription'),
                            },
                            fieldType: 'base',
                        })}
                    </Grid>
                )}
            </Grid>
            {FieldGenerator({
                ...formikProps,
                format: { xs: 12 },
                name: 'roomLayout',
                label: 'Teremelrendezés',
                type: 'backendSelect',
                url: endpoints.courseManagement.courseList.listRoomLayout,
                transform: (data: (string | number)[]) => {
                    setRawOptions(data as unknown as Option[]);
                    return data as unknown as Option[];
                },
                justValue: true,
                props: {
                    disabled: readOnly,
                    value: addresses && addresses[currentIndex].roomLayout,
                    onChange: (_: unknown, value: Option | null) => handleSelectChange(value, 'roomLayout'),
                },
                fieldType: 'base',
            })}
            {formikProps.values.addresses && formikProps.values.addresses[currentIndex].roomLayout === 'other' ? (
                <>
                    {FieldGenerator({
                        ...formikProps,
                        type: 'text',
                        format: { xs: 12 },
                        name: 'customRoomLayoutDescription',
                        label: 'Leírás',
                        props: {
                            InputProps: { inputProps: { maxLength: 255 } },
                            disabled: readOnly,
                            value: addresses && addresses[currentIndex].customRoomLayoutDescription,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                handleChange(e.target.value, 'customRoomLayoutDescription'),
                        },
                        fieldType: 'base',
                    })}
                    {FieldGenerator({
                        ...formikProps,
                        type: 'file',
                        origFileName: 'addresses.' + currentIndex + '.customRoomLayoutFileName',
                        uuidFileName: 'addresses.' + currentIndex + '.customRoomLayoutFilePath',
                        tempType: 'event-custom-room-layout',
                        downloadUrl: `${endpoints.fileManagement.download}?id=${formikProps.initialValues.id}&type=event-custom-room-layout`,
                        format: { xs: 12 },
                        name: 'customRoomLayout',
                        label: 'Egyedi teremelrendezés',
                        props: {
                            disabled: readOnly,
                        },
                    })}
                </>
            ) : (
                <DownloadButton fileName={fileName} />
            )}
        </Grid>
    );
};

export default function RoomSchedule({ readOnly }: { readOnly: boolean | undefined }) {
    const formikProps = useFormikContext<IEventForm>();
    const [separateLocations, setSeparateLocations] = useState(formikProps.values.roomLayoutPerLocation);
    const [value, setValue] = useState('0');
    const addresses = formikProps.values.addresses;
    const { tabState, setTabState } = useTabState();
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    useEffect(() => {
        if (firstLoad) {
            tabState.forEach((address, index) => {
                if ((address.visualAppearances || address.roomLayout) && index < addresses.length) {
                    formikProps.setFieldValue('addresses.' + index + '.visualAppearances', address.visualAppearances);
                    formikProps.setFieldValue('addresses.' + index + '.' + 'roomLayout', address.roomLayout);
                }
            });
            setFirstLoad(false);
        }
    }, [addresses.length, firstLoad, formikProps, tabState]);

    useEffect(() => {
        return () => {
            setTabState((prevTabState) => {
                const updatedTabState = addresses.map((address, index) => {
                    const newVisualAppearances = separateLocations
                        ? addresses[index]?.visualAppearances ?? []
                        : addresses[0]?.visualAppearances ?? [];

                    const newRoomLayout = separateLocations
                        ? addresses[index]?.roomLayout ?? ''
                        : addresses[0]?.roomLayout ?? '';

                    return {
                        ...address,
                        ...prevTabState[index],
                        visualAppearances: newVisualAppearances,
                        roomLayout: newRoomLayout,
                    };
                });

                return updatedTabState;
            });
        };
    }, [addresses, separateLocations, setTabState]);

    const handleChange = (newValue: boolean) => {
        formikProps.setFieldValue('roomLayoutPerLocation', newValue);
        if (separateLocations) {
            addresses?.forEach((_, index) => {
                if (index !== 0) {
                    formikProps.setFieldValue(
                        'addresses.' + index + '.' + 'visualAppearances',
                        addresses[0].visualAppearances,
                    );
                    formikProps.setFieldValue(
                        'addresses.' + index + '.' + 'visualAppearancesDescription',
                        addresses[0].visualAppearancesDescription,
                    );
                    formikProps.setFieldValue('addresses.' + index + '.' + 'roomLayout', addresses[0].roomLayout);
                    formikProps.setFieldValue(
                        'addresses.' + index + '.' + 'customRoomLayoutDescription',
                        addresses[0].customRoomLayoutDescription,
                    );
                    formikProps.setFieldValue(
                        'addresses.' + index + '.' + 'customRoomLayoutFileName',
                        addresses[0].customRoomLayoutFileName,
                    );
                    formikProps.setFieldValue(
                        'addresses.' + index + '.' + 'customRoomLayoutFileName',
                        addresses[0].customRoomLayoutFilePath,
                    );
                }
            });
        }
    };

    return (
        <Grid item xs={12} container spacing={4} sx={{ paddingLeft: '2rem' }}>
            {separateLocations ? (
                <>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '0.5rem' }} width="100%">
                            <TabList
                                variant="fullWidth"
                                onChange={(_, val) => setValue(val)}
                                aria-label="lab API tabs example"
                                sx={{
                                    '& .MuiButtonBase-root': {
                                        backgroundColor: '#fff !important',
                                        color: '#22344c !important',
                                    },
                                }}
                            >
                                {addresses?.map((element: IAddress | IExternalAddress, index) =>
                                    'roomName' in element ? (
                                        <Tab
                                            key={index}
                                            label={
                                                <Tooltip title={element.roomName ?? 'Nincs megadva'}>
                                                    <span>
                                                        {element.roomName.length > 32
                                                            ? element.roomName.substring(0, 32) + '...'
                                                            : element.roomName}
                                                    </span>
                                                </Tooltip>
                                            }
                                            value={'' + index}
                                        />
                                    ) : (
                                        <Tab
                                            key={index}
                                            label={
                                                <Tooltip title={element.location ?? 'Nincs megadva'}>
                                                    <span>
                                                        {element.location && element.location.length > 32
                                                            ? element.location.substring(0, 32) + '...'
                                                            : element.location ?? 'Nincs megadva'}
                                                    </span>
                                                </Tooltip>
                                            }
                                            value={'' + index}
                                        />
                                    ),
                                )}
                            </TabList>
                            {addresses?.map((_, index) => (
                                <TabPanel key={index} value={'' + index}>
                                    <Inputs readOnly={readOnly} currentTab={index} />
                                </TabPanel>
                            ))}
                        </Box>
                    </TabContext>
                    <SeparateLocationsCheckBox
                        checkedState={[separateLocations, setSeparateLocations]}
                        label="Helyszínenként külön szeretném beállítani a terembeosztást"
                        handleChange={handleChange}
                        readOnly={readOnly}
                    />
                </>
            ) : (
                <Grid container item xs={12} spacing={2}>
                    <SeparateLocationsCheckBox
                        checkedState={[separateLocations, setSeparateLocations]}
                        label="Helyszínenként külön szeretném beállítani a terembeosztást"
                        handleChange={handleChange}
                        readOnly={readOnly}
                    />
                    <Inputs readOnly={readOnly} />
                </Grid>
            )}
        </Grid>
    );
}
