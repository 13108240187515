import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useCallback, useEffect, useState, useContext } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import { RoomOption } from './types';
import instance from '../../../../../api';
import { endpoints } from '../../../../../utils/endPoints';
import { RoomContext } from './RoomContext';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

const CAPACITY_RANGES = [
    { label: '1-10', min: 1, max: 10 },
    { label: '11-25', min: 11, max: 25 },
    { label: '26-40', min: 26, max: 40 },
    { label: '41-60', min: 41, max: 60 },
    { label: '60-250', min: 60, max: 250 },
];

export default function RoomSearch({ objectId }: { objectId?: number }) {
    const { changedRoomId, selectedRoom, setSelectedRoom, selectedDates, trainingCenterOptions, refreshKey } =
        useContext(RoomContext);
    const [selectedCenter, setSelectedCenter] = useState<string>('');
    const [selectedCapacityRange, setSelectedCapacityRange] = useState<string>('');
    const [rooms, setRooms] = useState<RoomOption[]>([]);
    const [filteredRooms, setFilteredRooms] = useState<RoomOption[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    const { user } = useSelector((state: RootState) => state.auth);

    const fetchRooms = useCallback(() => {
        const { startDate, endDate } = selectedDates;
        if (!startDate || !endDate || new Date(startDate) > new Date(endDate)) {
            setRooms([]);
            return;
        }

        setIsFetching(true);

        instance
            .get(endpoints.courseManagement.courseEvent.roomSearch, {
                params: {
                    startDate: selectedDates.startDate,
                    endDate: selectedDates.endDate,
                    eventId: objectId ?? null,
                },
            })
            .then((response) => {
                setRooms(response.data);
            })
            .catch(() => {
                setRooms([]);
            })
            .finally(() => setIsFetching(false));
    }, [selectedDates, objectId]);

    const handleClearFilters = useCallback(() => {
        setSelectedCenter('');
        setSelectedCapacityRange('');
        setSelectedRoom(null);
    }, [setSelectedCenter, setSelectedCapacityRange, setSelectedRoom]);

    useEffect(() => {
        if (trainingCenterOptions.length === 1) {
            setSelectedCenter(trainingCenterOptions[0].value);
        } else if (trainingCenterOptions.length > 0 && user?.campus !== undefined) {
            const tc = trainingCenterOptions.find(
                (tc) => tc.value.toString() === (user.campus as unknown as number).toString(),
            );
            if (tc) {
                setSelectedCenter(tc.value);
            }
        }
    }, [trainingCenterOptions, user?.campus]);

    useEffect(() => {
        handleClearFilters();
    }, [handleClearFilters, refreshKey]);

    useEffect(() => {
        if (selectedDates.startDate && selectedDates.endDate) {
            fetchRooms();
        }
    }, [selectedDates.startDate, selectedDates.endDate, fetchRooms]);

    useEffect(() => {
        const filtered = rooms
            .filter((room) => {
                if (selectedCenter && room.trainingCenterId.toString() !== selectedCenter.toString()) {
                    return false;
                }

                if (selectedCapacityRange) {
                    const range = CAPACITY_RANGES.find((r) => r.label === selectedCapacityRange);
                    if (range && (room.capacity < range.min || room.capacity > range.max)) {
                        return false;
                    }
                }

                return true;
            })
            .sort((a, b) => {
                // Nem foglalt termek előre a foglalttal szemben
                if (!a.isOccupied && b.isOccupied) return -1;
                if (a.isOccupied && !b.isOccupied) return 1;
                // Kiemelt terem előre a nem kiemelt termekkel szemben
                if (a.isHighlighted && !b.isHighlighted) return -1;
                if (!a.isHighlighted && b.isHighlighted) return 1;
                // ABC sorrend ezeken belül
                return a.name.localeCompare(b.name);
            });

        setFilteredRooms(filtered);
    }, [rooms, selectedCenter, selectedCapacityRange]);

    useEffect(() => {
        if (changedRoomId) {
            const room = rooms.find((room) => room.id.toString() === changedRoomId.toString());

            if (room) {
                setSelectedCenter(
                    trainingCenterOptions.find(
                        (option) => option.value.toString() === room.trainingCenterId?.toString(),
                    )?.value || '',
                );

                const matchingRange = CAPACITY_RANGES.find(
                    (range) => room.capacity >= range.min && room.capacity <= range.max,
                );
                if (matchingRange) {
                    setSelectedCapacityRange(matchingRange.label);
                }

                setSelectedRoom(room);
            }
        }

        return () => {
            setSelectedRoom(null);
        };
    }, [changedRoomId, rooms, setSelectedRoom, trainingCenterOptions]);

    const handleRoomSelect = (room: RoomOption | null) => {
        setSelectedRoom(room);
    };

    return (
        <Grid item xs={12} sx={{ mt: 0 }}>
            <Paper elevation={4} sx={{ p: 2, paddingTop: 0, height: 'auto' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="text" sx={{ paddingY: 1 }} onClick={handleClearFilters} disabled={isFetching}>
                        Szűrés törlése
                    </Button>
                </Box>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <Autocomplete
                        value={trainingCenterOptions.find((option) => option.value === selectedCenter) || null}
                        onChange={(_, newValue) => {
                            setSelectedCenter(newValue ? newValue.value : '');
                            setSelectedCapacityRange('');
                            setSelectedRoom(null);
                        }}
                        options={trainingCenterOptions}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Képzési központ"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        noOptionsText="Nincs találat"
                        disabled={isFetching}
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                    <Autocomplete
                        value={CAPACITY_RANGES.find((range) => range.label === selectedCapacityRange) || null}
                        onChange={(_, newValue) => {
                            setSelectedCapacityRange(newValue ? newValue.label : '');
                            setSelectedRoom(null);
                        }}
                        options={CAPACITY_RANGES}
                        getOptionLabel={(option) => `${option.label} fő`}
                        renderInput={(params) => <TextField {...params} label="Férőhely" variant="outlined" />}
                        disabled={
                            !selectedDates.startDate ||
                            !selectedDates.endDate ||
                            !selectedCenter ||
                            new Date(selectedDates.startDate) > new Date(selectedDates.endDate) ||
                            isFetching
                        }
                        noOptionsText="Nincs találat"
                    />
                </FormControl>

                <FormControl fullWidth>
                    <Autocomplete
                        value={selectedRoom}
                        onChange={(_, newValue) => handleRoomSelect(newValue)}
                        options={filteredRooms}
                        getOptionLabel={(option) => option.name}
                        disabled={
                            !selectedDates.startDate ||
                            !selectedDates.endDate ||
                            !selectedCenter ||
                            !selectedCapacityRange ||
                            new Date(selectedDates.startDate) > new Date(selectedDates.endDate) ||
                            isFetching
                        }
                        ListboxProps={{
                            style: {
                                maxHeight: '240px',
                                overflowY: 'auto',
                            },
                        }}
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                {...props}
                                sx={{
                                    backgroundColor: option.isOccupied ? '#f0f0f0' : 'transparent',
                                    cursor: option.isOccupied ? 'not-allowed' : 'pointer',
                                    opacity: option.isOccupied ? 0.6 : 1,
                                    pointerEvents: option.isOccupied ? 'none' : 'auto',
                                }}
                            >
                                <Stack sx={{ width: '100%' }}>
                                    <Box
                                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                    >
                                        <Typography variant="body1">{option.name}</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                            <PersonIcon fontSize="small" />
                                            <Typography>{option.capacity} fő</Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            gap: 0.5,
                                        }}
                                    >
                                        <AccessTimeIcon
                                            fontSize="small"
                                            sx={{
                                                color: option.isOccupied ? '#FF8683' : '#35A899',
                                            }}
                                        />
                                        <Typography
                                            variant="body2"
                                            sx={{ color: option.isOccupied ? '#FF8683' : '#35A899' }}
                                        >
                                            {option.isOccupied ? 'Foglalt' : 'Nem foglalt'}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        )}
                        renderInput={(params) => <TextField {...params} label="Terem" />}
                        noOptionsText="Nincs találat"
                    />
                </FormControl>
            </Paper>
        </Grid>
    );
}
