import React from 'react';
import { createPortal } from 'react-dom';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';

const CustomDialog = ({
    open,
    onClose,
    title,
    children,
    sx = {},
    styleOverrides = {},
}: {
    open: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
    sx?: object;
    styleOverrides?: React.CSSProperties;
}) => {
    const theme = useTheme();

    if (!open) return null;

    return (
        <>
            {createPortal(
                <>
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            bgcolor: 'rgba(0, 0, 0, 0.25)',
                            zIndex: theme.zIndex.modal - 1,
                        }}
                        onClick={onClose}
                    />

                    <Box
                        sx={{
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1300,
                            backgroundColor: 'background.paper',
                            boxShadow: 24,
                            borderRadius: 2,
                            maxWidth: '800px',
                            width: '100%',
                            maxHeight: '90vh',
                            display: 'flex',
                            flexDirection: 'column',
                            ...sx,
                        }}
                        style={styleOverrides}
                    >
                        <DialogTitle>{title}</DialogTitle>
                        <Box
                            sx={{
                                flex: 1,
                                overflowY: 'auto',
                            }}
                        >
                            {children}
                        </Box>
                    </Box>
                </>,
                document.body,
            )}
        </>
    );
};

export default CustomDialog;
