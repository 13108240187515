import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import axios from 'axios';
import { useContext } from 'react';
import { downloadFile } from '../../../../../utils/AppConst';
import { getJWTToken } from '../../../../../utils/AuthHelper';
import SemesterContext from '../../../../../components/SemesterSelector/SemesterContextProvider';
import { openStudentPreview } from '../utils';
import DownloadButton from './DownloadButton';
import { DataRow } from '../../..';

const Opener = ({ Icon, url }: { Icon: JSX.Element; url: string }) => {
    const semester = useContext(SemesterContext);
    const handleClick = openStudentPreview(semester, url);
    return (
        <IconButton
            color="primary"
            sx={{ borderStyle: 'solid', borderWidth: '1px' }}
            size="small"
            onClick={handleClick}
        >
            <Tooltip title="Megtekintés">{Icon}</Tooltip>
        </IconButton>
    );
};

export const handleClick = (url: string, name: string) => {
    const AUTH_TOKEN = getJWTToken();
    return axios
        .get(url, {
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${AUTH_TOKEN}`,
            },
        })
        .then((response) => {
            downloadFile(response.data, name);
        });
};

export const filenameMap = {
    summary: 'osszefoglalo_dokumentum',
    previousSummary: 'korabbi_osszefoglalo_dokumentum',
    selfEvaluation: 'onertekelesi_kerdoiv',
    admission: 'felveteli_kerdoiv',
    commissionEvaluation: 'bizottsagi_ertekelo_lap',
};

export type SelfEvaluationDocumentType =
    | 'summary'
    | 'previousSummary'
    | 'selfEvaluation'
    | 'admission'
    | 'commissionEvaluation';

export interface DownloadTemplateProps extends GridRenderCellParams {
    url: string;
    endpoint?: (semesterId: string) => string;
    filename: string;
    Icon: JSX.Element;
    status: string | boolean | null;
    type: SelfEvaluationDocumentType;
}

export interface DownloadTemplatePropsMobile {
    url: string;
    endpoint?: (semesterId: string) => string;
    filename: string;
    Icon: JSX.Element;
    status: string | boolean | null;
    type: SelfEvaluationDocumentType;
    row: DataRow;
}

export default function DownloadTemplate({ Icon, status, url, endpoint, type, ...params }: DownloadTemplateProps) {
    const semester = useContext(SemesterContext);
    const filename = filenameMap[type];

    if (params.row.status === status) {
        return (
            <Grid container spacing={1} justifyContent="center">
                <Grid item>
                    <Opener Icon={Icon} url={url} />
                </Grid>
                {endpoint ? <DownloadButton type={type} url={endpoint(semester)} filename={filename + '.pdf'} /> : null}
            </Grid>
        );
    }

    return null;
}

export function DownloadTemplateMobile({ Icon, status, url, endpoint, type, ...params }: DownloadTemplatePropsMobile) {
    const semester = useContext(SemesterContext);
    const filename = filenameMap[type];

    if (params.row.status === status) {
        return (
            <Grid container spacing={1} justifyContent="center">
                <Grid item>
                    <Opener Icon={Icon} url={url} />
                </Grid>
                {endpoint ? <DownloadButton type={type} url={endpoint(semester)} filename={filename + '.pdf'} /> : null}
            </Grid>
        );
    }

    return null;
}
