import { useDispatch, useSelector } from 'react-redux';
import settingsService from '../../../../services/administration/settings';
import { create } from '../../../../store/notification';
import Switch from '@mui/material/Switch';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { RootState } from '../../../../store';

export function ValueCellRenderer(params: GridRenderCellParams) {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.auth);

    const isSwitch = ['MUST_ACCEPT_PRIVACY_STATEMENT', 'CERTIFICATES_CURRENT_STATUS_DISPLAY'].some(
        (setting) => setting === params.row.settingKey,
    );
    if (isSwitch && !user.archive) {
        return (
            <Switch
                defaultChecked={params.row.settingVal === 'true'}
                color="info"
                onChange={(_, checked) =>
                    settingsService
                        .changeSettingValue(params.row.id, { settingVal: checked.toString() })
                        .then(() =>
                            dispatch(
                                create({
                                    type: 'success',
                                    message: 'Sikeres mentés!',
                                }),
                            ),
                        )
                        .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen mentés!' })))
                }
            />
        );
    }
}
