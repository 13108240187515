import VisibilityIcon from '@mui/icons-material/Visibility';
import { StudentOperationsCellRendererMobileProps, StudentOperationsCellRendererProps } from '.';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { STATUS } from '../Status/utils';
import { endpoints } from '../../../../utils/endPoints';
import Grid from '@mui/material/Grid';
import { useContext } from 'react';
import SemesterContext from '../../../../components/SemesterSelector/SemesterContextProvider';
import DownloadButton from './Download/DownloadButton';
import { filenameMap } from './Download/DownloadTemplate';
import { Tooltip } from '@silinfo/front-end-template';
import IconButton from '@mui/material/IconButton';
import { openStudentPreview } from './utils';

export default function CommissionEvaluation({ params }: StudentOperationsCellRendererProps) {
    return <CommissionEvaluationComponent status={params.row.status || STATUS.UNAVAILABLE} />;
}

export function CommissionEvaluationMobile({ params }: StudentOperationsCellRendererMobileProps) {
    return <CommissionEvaluationComponent status={params.row.status || STATUS.UNAVAILABLE} />;
}

function CommissionEvaluationComponent({ status }: { status: string }) {
    const type = 'commissionEvaluation';
    const semester = useContext(SemesterContext);
    const filename = filenameMap[type];
    const url = clientEndPoints.student_self_assessment_commission_evaluation;

    if (status !== STATUS.COMPLETED) {
        return <></>;
    }

    return (
        <Grid item container xs={12} justifyContent="center" gap={1}>
            <Grid item>
                <Tooltip title="Megtekintés">
                    <IconButton
                        onClick={openStudentPreview(semester, url)}
                        color="primary"
                        size="small"
                        sx={{ borderStyle: 'solid', borderWidth: '1px' }}
                    >
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title="Letöltés">
                    <DownloadButton
                        type={type}
                        url={endpoints.students.selfEvaluation.commissionEvaluationPdf(semester)}
                        filename={filename + '.pdf'}
                    />
                </Tooltip>
            </Grid>
        </Grid>
    );
}
