import { ChangeEvent, useRef } from 'react';
import UploadDocumentIcon from '../Icons/UploadDocumentIcon.svg';
import { useDispatch } from 'react-redux';
import { endpoints } from '../../../../utils/endPoints';
import API from '../../../../api';
import { create } from '../../../../store/notification';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { convertDate } from '../utils';
import { Document } from '../types';
import axios from 'axios';

export default function FileUpload({
    handleChangeDocuments,
    isRegistrated,
    document,
    serial,
    token,
    admissionDisabled,
}: {
    handleChangeDocuments: (documentList: Document[]) => void;
    isRegistrated: boolean;
    document: Document;
    serial: number | null;
    token: string;
    admissionDisabled: boolean;
}) {
    const fileInput = useRef<HTMLInputElement | null>(null);
    const dispatch = useDispatch();

    const handleUpload = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const files = (e.target as HTMLInputElement).files;
        if (files && files[0]) {
            const formData = new FormData();
            formData.append('file', files[0]);
            formData.append('type', 'admission_applicant_program');
            formData.append('fileName', files[0].name);
            const endpoint = isRegistrated
                ? endpoints.admission.uploadDocumentMcc(serial, document.id)
                : endpoints.admission.uploadDocument(token, serial, document.id);

            const request = isRegistrated
                ? API.post(endpoint, formData, {
                      headers: {
                          'Content-Type': 'multipart/form-data',
                      },
                  })
                : axios.post(endpoint, formData, {
                      headers: {
                          'Content-Type': 'multipart/form-data',
                      },
                  });
            request
                .then(() => {
                    dispatch(create({ type: 'success', message: 'Sikeres feltöltés' }));
                    const endpoint = isRegistrated
                        ? endpoints.admission.getDocumentsMcc(serial)
                        : endpoints.admission.getDocuments(token, serial?.toString());
                    const request = isRegistrated ? API.get(endpoint) : axios.get(endpoint);
                    request.then((response) => handleChangeDocuments(response.data.uploadableDocuments));
                })
                .catch((err) => {
                    dispatch(create({ type: 'error', message: err.response.data.err ?? 'Sikertelen feltöltés' }));
                });
        }
    };

    return (
        <>
            <TextField
                inputRef={fileInput}
                type="file"
                sx={{
                    display: 'none',
                }}
                onChange={handleUpload}
            />
            <Button
                onClick={(e) => {
                    if (new Date(convertDate(document.deadline)) > new Date() && fileInput.current) {
                        fileInput.current.click();
                        e.stopPropagation();
                    }
                }}
                variant="contained"
                sx={{ textDecoration: 'none' }}
                startIcon={<img src={UploadDocumentIcon} />}
                disabled={new Date(convertDate(document.deadline)) < new Date() || admissionDisabled}
            >
                Fájl kiválasztása
            </Button>
        </>
    );
}
