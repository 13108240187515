import { Option } from '../../../../utils/AppConst';

export interface ITargetAudience {
    id: number;
    type: string;
    campus: Option[];
    trainingLevel: Option[];
    trainingProgram: Option[];
    collegeYear: Option[];
}

export const initialTargetAudienceForm = {
    id: 0,
    tenant: '',
    campus: [],
    trainingLevel: [],
    trainingProgram: [],
    collegeYear: [],
};

export type ITargetAudienceForm = typeof initialTargetAudienceForm;
