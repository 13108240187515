/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState, useContext, PropsWithChildren, useCallback } from 'react';
import { Option } from '@silinfo/front-end-template';
import { RoomOption } from './types';

interface RoomContextType {
    selectedRoom: RoomOption | null;
    setSelectedRoom: (room: RoomOption | null) => void;
    selectedDates: { startDate: string; endDate: string };
    setSelectedDates: (dates: { startDate: string; endDate: string }) => void;
    trainingCenterOptions: Option[];
    setTrainingCenterOptions: (options: Option[]) => void;
    changedRoomId: number | null;
    setChangedRoomId: (id: number | null) => void;
    readonly: boolean;
    setReadonly: (readonly: boolean) => void;

    refreshKey: boolean;
    triggerRefresh: () => void;
    currentLocationIndex: number;
    setCurrentLocationIndex: (index: number) => void;
}

export const RoomContext = createContext<RoomContextType>({
    selectedRoom: null, // Kiválasztott terem
    setSelectedRoom: () => {},
    selectedDates: { startDate: '', endDate: '' }, // Kiválasztott dátumok a fő formban
    setSelectedDates: () => {},
    trainingCenterOptions: [], // Teremkereső campus lista
    setTrainingCenterOptions: () => {},
    changedRoomId: null, // Ezzel állítjuk be a formból a teremkereső termét
    setChangedRoomId: () => {},
    readonly: false,
    setReadonly: () => {},
    refreshKey: false,
    triggerRefresh: () => {}, // Szűrő törlése
    currentLocationIndex: 0, // A teremkereső által vezérelt helyszín indexe
    setCurrentLocationIndex: () => {},
});

export const RoomContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [selectedRoom, setSelectedRoom] = useState<RoomOption | null>(null);
    const [changedRoomId, setChangedRoomId] = useState<number | null>(null);
    const [trainingCenterOptions, setTrainingCenterOptions] = useState<Option[]>([]);
    const [selectedDates, setSelectedDates] = useState({ startDate: '', endDate: '' });
    const [readonly, setReadonly] = useState(false);
    const [currentLocationIndex, setCurrentLocationIndex] = useState(0);
    const [refreshKey, setRefreshKey] = useState(false);

    const triggerRefresh = useCallback(() => {
        setRefreshKey((prev) => !prev);
    }, []);

    const contextValue: RoomContextType = {
        selectedRoom,
        setSelectedRoom,
        selectedDates,
        setSelectedDates,
        trainingCenterOptions,
        setTrainingCenterOptions,
        changedRoomId,
        setChangedRoomId,
        readonly,
        setReadonly,
        refreshKey,
        triggerRefresh,
        currentLocationIndex,
        setCurrentLocationIndex,
    };

    return <RoomContext.Provider value={contextValue}>{children}</RoomContext.Provider>;
};

export const useRoomContext = () => {
    const context = useContext(RoomContext);

    if (context === undefined) {
        throw new Error('useRoomContext must be used within a RoomContextProvider');
    }

    return context;
};

export default RoomContext;
