import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import School from '@mui/icons-material/School';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import Layout from '../components/Layout';
import MCCLoading from '../components/MCCLoading';
import OffLayout from '../components/OffLayout';
import Error from '../pages/Error';
//import TestEnv from '../pages/Test/env';
import MainPage from '../pages/Main/MainPage';
import TestRoutes from '../pages/Test/routes';
import { RootState, history } from '../store';
import StudentLayout from '../studentPages/components/Layout';
import { getLayoutType, setLayoutType } from '../utils/AuthHelper';
import { clientEndPoints } from '../utils/clientEndPoints';
import useSwitchCheck, { LayoutType } from '../utils/useSwitchCheck';
import { administration } from './Administration/Administration';
import { courseManagement } from './CourseManagement/CourseManagement';
import { finance } from './Finance/Finance';
import formPreviews from './FormPreviews';
import { masterData } from './MasterData/MasterData';
import { selfEvaluation } from './SelfEvaluation/SelfEvaluation';
import { scholarship } from './Scholarship/Scholarship';
import { studentPage } from './StudentPage';
import { systemMessages } from './SystemMessages';
import { admission } from './Admission/Admission';
import { admissionLandingPage } from './Admission/AdmissionLandingPage';
import { admissionTokenExpiredPage } from './Admission/AdmissionTokenExpiredPage';
import { admissionTokenNotFoundPage } from './Admission/AdmissionTokenNotFoundPage';
import { admissionPage } from './Admission/AdmissionPage';
import { admissionFormPage } from './Admission/AdmissionFormPage';
import { useTenant } from '../components/TenantContext';
import TesztVaratlanHiba from '../pages/Error/TesztVaratlanHiba';

const whiteList = ['/', '/teszt-varatlan-hiba', '/500', '/503', '/404', '/login', '/403', '/system-messages'];

function Brother({ canSwitch }: { canSwitch: LayoutType[] }) {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useSelector((state: RootState) => state.auth);
    const { accessTree } = useSelector((state: RootState) => state.accessManagement);
    const [loading, setLoading] = useState<boolean>(true);
    const tenant = useTenant();
    useEffect(() => {
        const regex = /\/felveteli\/(?=.*[a-zA-Z0-9]).{10,}$/;
        const menuKey = Object.keys(clientEndPoints).find((elem) => {
            const endPointSplit = clientEndPoints[elem as keyof typeof clientEndPoints].split('/');
            const locationNameSplit = location.pathname.split('/');

            if (endPointSplit.length !== locationNameSplit.length) return false;

            let endPointString = '';
            let locationNameString = '';

            for (let i = 0; i < endPointSplit.length; i++) {
                if (endPointSplit[i][0] !== ':') {
                    endPointString += endPointSplit[i];
                    locationNameString += locationNameSplit[i];
                }
            }
            return endPointString === locationNameString;
        });

        if (canSwitch.length === 2 && location.pathname !== '/switch-layout' && !getLayoutType()) {
            navigate('/switch-layout');
        }

        if (canSwitch[0] && canSwitch.length === 1) {
            setLayoutType(canSwitch[0]);
        }

        if (!['/switch-layout', '/felveteli', '/felveteli/mcc'].includes(location.pathname)) {
            if (
                !whiteList.includes(location.pathname) &&
                (!menuKey ||
                    !accessTree[menuKey] ||
                    !accessTree[menuKey].some((e: string) => user.roles.includes(e))) &&
                !user.admin
            ) {
                if (!regex.test(window.location.pathname)) {
                    navigate('/403');
                }
            } else if (!whiteList.includes(location.pathname)) {
                setLayoutType(location.pathname.split('/')[1] === 'student' ? 'student' : 'admin');
            }
        }
        setLoading(false);
        document.title = tenant || 'TAR';
    }, [location, location.pathname, canSwitch, navigate, accessTree, user.admin, user.roles, tenant]);

    if (loading) {
        return <MCCLoading />;
    }
    return <></>;
}

function Routing() {
    const canSwitch = useSwitchCheck();
    const { layoutType } = useSelector(
        (state: RootState) => state.auth,
        (left, right) => JSON.stringify(left.layoutType) === JSON.stringify(right.layoutType),
    );

    const routingArray = useMemo(() => {
        return routingArr(canSwitch.layoutTypes, layoutType);
    }, [canSwitch, layoutType]);

    const Routing = () => useRoutes(routingArray);
    if (canSwitch.loading) return <MCCLoading />;
    return (
        <>
            <Brother canSwitch={canSwitch.layoutTypes} />
            <Routing />
        </>
    );
}
export function routingArr(canSwitch: string[] = [], layoutType = 'student') {
    const test = {
        path: '/test',
        children: [
            {
                path: 'routes',
                element: <TestRoutes />,
            },
            /*
            {
                path: 'env',
                element: <TestEnv />,
            },
             */
        ],
    };

    const loggedIn = {
        path: '/',
        element: !layoutType ? <MCCLoading /> : layoutType === 'admin' ? <Layout /> : <StudentLayout />,
        children: [
            {
                path: '',
                element: !layoutType ? (
                    <MCCLoading />
                ) : layoutType === 'admin' ? (
                    <MainPage />
                ) : (
                    <Navigate to="student/calendar" />
                ),
            },
            masterData,
            administration,
            selfEvaluation,
            scholarship,
            courseManagement,
            finance,
            studentPage,
            systemMessages,
            admission,
        ],
    };

    const switchLayout = {
        path: '/switch-layout',
        element:
            canSwitch.length === 2 ? (
                <OffLayout>
                    <Outlet />
                </OffLayout>
            ) : (
                <Navigate to="/" />
            ),
        children: [
            {
                path: '',
                element: (
                    <Grid item container spacing={2}>
                        <Grid item xs={12}>
                            <Typography textAlign="center" variant="h4">
                                Nézet kiválasztása
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                fullWidth
                                onClick={() => {
                                    setLayoutType('admin');
                                    history.push('/');
                                }}
                                sx={{
                                    padding: '8px',
                                    height: 56,
                                }}
                                endIcon={<AdminPanelSettings />}
                            >
                                Admin
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                fullWidth
                                onClick={() => {
                                    setLayoutType('student');
                                    history.push('/');
                                }}
                                sx={{
                                    padding: '8px',
                                    height: 56,
                                }}
                                endIcon={<School />}
                            >
                                Hallgató
                            </Button>
                        </Grid>
                    </Grid>
                ),
            },
        ],
    };

    const errors = {
        path: '/',
        element: (
            <OffLayout>
                <Outlet />
            </OffLayout>
        ),
        children: [
            {
                path: '403',
                element: <Error statusCode={403} />,
            },
            {
                path: '404',
                element: <Error statusCode={404} />,
            },
            {
                path: '500',
                element: <Error statusCode={500} />,
            },
            {
                path: '503',
                element: <Error statusCode={503} />,
            },
            {
                path: '*',
                element: <Navigate to="/404" />,
            },
        ],
    };

    return [
        {
            path: 'teszt-varatlan-hiba',
            element: <TesztVaratlanHiba />,
        },
        test,
        loggedIn,
        switchLayout,
        errors,
        formPreviews,
        admissionLandingPage,
        admissionPage,
        admissionFormPage,
        admissionTokenExpiredPage,
        admissionTokenNotFoundPage,
    ];
}

export default Routing;
