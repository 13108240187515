import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import api from '../../api';
import { create } from '../../store/notification';
import { getIsNewLogin, setForceShowFirstLoginPopup, setIsNewLogin } from '../../utils/AuthHelper';
import { IUserNotification } from '../../utils/Interfaces/IUserNotification';
import { endpoints } from '../../utils/endPoints';
import Actions from './Actions';
import { theme } from '../../utils/theme';

const useStyles = makeStyles(() => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

interface UserNotificationsProps {
    setIsNotificationOpen: Dispatch<SetStateAction<boolean>>;
}

const pathsToCheck = [
    '/felveteli',
    '/previous-summary',
    '/self-evaluation',
    '/self-assessment',
    '/read-fill-commission-evaluation',
    '/commission-evaluation',
    '/commission-evaluation-sheet/fill',
    '/commission-evaluation-sheet/preview',
    '/preview',
    '/questionnaires/',
];

export default function UserNotifications(props: UserNotificationsProps) {
    const { setIsNotificationOpen } = props;
    const [notifications, setNotifications] = useState<IUserNotification[]>([]);
    const [open, setOpen] = useState(true);
    const [index, setIndex] = useState<number>(0);
    const [visitedIndices, setVisitedIndices] = useState<number[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleSeen = useCallback(() => {
        const current = notifications[index];
        if (current.id) {
            const url = endpoints.administration.userNotifications.seen;
            api.post(url, { 'id': [current.id] }).catch(() => {
                setLoading(false);
                dispatch(create({ type: 'error', message: 'Szerver hiba' }));
            });
        }
    }, [dispatch, index, notifications]);

    useEffect(() => {
        if (!pathsToCheck.some((path) => location.pathname.includes(path))) {
            setLoading(true);
            api.get<IUserNotification[]>(endpoints.administration.userNotifications.list, {
                params: getIsNewLogin() === 'true' ? { firstLoginNotifications: 1 } : {},
            }).then((resp) => {
                setNotifications(resp.data);
                if (resp.data.length === 0) {
                    setForceShowFirstLoginPopup('false');
                    setIsNotificationOpen(false);
                }
                setIsNewLogin('false');
                setLoading(false);
            });
        }
    }, [setIsNotificationOpen]);

    useEffect(() => {
        if (!location.pathname.includes('/felveteli')) {
            if (!visitedIndices.includes(index) && !loading) {
                handleSeen();
                setVisitedIndices((prev) => [...prev, index]);
            }
            if (index === notifications.length - 1) {
                setForceShowFirstLoginPopup('false');
            }
        }
    }, [handleSeen, index, loading, visitedIndices, notifications]);

    const notification = notifications[index];

    if (loading || !notification) return null;

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle className={classes.title}>
                {notification.messageTitle || notification.notificationType.label}
            </DialogTitle>
            <div>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{ __html: notification.messageContent || '-' }} />
                </DialogContent>
                <Actions index={index} setIndex={setIndex} notifications={notifications} setOpen={setOpen} />
            </div>
        </Dialog>
    );
}
