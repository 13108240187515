import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import { FieldGenerator, Input, Option, TextField } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import EditContext from './EditContext';
import { IForm } from '../utils/Interfaces/interfaces';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { TypographyProps } from '@mui/material/Typography';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import GoogleMapsAutocomplete from '../pages/CourseManagement/Courses/Events/GoogleMapsAutocomplete';

interface ISpecialInput {
    type: 'location';
    name: string;
    gifts: [];
    itRequests: [];
}
interface ILocation {
    location: string;
    lat: number;
    lng: number;
}

type DynamicFieldInputType = Input | ISpecialInput;

export interface DynamicFieldsProps {
    keyName: string;
    errorKey?: string;
    label: string;
    header?: TypographyProps['variant'];
    required?: boolean;
    inputFields: DynamicFieldInputType[] | ((index: number) => DynamicFieldInputType[]);
    rawFields?: DynamicFieldInputType[];
    readOnly?: boolean | undefined;
}

const isFunction = (
    input: DynamicFieldInputType[] | ((index: number) => DynamicFieldInputType[]),
): input is (index: number) => DynamicFieldInputType[] => typeof input === 'function';

const getValue = (values: IForm, keyName: string): unknown => {
    const keys = keyName.split('.');
    if (keys.length === 1) {
        return values[keyName];
    }

    return getValue(values[keys[0]] as IForm, keys.slice(1).join('.'));
};

export const CustomFieldGenerator = <T extends IForm>({
    field,
    index,
    keyName,
    errorKey,
    disabled,
}: {
    field: DynamicFieldInputType;
    index: number;
    keyName: string;
    errorKey?: string;
    disabled: boolean;
}) => {
    const formikProps = useFormikContext<T>();
    const { setFieldValue } = formikProps;
    const values = getValue(formikProps.values, keyName) as IForm[];
    const parentIndex = keyName.match(/\d+/)?.[0];
    const createdErrorKey = !errorKey
        ? `${keyName}[${index}].${field.name}`.replace(/\.\d+\./, `[${parentIndex}].`)
        : `${errorKey}[${index}].${field.name}`;
    const setter = useCallback(
        (location: ILocation) => {
            if (location) {
                setFieldValue(keyName + '.' + index, { ...location });
            }
        },
        [index, keyName, setFieldValue],
    );
    if (field.type === 'location') {
        return (
            <GoogleMapsAutocomplete
                setter={setter}
                disabled={disabled}
                initialValue={
                    values?.[index]
                        ? {
                              description: (values[index] as Record<string, string>).location || '',
                          }
                        : undefined
                }
                error={formikProps.errors[createdErrorKey] ? 'Ez az érték nem lehet üres.' : ''}
            />
        );
    }

    if (field.props?.clearable && field.type === 'select') {
        return (
            <Autocomplete
                options={field.options}
                isOptionEqualToValue={(option: Option, value: Option) =>
                    option.value == value.value || '' + value == option.value
                }
                getOptionLabel={(option) =>
                    option.label || field.options.find((o) => o.value == '' + option)?.label || ''
                }
                value={(values?.[index]?.[field.name] as Option) || { value: '', label: '' }}
                onChange={(_, value) =>
                    formikProps.setFieldValue(keyName + '.' + index + '.' + field.name, value?.value || value)
                }
                size="small"
                renderInput={(params) => <TextField {...params} label={field.label} />}
                noOptionsText="Nincs találat"
                disabled={disabled || field.props?.disabled}
            />
        );
    }
    const props = {
        ...field,
        ...formikProps,
        name: keyName + '.' + index + '.' + field.name,
        format: { xs: 12 },
        props: {
            ...(field.type !== 'backendSelect'
                ? {
                      value: values?.[index]?.[field.name] || (field.type === 'multiselect' ? [] : ''),
                  }
                : {}),
            error: formikProps.errors[createdErrorKey],
            helperText: formikProps.errors[createdErrorKey],
            ...field.props,
            disabled: disabled || field.props?.disabled,
        },
    };

    const Field = FieldGenerator as React.FC<typeof props>;

    return (
        <div>
            <Field {...props} />
        </div>
    );
};

interface FieldProps {
    id: number;
    index: number;
    label: string;
    inputFields: DynamicFieldInputType[] | ((index: number) => DynamicFieldInputType[]);
    required?: boolean;
    disabled: boolean;
    handleDelete: (id: number, index: number) => void;
    header?: TypographyProps['variant'];
    keyName: string;
    errorKey?: string;
}

const useFields = (
    index: number,
    inputFields: DynamicFieldInputType[] | ((index: number) => DynamicFieldInputType[]),
) => {
    return isFunction(inputFields) ? inputFields(index) : inputFields;
};

export const Field = ({
    id,
    index,
    label,
    inputFields,
    required,
    disabled,
    handleDelete,
    header,
    keyName,
    errorKey,
}: FieldProps) => {
    const headerElement = header ?? 'h6';
    const fields = useFields(index, inputFields);
    return (
        <Grid item container xs={12} spacing={1} key={id}>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography variant={headerElement} display="flex" justifyContent="space-between" alignItems="center">
                    <span>
                        {index + 1}. {label}
                    </span>
                    {(index > 0 || !required) && !disabled ? (
                        <Tooltip title="Törlés">
                            <IconButton color="error" onClick={() => handleDelete(id, index)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </Typography>
            </Grid>
            {fields.map((field) => (
                <Grid item key={field.name} xs={12}>
                    <CustomFieldGenerator {...{ field, index, disabled, keyName, errorKey }} />
                </Grid>
            ))}
        </Grid>
    );
};

export default function DynamicFields<T extends IForm>({
    keyName,
    label,
    inputFields,
    header,
    required,
    rawFields,
    readOnly,
}: DynamicFieldsProps) {
    const formikProps = useFormikContext<T>();
    const values = getValue(formikProps.values, keyName) as IForm[];
    const count = values?.length;
    const [fields, setFields] = useState<number[]>(
        Array(count)
            .fill(0)
            .map((_, i) => Date.now() + i),
    );
    const editState = useContext(EditContext);
    const disabled = editState?.[0] === false;

    useEffect(() => {
        if (count < fields.length) {
            setFields((prev) => prev.slice(0, count));
        }
    }, [count, fields.length]);

    const [numElements, setNumElements] = useState(0);

    const handleDelete = (id: number, index: number) => {
        setFields((prev) => prev.filter((val) => val !== id));
        formikProps.setFieldValue(
            keyName,
            values.filter((_, i) => i !== index),
        );

        setNumElements((prev) => prev - 1);
    };

    const handleAdd = () => {
        if (numElements >= 10) {
            return;
        }

        setNumElements((prev) => prev + 1);
        setFields((prev) => [...prev, Date.now()]);
        const fieldsToValues = rawFields || (isFunction(inputFields) ? inputFields(0) : inputFields);
        const newValues = fieldsToValues.reduce((acc, curr) => {
            acc[curr.name] = curr.type === 'multiselect' ? [] : '';
            return acc;
        }, {} as IForm);

        formikProps.setFieldValue(keyName, [...values, newValues]);
    };

    const canAddMore = values?.length < 10;

    return (
        <>
            {fields.length > 0 ? (
                fields.map((id, index) => (
                    <Field
                        key={id}
                        id={id}
                        index={index}
                        label={label}
                        inputFields={inputFields}
                        required={required}
                        disabled={disabled || !!readOnly}
                        handleDelete={handleDelete}
                        header={header}
                        keyName={keyName}
                    />
                ))
            ) : (
                <Grid item xs={12} textAlign="center" color="text.secondary">
                    <Typography>
                        <i>Nincs {label} megadva.</i>
                    </Typography>
                </Grid>
            )}
            {!disabled && !readOnly ? (
                <Grid item xs={12} textAlign="right">
                    <Tooltip title="Hozzáadás">
                        <>
                            {canAddMore && (
                                <IconButton color="success" onClick={handleAdd}>
                                    <Add />
                                </IconButton>
                            )}
                        </>
                    </Tooltip>
                </Grid>
            ) : null}
        </>
    );
}
