import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { AutoloadTable, Page } from '@silinfo/front-end-template';
import { useCallback, useEffect, useState } from 'react';
import { SemesterContextProvider } from '../../components/SemesterSelector/SemesterContextProvider';
import questionnaireTemplateService from '../../services/selfEvalution/questionnaireTemplate';
import { Option } from '../../utils/AppConst';
import BackendSelect from '../components/BackendSelect';
import { endpoints } from './../../utils/endPoints';
import utils, { names } from './utils';
import useCheckRefresh from '../../utils/useCheckRefresh';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { theme } from '../../utils/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { OperationsCellRendererMobile } from './CellRenderers/Operations';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { StatusCellRendererMobile } from './CellRenderers/Status';
import { allowedComponents, ComponentMapKeys } from './CellRenderers/Operations/utils';
import { useTenant } from '../../components/TenantContext';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    headingMain: {
        color: theme.palette.info.main,
        fontSize: '18px',
    },
    headingPaper: {
        padding: theme.spacing(1.5),
        marginBottom: theme.spacing(2.5),
        fontSize: '18px',
    },
    q: {
        padding: theme.spacing(1.5),
    },
    qContainer: {
        display: 'flex',
        border: '1px solid #647C9B',
        alignItems: 'center',
        margin: theme.spacing(1),
        padding: theme.spacing(1.5),
        boxSizing: 'border-box',
    },
    qBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    name: {
        fontSize: '14px',
    },
    courseCode: {
        fontSize: '11px',
    },
    status: {
        marginTop: theme.spacing(0.5),
        fontSize: '11px',
    },
    operations: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(1),
    },
}));

type SelfEvaluationTableRow =
    | string
    | {
          fillDate: string;
          id: number;
          status: string;
          file: {
              id: number;
              filename: string;
          };
      }
    | null;

interface UniversityIndexFile {
    filename: string;
    id: number;
}

export interface DataFile {
    id: number;
    filename: string;
}

export interface DataRow {
    status: string | null;
    id: number;
    name: string;
    file?: DataFile;
    fillDate: string;
    otherInfo?: Record<string, unknown>;
}

export interface SemesterData {
    'previousSummary': string;
    'selfAssessmentQuestionnaire': string;
    'selfAssessmentConversation': string;
    //'summaryDocument': string;
    'commissionEvaluationSheet': string;
    'universityIndex': { 'status': string; 'file': UniversityIndexFile };
    'careerGuidanceSheet': string;
    'completionSheet': string;
}

const findActive = (value: (Option & { active: boolean })[]) => value.find((item) => item.active);

export default function SelfAssessment() {
    const { header, columns, order } = utils;
    const [semester, setSemester] = useState<Option | null>(null);
    const [semesterData, setSemesterData] = useState<DataRow[]>([]);
    const [refresh, setRefresh] = useState(false);
    const refreshCheck = useCheckRefresh();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const classes = useStyles();
    const tenant = useTenant();

    const filter = useCallback(async () => {
        const { data, ...rest } = await questionnaireTemplateService.getSemester(semester?.value || '');
        return {
            data: {
                data: getMappedData(data, order),
            },
            ...rest,
        };
    }, [order, semester?.value]);

    useEffect(() => {
        const fetchData = async () => {
            if (semester?.value && !matches) {
                try {
                    const { data } = await questionnaireTemplateService.getSemester(semester.value);
                    setSemesterData(getMappedData(data, order));
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchData();
    }, [semester, matches, order]);

    return matches ? (
        <Page header={header(tenant || 'TAR')}>
            <Grid item xs={12}>
                <BackendSelect<Option & { active: boolean }>
                    url={endpoints.students.selfEvaluation.semesters}
                    label="Szemeszter"
                    responseKey=""
                    value={semester}
                    setter={setSemester}
                    defaultValueCondition={findActive}
                    setRefresh={setRefresh}
                />
            </Grid>
            <Grid item xs={12}>
                <SemesterContextProvider semester={semester?.value || ''}>
                    <Paper>
                        <AutoloadTable
                            filter={filter}
                            columns={columns(setRefresh)}
                            responseKey="data"
                            hideFooter
                            disableSelectionOnClick
                            disableColumnMenu
                            noAccordionHeader
                            refresh={JSON.stringify([refresh, semester?.value || '', refreshCheck])}
                            disableColumnReorder
                            disableColumnResize
                        />
                    </Paper>
                </SemesterContextProvider>
            </Grid>
        </Page>
    ) : (
        <Grid item xs={12}>
            <Typography className={classes.headingMain}>Önértékelés</Typography>
            <Grid item xs={12}>
                <BackendSelect<Option & { active: boolean }>
                    url={endpoints.students.selfEvaluation.semesters}
                    label="Szemeszter"
                    responseKey=""
                    value={semester}
                    setter={setSemester}
                    defaultValueCondition={findActive}
                    setRefresh={setRefresh}
                />
            </Grid>
            <Paper className={classes.paper} elevation={3}>
                <SemesterContextProvider semester={semester?.value || ''}>
                    {semesterData &&
                        semesterData.map((e) => (
                            <div key={e.name} className={classes.qContainer}>
                                <div className={classes.qBox}>
                                    <Typography className={classes.name}>{names[e.name]}</Typography>
                                    <div className={classes.status}>
                                        <StatusCellRendererMobile
                                            name={e.name || ''}
                                            st={e.status || ''}
                                            fillDate={e.fillDate || ''}
                                        />
                                    </div>
                                </div>
                                <div className={classes.operations}>
                                    <OperationsCellRendererMobile
                                        setRefresh={setRefresh}
                                        params={{ row: e } as GridRenderCellParams}
                                    />
                                </div>
                            </div>
                        ))}
                </SemesterContextProvider>
            </Paper>
        </Grid>
    );
}

function getMappedData(data: Record<string, unknown>, order: string[]) {
    const data2 = { ...data };

    // Töröljük azokat a kulcsokat, amelyek nincsenek az allowedKeys között
    Object.keys(data2).forEach((key) => {
        if (!allowedComponents.includes(key as ComponentMapKeys)) {
            delete data2[key];
        }
    });

    return Object.entries(data2 || {})
        .map(([key, value], index) => {
            const responseValue: SelfEvaluationTableRow = value as SelfEvaluationTableRow;
            if (typeof responseValue === 'object' && responseValue !== null) {
                return {
                    status: responseValue.status,
                    id: index + 1,
                    name: key,
                    fillDate: responseValue.fillDate,
                    file: responseValue.file,
                };
            } else {
                const ret = { status: responseValue, id: index + 1, name: key, otherInfo: {}, fillDate: '' };
                if (key === 'selfAssessmentQuestionnaire') {
                    ret.otherInfo = {
                        selfAssessmentQuestionnaireFilled: !!data?.selfAssessmentQuestionnaireFilled,
                    };
                }
                return ret;
            }
        })
        .sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
}
