import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { TableButton } from '@silinfo/front-end-template';
import useOpen from '../../utils/useOpen';
import Dialog from '@mui/material/Dialog';
import ActionDialogContent from './ActionDialogContent';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { clientEndPoints } from '../../utils/clientEndPoints';
import Success from '../SelfAssessment/CellRenderers/Status/Icons/Success';
import Error from '../SelfAssessment/CellRenderers/Status/Icons/Error';
import Typography from '@mui/material/Typography';
import { theme } from '../../utils/theme';

export default function Operations(params: GridRenderCellParams) {
    const { isOpen, open, close } = useOpen();
    const [timeZone, setTimeZone] = useState('Europe/Budapest');

    useEffect(() => {
        setTimeZone(localStorage.getItem('timezone') ?? 'Europe/Budapest');
    }, []);

    const openQuestionnaire = useCallback(() => {
        window.open(clientEndPoints.student_questionnaires_fill.replace(':id', '' + params.row.courseId), '_blank');
    }, [params.row.courseId]);

    const handleOpen = () => {
        if (params.row.anonym) {
            openQuestionnaire();
        } else {
            open();
        }
    };

    return (
        <>
            {(() => {
                if (params.row.isFilled) {
                    return (
                        <div style={{ display: 'flex', gap: '4px' }}>
                            <Success />
                            <Typography color={theme.palette.success.main}>Kitöltve</Typography>
                        </div>
                    );
                }

                if (
                    params.row.deadline &&
                    new Date(params.row.deadline).toLocaleDateString('hu-HU', { timeZone: timeZone }) <
                        new Date().toLocaleDateString('hu-HU', { timeZone: timeZone })
                ) {
                    return (
                        <div style={{ display: 'flex', gap: '4px' }}>
                            <Error />
                            <Typography color={theme.palette.error.main}>Lejárt</Typography>
                        </div>
                    );
                }

                return (
                    <TableButton color="primary" onClick={handleOpen}>
                        <DriveFileRenameOutlineIcon />
                    </TableButton>
                );
            })()}
            <Dialog open={isOpen} onClose={close} fullWidth>
                <ActionDialogContent close={close} isAnonymous={!!params.row.anonym} id={+params.row.courseId} />
            </Dialog>
        </>
    );
}
