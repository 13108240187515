import { default as api, default as API } from '../../api';
import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';

export interface IUsersForm
    extends Record<
        string,
        string | number | boolean | string[] | undefined | { origFileName: string; uuidFileName: string }
    > {
    id?: string | number;
    lastName: string;
    firstName: string;
    email: string;
    campus: string;
    mccId: string;
    disabled: boolean;
    roles: string[];
    profilePicture: { origFileName: string; uuidFileName: string };
    roleTrainingProgramProgramId: string;
    roleTrainingLeaderProgramId: string;
    roleEpCoordinatorCampusId: string;
    roleFinanceAdminCampusIds: string[];
    roleOperatorCampusId: string;
    activated: boolean;
}

const usersService = {
    ...crudTemplate<IUsersForm>(endpoints.masterData.users.main),
    sendActivationEmail: (userId: number, tenantId?: string) =>
        api.get(
            endpoints.masterData.users.sendActivationEmail.replace(':userId', userId?.toString() || '') +
                (tenantId ? '?tenantId=' + tenantId : ''),
        ),
    sendForgotPasswordTokenEmail: (userId: number) =>
        api.get(endpoints.masterData.users.forgotPasswordToken.replace('{id}', userId?.toString() || '')),
    roles: () => api.get(endpoints.masterData.users.roles),
    campuses: (userId?: number | string) => api.get(endpoints.masterData.users.campuses, { params: { user: userId } }),
    mccId: () => api.get(endpoints.masterData.users.mccId),
    trainingPrograms: () => api.get(endpoints.masterData.users.trainingPrograms),
    trainingPrograms2: (ids: (string | number | { value: number; label: string; duration: string })[]) =>
        api.get(endpoints.masterData.users.trainingPrograms, { params: { ids } }),
    get2: (id: string | number) => API.get(endpoints.masterData.users.get2.replace('{id}', id?.toString() || '')),
    getTrainingProgramByUserId: (userId: string | number) =>
        API.get(endpoints.masterData.users.getTrainingProgramByUserId.replace(':userId', userId?.toString() || '')),
    userSearchLoginInAs: (term: string) => API.get(endpoints.masterData.users.userSearch + '?term=' + term),
    userLoginInAs: (authId?: number) => API.post(endpoints.masterData.users.userLoginInAs, { authId: authId }),
    archive: (id: string | number) => api.post(endpoints.masterData.users.archive.replace('{id}', id.toString())),
    getCentralUser: (email: string) => api.get(endpoints.masterData.users.getCentralUser + '?email=' + email),
};

export default usersService;
