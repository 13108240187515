import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SubmitButton } from '@silinfo/front-end-template';
import { Link } from 'react-router-dom';

export default function FormExpired() {
    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Typography align="center" variant="h4">
                    {'A kérdőív kitöltési ideje lejárt.'}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Link to="/">
                    <SubmitButton fullWidth size="large">
                        Vissza a főoldalra
                    </SubmitButton>
                </Link>
            </Grid>
            <Grid item xs={12}>
                <a href={process.env.REACT_APP_AUTH_URL + '/logout'} style={{ textDecoration: 'none' }}>
                    <SubmitButton fullWidth size="large">
                        Kijelentkezés
                    </SubmitButton>
                </a>
            </Grid>
        </Grid>
    );
}
