import { makeStyles } from '@mui/styles';
import { IFillerStudent, IForm2, IForm2Rules, IResponse, MultiformReadonlyRules } from './types';
import { createFileNameString } from '../../utils/AppConst';
import Alert from '@mui/material/Alert';
import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '../../utils/AppConst';

function InfoBanner() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <div style={{ padding: '16px', marginTop: '24px' }}>
                <Alert
                    severity="info"
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                >
                    A kérdőív kitöltésre került, ezért a válaszok nem módosíthatók.
                </Alert>
            </div>
        </ThemeProvider>
    );
}

const dummyAsyncFunction = async () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve('OK');
        }, 1000);
    });
};

const useFormPreviewStyles = makeStyles(() => ({
    readableDisabled: {
        '& .Mui-disabled': {
            color: 'black',
            '-webkit-text-fill-color': 'black',
        },
    },
    readableEnabled: {
        '& .Mui-disabled': {
            color: 'rgba(0, 0, 0, 1)',
            '-webkit-text-fill-color': 'rgba(0, 0, 0, 1)',
        },
        color: 'rgba(0, 0, 0, 1)',
        '-webkit-text-fill-color': 'rgba(0, 0, 0, 1)',
    },
}));

const createFileName = (formName: string, student?: IFillerStudent) => {
    if (!student) return `${formName}.pdf`;
    const { mccId, lastName, firstName } = student;
    return createFileNameString(`${lastName}_${firstName}_${mccId}_${formName}.pdf`);
};

const REFRESH_LOCAL_STORAGE_KEY = 'questionnaire_refresh';

const getRefreshLocalStorage = (): boolean => {
    const refresh = localStorage.getItem(REFRESH_LOCAL_STORAGE_KEY);
    return refresh ? JSON.parse(refresh) : false;
};

const setRefreshLocalStorage = () => {
    localStorage.setItem(REFRESH_LOCAL_STORAGE_KEY, JSON.stringify(true));
};

const deleteRefreshLocalStorage = () => {
    localStorage.removeItem(REFRESH_LOCAL_STORAGE_KEY);
};

const getForms = (data: IResponse): IForm2[] | undefined => {
    let forms: IForm2[] | undefined = [];

    if (data.content) {
        forms.push(JSON.parse(data.content));
    } else {
        forms = data.forms;
    }

    return forms;
};

const multiformReadonlyRules = (
    isLastPage: boolean,
    dateFilled: boolean,
    groupDateFilled: boolean,
    formIndex: number,
    rules?: IForm2Rules,
): MultiformReadonlyRules => {
    let readonlyThis = dateFilled;
    let showFinalizeButton = true;
    let showSaveButton = isLastPage;

    if (formIndex === 1) {
        if (groupDateFilled) {
            readonlyThis = true;
            showFinalizeButton = showSaveButton = false;
        } else {
            // TODO: ez csak hallgatói oldalon
            showFinalizeButton = rules?.student_can_finalize === 'student_finalize_yes';

            switch (rules?.questionnaire2_write) {
                case 'not_writeable':
                    readonlyThis = true;
                    showFinalizeButton = false;
                    showSaveButton = false;
                    break;
                case 'if_first_is_finalized':
                    readonlyThis = !dateFilled;
                    showFinalizeButton = showFinalizeButton && dateFilled;
                    showSaveButton = showSaveButton && dateFilled;
                    break;
                default:
                    readonlyThis = false;
            }
        }
    } else if (formIndex === 0) {
        showSaveButton = showSaveButton && !dateFilled;
        showFinalizeButton = !dateFilled;
    }

    return {
        readonly: readonlyThis,
        showSaveButton: showSaveButton,
        showFinalizeButton: showFinalizeButton,
    };
};

export {
    dummyAsyncFunction,
    useFormPreviewStyles,
    createFileName,
    getRefreshLocalStorage,
    setRefreshLocalStorage,
    deleteRefreshLocalStorage,
    getForms,
    multiformReadonlyRules,
    InfoBanner,
};
