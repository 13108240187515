import Alert from '@mui/material/Alert';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';
import { FormikListPage, Header, TextField, useFetch } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import API from '../../../api';
import IEntityBase from '../../../interface/IEntityBase';
import { defaultTheme, Option } from '../../../utils/AppConst';
import { endpoints } from '../../../utils/endPoints';
import RefreshButton from './RefreshButton';
import { breadcrumbs, header, documentStatus, semesterStatus, tableProps } from './utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useTenant } from '../../../components/TenantContext';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import MCCLoading from '../../../components/MCCLoading';

const Input = (params: AutocompleteRenderInputParams) => <TextField {...params} label="Szemeszter" />;

type SemesterOption = Option<number> & { active?: boolean };

export default function Administration() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [semester, setSemester] = useState<SemesterOption | null>(null);
    const [refresh, setRefresh] = useState(false);
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');
    const { data: semesterOptions, loading: semestersLoading } = useFetch<SemesterOption[]>(
        endpoints.selfEvaluation.questionnaireProgram.semesters,
        '',
    );

    const { data: trainingLevels, loading: trainingLevelsLoading } = useFetch<IEntityBase[]>(
        endpoints.masterData.trainingLevels.main,
        'hydra:member',
    );

    const { data: trainingPrograms, loading: trainingProgramsLoading } = useFetch<IEntityBase[]>(
        endpoints.masterData.trainingPrograms.optionsForFilterStudent,
        '',
    );

    const { data: questionnaireTemplates, loading: questionnaireTemplatesLoading } = useFetch<Option<string>[]>(
        endpoints.selfEvaluation.questionnaireProgram.listTemplatesForFilter,
        '',
    );

    const { data: commissionEvaluationTemplates, loading: commissionEvaluationTemplatesLoading } = useFetch<
        Option<string>[]
    >(endpoints.selfEvaluation.questionnaireProgram.listCommissionEvaluationTemplatesForFilter, '');

    const loading =
        semestersLoading ||
        trainingLevelsLoading ||
        trainingProgramsLoading ||
        questionnaireTemplatesLoading ||
        commissionEvaluationTemplatesLoading;

    const options: Record<string, Option[]> = useMemo(
        () => ({
            trainingLevels: trainingLevels.map((e) => ({ value: e.id as string, label: e.name })),
            trainingPrograms: trainingPrograms.filter((e) => e.archive === false) as unknown as Option[],
            semesterStatus: semesterStatus,
            questionnaireTemplates: questionnaireTemplates,
            questionnaireStatus: documentStatus,
            commissionEvaluationTemplates: commissionEvaluationTemplates,
            commissionEvaluationStatus: documentStatus,
        }),
        [trainingLevels, trainingPrograms, questionnaireTemplates, commissionEvaluationTemplates],
    );

    console.count('render');

    useEffect(() => {
        if (semestersLoading || semesterOptions.length < 1) return;
        const activeSemester = semesterOptions.find((item) => item.active);
        if (activeSemester) setSemester(activeSemester);
    }, [semestersLoading, semesterOptions]);

    const filter = useCallback(
        (form: Record<string, unknown>) => {
            if (!semester) {
                return Promise.resolve([] as unknown as AxiosResponse);
            }

            return API.get(
                endpoints.selfEvaluation.questionnaireProgram.list.replace(
                    '{semesterId}',
                    semester.value as unknown as string,
                ),
                { params: form },
            );
        },
        [semester],
    );

    const handleSemesterChange = useCallback(
        (_: unknown, value: Option<number> | null) => {
            setSemester(value);
            setRefresh((prev) => !prev);
        },
        [setSemester],
    );

    if (loading) {
        return <MCCLoading />;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Header {...{ ...(headerWithTenant as HeaderProps), breadcrumbs }} />
            </Grid>
            <Grid item container spacing={1} xs={12}>
                <Grid item xs={6} md={11}>
                    <Autocomplete
                        options={semesterOptions}
                        value={semester}
                        onChange={handleSemesterChange}
                        size="small"
                        renderInput={Input}
                    />
                </Grid>
                <Grid item xs={6} md={1} alignSelf={'center'} justifyContent={'flex-end'}>
                    {!user.archive && <RefreshButton setRefresh={setRefresh} semester={semester} />}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {semester ? (
                    <FormikListPage
                        {...tableProps(options, refresh, setRefresh, user.archive, tenant)}
                        service={{ filter }}
                    />
                ) : (
                    <ThemeProvider theme={defaultTheme}>
                        <Alert severity="info">Kérjük, válassz szemesztert az adatok megtekintéséhez</Alert>
                    </ThemeProvider>
                )}
            </Grid>
        </Grid>
    );
}
