import DownloadIcon from '@mui/icons-material/Download';
import { FormPreview } from '@silinfo/form-builder-2';
import { useFetch } from '@silinfo/front-end-template';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../../images/MCC_logo_for_form.png';
import { RootState } from '../../store';
import { filenameMap } from '../../studentPages/SelfAssessment/CellRenderers/Operations/Download/DownloadTemplate';
import FormToPrint from '../../studentPages/SelfAssessment/CellRenderers/Operations/Download/FormToPrint';
import { FormToPrintProps } from '../../studentPages/SelfAssessment/CellRenderers/Operations/Download/types';
import { formFilledSuccessfullyMessage, questionnaireFileProps } from '../../utils/AppConst';
import { theme } from '../../utils/theme';
import MCCLoading from '../MCCLoading/index';
import PDFDownloader from '../PDFDownloader';
import { IFillerStudent, IForm2, IResponse, PreviewTemplateProps } from './types';
import { createFileName, dummyAsyncFunction, getForms, useFormPreviewStyles } from './utils';
import MultiFormPreview from './MultiFormPreview';
import axios from 'axios';
import api from '../../api';
import { clientEndPoints } from '../../utils/clientEndPoints';

export default function PreviewTemplate(props: PreviewTemplateProps) {
    const { url, responseKey, keyPrefix, successMessage, onSubmit, formName, type } = props;
    const { user } = useSelector((state: RootState) => state.auth);
    const [formToSet, setFormArr] = useState<Record<string, unknown>[]>([]);
    const [form, setForm] = useState({});
    const navigate = useNavigate();
    const [forms, setForms] = useState<IForm2[]>([]);
    const { id } = useParams() as { id: string };
    const regex = /\/questionnaire\/[a-zA-Z0-9]{10,}/;
    const useAxios = regex.test(url);
    const instance = useAxios ? axios : api;
    const { data, loading } = useFetch<IResponse>(url, responseKey || '', undefined, instance);
    const [isFirstLoading, setIsFirstLoading] = useState(true);
    const classes = useFormPreviewStyles();
    const dispatch = useDispatch();
    const [validated, setValidated] = useState<boolean>(false);

    // Mentés&Visszatöltés kliens oldalon
    const lskey = [keyPrefix, user.id, id].join('_');
    useEffect(() => {
        // Több form esetén a storage visszatöltés feltétele
        const groupLoadCondition =
            !loading &&
            data &&
            data.fill &&
            ((Array.isArray(data.fill) && data.fill.length === 0) ||
                (data.fill[1] && Array.isArray(data.fill[1]) && data.fill[1].length === 0 && data.dateFilled));

        // Első betöltésnél betöltjük a storageben lévő adatokat
        if (isFirstLoading && !loading && forms.length > 0) {
            try {
                const saved = localStorage.getItem(lskey);
                if (saved) {
                    const parsedSaved = JSON.parse(saved);
                    if (forms.length === 1 && !data.dateFilled) {
                        setForm(parsedSaved);
                    }
                    // Multiform
                    else if (Array.isArray(parsedSaved) && !data.groupDateFilled && groupLoadCondition) {
                        setFormArr(parsedSaved);
                    }
                }
                setIsFirstLoading(false);
            } catch (e) {
                console.error('Storage get error', e);
            }
        }

        // Formok betöltése, amint elérhető a data
        if (!loading && data) {
            try {
                const forms = getForms(data);
                if (forms) {
                    setForms(forms);
                }
            } catch (e) {
                console.error('Storage get error', e);
            }
        }

        if (!loading && data.fill && data.fill.length > 0) {
            // TODO: Vajon miért volt itt más a form data?
            //if (window.location.pathname.includes('/elonezet') || window.location.pathname.includes('/preview')) {
            //    setForm(data.fill[0]?.formData ? data.fill[0]?.formData : {});
            //} else {
            if (data.fill[0] && data.fill[0].formData) {
                setForm(data.fill[0].formData ?? {});
            } else if (data.fill[0]) {
                setForm(data.fill[0] ?? {});
            }
            if (typeof data.fill !== 'function') {
                setFormArr(data.fill);
            }
            //}
            setIsFirstLoading(false);

            // A második oldalt itt töltjük be, ha még nem volt mentés
            if (!isFirstLoading && groupLoadCondition) {
                try {
                    const saved = localStorage.getItem(lskey);
                    if (saved) {
                        const parsedSaved = JSON.parse(saved);
                        setFormArr(parsedSaved);
                    } else {
                        setFormArr(data.fill);
                    }
                } catch (e) {
                    console.error('Storage get error', e);
                    setFormArr(data.fill);
                }
            } else if (!isFirstLoading) {
                if (data && Array.isArray(data.fill)) {
                    setFormArr(data.fill);
                } else {
                    navigate(clientEndPoints.student_questionnaires_expired);
                }
            }
        }
    }, [lskey, loading, data, isFirstLoading, forms.length, navigate]);

    useEffect(() => {
        try {
            if (!(isFirstLoading || loading || data.dateFilled) && forms.length === 1) {
                localStorage.setItem(lskey, JSON.stringify(form));
            } else if (!(isFirstLoading || loading || data.groupDateFilled) && forms.length > 1) {
                localStorage.setItem(lskey, JSON.stringify(formToSet));
            }
        } catch (e) {
            console.error('Storage save error', e);
        }
    }, [data, form, formToSet, forms.length, isFirstLoading, loading, lskey]);

    const FormToPrintByType = useCallback(
        (formToPrintProps: Omit<FormToPrintProps, 'type'>) => (
            <FormToPrint
                type={type || 'selfEvaluation'}
                givenUser={loading ? undefined : data.student || (user as IFillerStudent)}
                {...formToPrintProps}
            />
        ),
        [loading, data, type, user],
    );

    if (loading || isFirstLoading || !forms) return <MCCLoading />;

    return (
        <>
            {data.dateFilled ||
            window.location.pathname.includes('/elonezet') ||
            window.location.pathname.includes('/preview') ? (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        zIndex: 5000,
                        background: theme.palette.secondary.main,
                        borderRadius: '50%',
                        padding: '5px',
                    }}
                    className="borderRadius"
                >
                    <PDFDownloader
                        data={{
                            row: {
                                fill: data.fillPdf ?? data.fill ?? [],
                                forms: data.forms,
                                dateFilled: true,
                            },
                            filename: createFileName(filenameMap[type || 'selfEvaluation'], data.student),
                        }}
                        ComponentToPrint={FormToPrintByType}
                        DownloadIcon={<DownloadIcon color="primary" />}
                        portrait
                    />
                </div>
            ) : null}
            {forms && forms.length === 1 && (
                <div className={data?.dateFilled ? classes.readableEnabled : classes.readableDisabled}>
                    <FormPreview
                        formToSet={form}
                        setForm={setForm}
                        form={forms[0]}
                        image={Logo}
                        name={data.name || formName || ''}
                        notificationSuccess={successMessage || formFilledSuccessfullyMessage}
                        onSubmit={
                            onSubmit
                                ? async (form: Record<string, unknown>): Promise<unknown> => {
                                      return await onSubmit(form, 0);
                                  }
                                : dummyAsyncFunction
                        }
                        readonly={data.dateFilled}
                        fileProps={questionnaireFileProps(dispatch, data.dateFilled)}
                        isFilled={data.dateFilled}
                        formType={type}
                    />
                </div>
            )}
            {forms && forms.length > 1 && (
                <div className={classes.readableDisabled}>
                    <MultiFormPreview
                        image={Logo}
                        dateFilled={!!data.dateFilled}
                        forms={forms}
                        onSubmit={onSubmit || dummyAsyncFunction}
                        setForm={setFormArr}
                        formToSet={formToSet}
                        rules={data.rules}
                        groupDateFilled={!!data.groupDateFilled}
                        isPageOneSaved={!!data.isPageOneSaved}
                        isPageTwoSaved={!!data.isPageTwoSaved}
                        validated={validated}
                        setValidated={setValidated}
                    />
                </div>
            )}
        </>
    );
}
