import { default as API } from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';
import { endpoints } from '../../utils/endPoints';

const questionnaireProgramService = {
    getSemesters: () => API.get(endpoints.selfEvaluation.questionnaireProgram.semesters),
    refreshProgramsForSemester: (semesterId: string) =>
        API.post(
            endpoints.selfEvaluation.questionnaireProgram.refreshProgramsForSemester.replace(
                '{semesterId}',
                semesterId,
            ),
        ),
    list: (form: Record<string, unknown>) => {
        return API.get(
            endpoints.selfEvaluation.questionnaireProgram.list.replace('{semesterId}', form.semesterId as string),
            {
                params: form,
            },
        );
    },
    listTemplates: () => API.get(endpoints.selfEvaluation.questionnaireProgram.listTemplates),
    listTemplatesByQuestionnaireProgram: (questionnaireProgramId: string) =>
        API.get(
            endpoints.selfEvaluation.questionnaireProgram.listTemplatesByQuestionnaireProgram.replace(
                '{questionnaireProgramId}',
                questionnaireProgramId,
            ),
        ),
    previewTemplateContent: (templateId: string) =>
        API.get(
            endpoints.selfEvaluation.questionnaireProgram.previewTemplateContent.replace('{templateId}', templateId),
        ),
    updateTemplate: (
        programId: string,
        templateId: number | null,
        templateGroupId: number | null,
        type: string | null,
    ) =>
        API.post(endpoints.selfEvaluation.questionnaireProgram.updateTemplate.replace('{programId}', programId), {
            templateId: templateId,
            templateGroupId: templateGroupId,
            type: type,
        }),
    updateTemplateSummary: (programId: string, templateId: number | null) =>
        API.post(
            endpoints.selfEvaluation.questionnaireProgram.updateTemplateSummary.replace('{programId}', programId),
            {
                templateId: templateId,
            },
        ),
    updateStatus: (programId: string, form: IForm) =>
        API.post(endpoints.selfEvaluation.questionnaireProgram.updateStatus.replace('{programId}', programId), form),
    updateStatusSummary: (programId: string, form: IForm) =>
        API.post(
            endpoints.selfEvaluation.questionnaireProgram.updateStatusSummary.replace('{programId}', programId),
            form,
        ),
    updateCommissionEvaluation: (programId: string, form: IForm) =>
        API.post(
            endpoints.selfEvaluation.questionnaireProgram.updateCommissionEvaluation.replace('{programId}', programId),
            form,
        ),
    uploadUniversityIndex: (semesterId: string, form: IForm) =>
        API.post(endpoints.students.selfEvaluation.uploadUniversityIndex(semesterId), form),
    removeUniversityIndex: (semesterId: string) =>
        API.delete(endpoints.students.selfEvaluation.removeUniversityIndex(semesterId)),
};

export default questionnaireProgramService;
