import IconButton from '@mui/material/IconButton';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { componentMap, dateStyle } from './utils';
import { TSelfAssessmentDocumentStatus } from '../types';
import { STATUS } from '../../../../studentPages/SelfAssessment/CellRenderers/Status/utils';
import PendingIcon from '@mui/icons-material/Pending';
import EditNoteIcon from '@mui/icons-material/Edit';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import EndpointProvider from '../../../../components/EndpointProvider';

const handleClick = (id: string | number, value: string) => {
    const url =
        value === 'wait_to_fill_student' || value === 'wait_to_fill_administrator' || value === 'wait_to_fill'
            ? clientEndPoints.self_assessment_report_fill.replace(':id', '' + id)
            : clientEndPoints.self_assessment_report_preview.replace(':id', '' + id);
    window.open(url, '_blank');
};
export default function SelfAssessmentQuestionnaireCellRenderer(
    params: GridRenderCellParams<TSelfAssessmentDocumentStatus>,
) {
    const Core = componentMap[params.value || 'none'];
    const onClick = useCallback(() => {
        handleClick(params.id, params.value ?? '');
    }, [params.id, params.value]);
    const endpoint =
        params.value === STATUS.COMPLETED ? 'self_assessment_report_preview' : 'self_assessment_report_fill';
    const [childHasAccess, setChildHasAccess] = useState<boolean>(false);

    const renderCell = (icon: JSX.Element, date: string | null, childHasAccess: boolean) => (
        <EndpointProvider endpoints={[endpoint]} onChildAccessChange={setChildHasAccess} forceShow={true}>
            <div style={{ textAlign: 'center' }}>
                <IconButton onClick={onClick} disabled={childHasAccess}>
                    {icon}
                </IconButton>
                <div style={dateStyle}>{date || ''}</div>
            </div>
        </EndpointProvider>
    );

    if (params.value === 'wait_to_fill_student') {
        return renderCell(
            <EditNoteIcon style={{ color: childHasAccess ? '#B0B0B0' : '#004E89' }} />,
            params.row.selfAssessmentQuestionnaireFillDate,
            childHasAccess,
        );
    }

    if (params.value === 'wait_to_fill_administrator' && params.row?.isGroupTemplate === true) {
        return renderCell(
            <AssignmentLateIcon style={{ color: childHasAccess ? '#B0B0B0' : '#FFD700' }} />,
            params.row.selfAssessmentQuestionnaireFillDate,
            childHasAccess,
        );
    }

    if (params.value === 'wait_to_fill' && params.row?.isGroupTemplate === true) {
        return renderCell(
            <PendingIcon style={{ color: childHasAccess ? '#B0B0B0' : '#22344C' }} />,
            params.row.selfAssessmentQuestionnaireFillDate,
            childHasAccess,
        );
    }

    if (params.value === STATUS.COMPLETED) {
        return renderCell(Core, params.row?.selfAssessmentQuestionnaireFillDate, childHasAccess);
    }

    return Core;
}
