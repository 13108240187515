import { GridRowModel } from '@mui/x-data-grid';
import { Tooltip } from '@silinfo/front-end-template';
import clsx from 'clsx';
import { QuestionnaireTemplateType, TYPE_QUESTIONNAIRE, TYPE_COMMISSION_EVALUATION } from '../utils';
import { DefaultCellRendererProps, useStylesTooltip } from './SemesterStatusCellRenderer';

export const NOT_SELECTED_TEXT = 'Nincs kijelölve';

function getLabel(type: QuestionnaireTemplateType, row: GridRowModel) {
    let label;
    if (type === TYPE_COMMISSION_EVALUATION) {
        label = row.evaluationSheetQuestionnaire?.name;
    } else if (type === TYPE_QUESTIONNAIRE) {
        label = row.questionnaireTemplate?.name || row.questionnaireTemplateGroup?.name;
    }
    return label || NOT_SELECTED_TEXT;
}

export default function QuestionnaireTemplateCellRenderer({
    params,
    type,
}: DefaultCellRendererProps & { type: QuestionnaireTemplateType }) {
    const classes = useStylesTooltip();
    const label = getLabel(type, params.row);

    return (
        <Tooltip title={label}>
            <div className={label === NOT_SELECTED_TEXT ? clsx(classes.truncate, classes.grey) : classes.truncate}>
                {label}
            </div>
        </Tooltip>
    );
}
