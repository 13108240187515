import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import instance from '../../../api';
import PreviewTemplate from '../../../components/PreviewTemplate';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { setRefreshLocalStorage } from '../../../components/PreviewTemplate/utils';
import { SelfEvaluationDocumentType } from '../CellRenderers/Operations/Download/DownloadTemplate';

interface SummaryProps {
    makeUrlFromId: (id: string) => string;
    type: SelfEvaluationDocumentType;
    keyPrefix: 'student_summary' | 'student_previous_summary' | 'student_commission_evaluation';
}

export default function Summary({ makeUrlFromId, type, keyPrefix }: SummaryProps) {
    const { id } = useParams();
    const url = useMemo(() => makeUrlFromId(id || ''), [id, makeUrlFromId]);

    const handleSubmit = useCallback(
        async (form: IForm) => {
            await instance.post(url, form);
            setRefreshLocalStorage();
        },
        [url],
    );

    return <PreviewTemplate type={type} url={url} keyPrefix={keyPrefix} onSubmit={handleSubmit} />;
}
