import { useFetch } from '@silinfo/front-end-template';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Logo from '../../../../images/MCC_logo_for_form.png';
import MCCLoading from '../../../../components/MCCLoading';
import { PreviewTemplateProps, IResponse, IFillerStudent } from '../../../../components/PreviewTemplate/types';
import {
    useFormPreviewStyles,
    getForms,
    dummyAsyncFunction,
    createFileName,
} from '../../../../components/PreviewTemplate/utils';
import { RootState } from '../../../../store';
import FormPreview from './FormPreview';
import { theme } from '../../../../utils/theme';
import PDFDownloader from '../../../../components/PDFDownloader';
import { FormToPrintProps } from '../../../../studentPages/SelfAssessment/CellRenderers/Operations/Download/types';
import FormToPrint from '../../../../studentPages/SelfAssessment/CellRenderers/Operations/Download/FormToPrint';
import DownloadIcon from '@mui/icons-material/Download';

export default function CommissionForm(props: PreviewTemplateProps) {
    const { url, responseKey, keyPrefix, onSubmit } = props;
    const { user } = useSelector((state: RootState) => state.auth);
    const [formToSet, setFormArr] = useState<Record<string, unknown>[]>([]);
    const [form, setForm] = useState({});
    const { id } = useParams() as { id: string };
    const { data, loading } = useFetch<IResponse>(url, responseKey || '');
    const [isFirstLoading, setIsFirstLoading] = useState(true);
    const classes = useFormPreviewStyles();
    const [validated, setValidated] = useState<boolean>(false);

    const FormToPrintByType = useCallback(
        (formToPrintProps: Omit<FormToPrintProps, 'type'>) => (
            <FormToPrint
                type={'commissionEvaluation'}
                givenUser={loading ? undefined : data.student || (user as IFillerStudent)}
                {...formToPrintProps}
            />
        ),
        [loading, data, user],
    );

    // Mentés&Visszatöltés kliens oldalon
    const lskey = [keyPrefix, user.id, id].join('_');
    useEffect(() => {
        if (isFirstLoading && !loading && !data.dateFilled) {
            try {
                const saved = localStorage.getItem(lskey);
                if (saved) {
                    setForm(JSON.parse(saved));
                }
                setIsFirstLoading(false);
            } catch (e) {
                console.error('Storage get error', e);
            }
        }
    }, [lskey, loading, data, isFirstLoading]);

    useEffect(() => {
        try {
            if (!(isFirstLoading || loading || data.dateFilled)) {
                localStorage.setItem(lskey, JSON.stringify(form));
            }
        } catch (e) {
            console.error('Storage save error', e);
        }
    }, [data, form, isFirstLoading, loading, lskey]);

    useEffect(() => {
        if (!loading && data.fill) {
            if (data.fill[0] && data.fill[0].formData) {
                setForm(data.fill[0].formData ?? {});
            } else {
                setForm(data.fill[0] ?? {});
            }
            setFormArr(data.fill);
            setIsFirstLoading(false);
        }
    }, [loading, data]);

    if (loading || isFirstLoading) return <MCCLoading />;

    const forms = getForms(data);

    return (
        <>
            {data.dateFilled ||
            window.location.pathname.includes('/elonezet') ||
            window.location.pathname.includes('/preview') ? (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        zIndex: 5000,
                        background: theme.palette.secondary.main,
                        borderRadius: '50%',
                        padding: '5px',
                    }}
                    className="borderRadius"
                >
                    <PDFDownloader
                        data={{
                            row: {
                                fill: data.fillPdf ?? data.fill ?? [],
                                forms: data.forms,
                                dateFilled: true,
                            },
                            filename: createFileName('Bizottsagi-ertekelo-lap', data.student),
                        }}
                        ComponentToPrint={FormToPrintByType}
                        DownloadIcon={<DownloadIcon color="primary" />}
                        portrait
                    />
                </div>
            ) : null}
            <div className={classes.readableDisabled}>
                <FormPreview
                    image={Logo}
                    dateFilled={!!data.dateFilled}
                    forms={forms}
                    onSubmit={onSubmit || dummyAsyncFunction}
                    setForm={setFormArr}
                    formToSet={formToSet}
                    rules={data.rules}
                    validated={validated}
                    isPageSaved={!!data.isPageSaved}
                    setValidated={setValidated}
                />
            </div>
        </>
    );
}
