import { Input } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { endpoints } from '../../../../utils/endPoints';
import ROLES from '../../../../utils/Roles';
import { Options } from './Form';
import { useParams } from 'react-router-dom';
import { IUsersForm } from '../../../../services/masterData/users';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

export const useConditionalUsersFields = (
    options: Options,
    readonly: boolean,
    existsInCentral: boolean,
    activated: boolean,
): Input[] => {
    const { values, setFieldValue } = useFormikContext<IUsersForm>();
    const { roles } = values;
    const { id } = useParams();
    const { user } = useSelector((state: RootState) => state.auth);

    return [
        {
            name: 'roleTrainingLeaderProgramIds',
            label: 'Képzési program vezető - Képzési program',
            type: 'multiselect',
            options: options.trainingPrograms,
            render: roles.includes(ROLES.TRAINING_PROGRAM_LEADER),
            props: {
                disabled: readonly,
            },
        },
        {
            name: 'roleTrainingProgramProgramIds',
            label: 'Képzési program admin - Képzési program',
            type: 'multiselect',
            options: options.trainingPrograms,
            render: roles.includes(ROLES.TRAINING_PROGRAM_ADMIN),
        },
        {
            name: 'roleEpCoordinatorCampusId',
            label: 'EP koordinátor - Campus',
            type: 'select',
            options: options.campuses,
            render: roles.includes(ROLES.EP_COORDINATOR),
            props: {
                disabled: readonly,
            },
        },
        {
            name: 'roleFinanceAdminCampusIds',
            label: 'Pénzügyi admin - Campus',
            type: 'multiselect',
            options: options.campuses,
            render: roles.includes(ROLES.FINANCIAL_ADMIN),
            props: {
                disabled: readonly,
            },
            fieldType: 'base',
        },
        {
            name: 'roleOperatorCampusId',
            label: 'Üzemeltető - Campus',
            type: 'select',
            options: options.campuses,
            render: roles.includes(ROLES.OPERATOR),
            props: {
                disabled: readonly,
            },
        },
        {
            name: 'roleBoardCampusIds',
            label: 'BOARD - Campus',
            type: 'multiselect',
            options: options.campuses,
            render: roles.includes(ROLES.BOARD),
            props: {
                disabled: readonly,
            },
        },
        {
            name: 'disabled',
            label: 'Letiltás',
            type: 'checkbox',
            props: {
                disabled: user.id === +(id || 0) || readonly,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('disabled', e.target.checked);
                },
                checked: values.disabled,
            },
            render: true,
        },
        {
            name: 'profilePicture',
            type: 'file',
            render: roles.includes(ROLES.TEACHER),
            label: 'Profilkép',
            origFileName: 'profilePicture.origFileName',
            uuidFileName: 'profilePicture.uuidFileName',
            downloadUrl: `${endpoints.fileManagement.download}?id=${id}&type=profile-image`,
            tempType: 'profile-image',
            format: {
                xs: 12,
            },
            props: {
                disabled: readonly,
            },
        },
        {
            name: 'sendEmail',
            label: 'Aktivációs e-mail küldése',
            type: 'checkbox',
            render: !id,
            fieldType: 'base',
            props: {
                checked: values.sendEmail,
                disabled: readonly || activated,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('sendEmail', e.target.checked);
                },
            },
        },
    ]
        .filter((e) => e.render)
        .map(({ render: _render, ...e }) => ({ format: { xs: 12, md: 6 }, ...e })) as Input[];
};
