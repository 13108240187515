import { TableEditButton } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { Link } from 'react-router-dom';

export function BtnCellRenderer(params: GridRenderCellParams) {
    const isSwitch = ['MUST_ACCEPT_PRIVACY_STATEMENT', 'CERTIFICATES_CURRENT_STATUS_DISPLAY'].some(
        (setting) => setting === params.row.settingKey,
    );
    const { user } = useSelector((state: RootState) => state.auth);

    return isSwitch || user.archive ? (
        '-'
    ) : (
        <>
            <Link to={clientEndPoints.admin_settings_form.replace(':settingId', params.row.id.toString())}>
                <TableEditButton tooltip={'Szerkesztés'} sx={{ marginRight: '5px' }} />
            </Link>
        </>
    );
}
