import { useDispatch } from 'react-redux';
import { useState } from 'react';
import InformationPanel from '@silinfo/form-builder-2/lib/esm/components/FormPreview/InformationPanel';
import { ReadonlyContextProvider } from '@silinfo/form-builder-2/lib/esm/components/FormPreview/ReadonlyContext';
import { FormContextProvider } from '@silinfo/form-builder-2/lib/esm/components/FormPreview/FormContext';
import { FileContextProvider } from '@silinfo/form-builder-2/lib/esm/components/FormPreview/FileContext';
import Panel from '@silinfo/form-builder-2/lib/esm/components/FormPreview/Panel';
import { Actions } from '@silinfo/form-builder-2/lib/esm/components/FormPreview/Actions';
import CircularProgress from '@mui/material/CircularProgress';
import { FileProps } from '@silinfo/form-builder-2/lib/esm/components/FormPreview/types';
import { withStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { ICommissionEvaluationFormParams } from '../../../../components/PreviewTemplate/types';
import { useFormPreviewStyles } from '../../../../components/PreviewTemplate/utils';
import { questionnaireFileProps } from '../../../../utils/AppConst';
import { create } from '../../../../store/notification';
import Button from '@mui/material/Button';

const StyledSaveButton = withStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginRight: '10px',
    },
}))(Button);

export function RenderForm({
    dateFilled,
    isPageSaved,
    setDateFilled,
    formToSet,
    setForm,
    form,
    formIndex,
    onSubmit,
    setValidated,
}: ICommissionEvaluationFormParams) {
    const dispatch = useDispatch();
    const classes = useFormPreviewStyles();
    const pages = Object.values(form.pages || {});
    const count = pages.length + 1;
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const page = pages[index];
    const panels = Object.values(page?.panels || {});
    const [serverError, setServerError] = useState('');
    const [isSaved, setIsSaved] = useState(false);

    const customOnSave = async () => {
        setLoading(true);
        try {
            await onSubmit(formToSet, formIndex, false);
            setIsSaved(true);
            dispatch(create({ type: 'success', message: 'Sikeres mentés!' }));
        } catch (e) {
            dispatch(create({ type: 'error', message: 'Sikertelen mentés!' }));
        } finally {
            setLoading(false);
        }
    };

    const customOnFinalize = async (): Promise<void> => {
        await onSubmit(formToSet, formIndex, true);
        if (formIndex === 0) {
            setDateFilled(true);
            setValidated(true);
        }
        dispatch(create({ type: 'success', message: 'Sikeres véglegesítés!' }));
    };

    const readonly = dateFilled;

    return (
        <div
            className={readonly ? classes.readableEnabled : classes.readableDisabled}
            style={{ paddingLeft: '30px', paddingRight: '30px', paddingBottom: '30px' }}
        >
            {serverError ? (
                <InformationPanel message={serverError} />
            ) : (
                <ReadonlyContextProvider readonly={!!readonly}>
                    <FileContextProvider
                        file={questionnaireFileProps(dispatch, !isSaved ? isPageSaved : isSaved) as FileProps<unknown>}
                    >
                        <FormContextProvider formState={[formToSet, setForm, errors, setErrors]}>
                            {index === count - 1 ? (
                                <InformationPanel message={'Sikeres véglegesítés!'} />
                            ) : (
                                panels.map((panel) => <Panel key={panel.ID} panel={panel} />)
                            )}
                        </FormContextProvider>
                    </FileContextProvider>
                </ReadonlyContextProvider>
            )}

            <Actions
                index={index}
                count={count}
                pages={pages}
                onSubmit={customOnFinalize}
                formToSet={formToSet}
                setIndex={setIndex}
                setErrors={setErrors}
                readonly={!!readonly}
                setServerError={setServerError}
                error={false}
                OtherButton={
                    !readonly &&
                    index !== count - 1 && (
                        <StyledSaveButton
                            variant="contained"
                            onClick={customOnSave}
                            color="warning"
                            startIcon={loading ? <CircularProgress color="info" size={20} /> : null}
                            disabled={loading}
                        >
                            Mentés
                        </StyledSaveButton>
                    )
                }
                confirmationDialog
                confirmationDialogTitle="Megerősítés véglegesítésről"
                confirmationDialogContent=" Biztosan véglegesíteni szeretné a kérdőívet? A művelet csak adminisztrátori jogosultsággal
                        vonható vissza."
            />
        </div>
    );
}
