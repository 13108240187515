import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditNoteIcon from '@mui/icons-material/Edit';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { TSelfAssessmentDocumentStatus, TSelfAssessmentPreviousDocumentStatus } from '../types';

const dateStyle = {
    fontSize: '12px',
    color: '#888',
    height: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const componentMap: Record<TSelfAssessmentDocumentStatus | TSelfAssessmentPreviousDocumentStatus, JSX.Element> = {
    filled: <CheckCircleIcon color="success" />,
    available: <CheckCircleIcon color="success" />,
    none: (
        <div style={{ textAlign: 'center' }}>
            <BlockIcon color="disabled" />
            <div
                // Saját style kell neki, mivel button / icon elcsúszás van
                style={{
                    fontSize: '12px',
                    color: '#888',
                    height: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            ></div>
        </div>
    ),
    wait_to_fill: (
        <div style={{ textAlign: 'center' }}>
            <HighlightOffIcon color="error" />
            <div
                // Saját style kell neki, mivel button / icon elcsúszás van
                style={{
                    fontSize: '12px',
                    color: '#888',
                    height: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            ></div>
        </div>
    ),
    wait_to_fill_student: <EditNoteIcon style={{ color: '#004E89' }} />,
    wait_to_fill_administrator: <AssignmentLateIcon style={{ color: '#FFD700' }} />,
};

const confirmationTextsByKey: Record<string, string> = {
    selfAssessmentConversation: 'Az önértékelési beszélgetés megtörtént?',
};

const conversationMapper = new Map<boolean | null, TSelfAssessmentDocumentStatus>([
    [true, 'filled'],
    [false, 'wait_to_fill'],
    [false, 'wait_to_fill_student'],
    [false, 'wait_to_fill_administrator'],
    [null, 'none'],
]);

const statusMapper = new Map<boolean | null, TSelfAssessmentDocumentStatus>([
    [true, 'filled'],
    [false, 'wait_to_fill'],
    [false, 'wait_to_fill_student'],
    [false, 'wait_to_fill_administrator'],
    [null, 'none'],
]);

const universityIndexMapper = new Map<string | null, TSelfAssessmentDocumentStatus>([
    ['uploaded', 'filled'],
    ['wait_to_upload', 'wait_to_fill'],
    [null, 'none'],
]);

export { componentMap, confirmationTextsByKey, statusMapper, conversationMapper, universityIndexMapper, dateStyle };
