import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { CancelButton, Checkbox, SubmitButton, TextField } from '@silinfo/front-end-template';
import { endpoints } from '../../../utils/endPoints';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MccLogo from './Icons/MccLogo.svg';
import UserLogo from './Icons/UserLogo.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../../utils/theme';
import PrivacyPolicyModal from './Dialogs/PrivacyPolicyModal';
import Tooltip from '@mui/material/Tooltip';
import AdmissionsStyle from './Admissions.module.scss';
import axios from 'axios';
import { getJWTToken } from '../../../utils/AuthHelper';
import { useTenant } from '../../../components/TenantContext';

const SelectElement = ({ onClick, Logo, label }: { onClick: () => void; Logo: string; label: string }) => {
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Grid
            item
            container
            className={`borderRadius ${AdmissionsStyle.selectElement}`}
            sx={{
                justifyContent: matches ? 'center' : 'space-between',
                ...(!matches && { paddingRight: '2rem' }),
            }}
            onClick={onClick}
        >
            <Grid item xs={1} className={`${AdmissionsStyle.flex} ${AdmissionsStyle.contentCenter}`}>
                <img src={Logo} />
            </Grid>
            <Grid item xs={matches ? 10 : 9}>
                <Typography
                    className={AdmissionsStyle.selectElementTypography}
                    sx={{
                        fontSize: !matches ? '14px' : '18px',
                    }}
                >
                    {label}
                </Typography>
            </Grid>
        </Grid>
    );
};

const Selector = ({ status, semester, setNewUser }: { status: string; semester: string; setNewUser: () => void }) => {
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [outsideStudent, setOutsideStudent] = useState<string>('');
    const JWT = getJWTToken();
    const tenant = useTenant();

    useEffect(() => {
        const checkExternalStudent = async () => {
            if (JWT !== undefined) {
                const response = await axios.post(endpoints.admission.checkExternalStudent, {
                    token: JWT,
                });
                setOutsideStudent(response.data);
            }
        };

        checkExternalStudent();
    }, [JWT]);

    const handleClick = () => {
        const { admission_mcc, admission_mccWithToken } = clientEndPoints;
        const tokenId = outsideStudent;

        if (tokenId && tokenId.trim() !== '') {
            const urlWithToken = admission_mccWithToken.replace(':tokenId', tokenId);
            window.location.replace(urlWithToken);
        } else {
            window.location.replace(`${admission_mcc}?tab=2`);
        }
    };

    return (
        <>
            {status === 'open' && (
                <Grid container className={AdmissionsStyle.contentCenter}>
                    <Grid className={`${AdmissionsStyle.contentCenter} ${AdmissionsStyle.flex}`}>
                        <Typography
                            className={`${AdmissionsStyle.selectorTypography} ${AdmissionsStyle.bottomMargin}`}
                            sx={{
                                fontSize: matches ? '24px' : '32px',
                            }}
                        >
                            Köszöntünk az MCC felvételi jelentkezési felületén!
                        </Typography>
                        <Typography sx={{ textAlign: 'center', fontSize: matches ? '16px' : '24px', color: '#181E25' }}>
                            Jelenleg az alábbi felvételi időszakra tudsz jelentkezni:
                        </Typography>
                        <Typography
                            className={`${AdmissionsStyle.bold} ${AdmissionsStyle.selectorTypography}`}
                            sx={{
                                fontSize: matches ? '16px' : '24px',
                            }}
                        >
                            {semester}
                        </Typography>
                        <Typography
                            className={`${AdmissionsStyle.selectorTypography}`}
                            sx={{
                                fontSize: matches ? '14px' : '20px',
                                marginTop: matches ? '24px' : '2rem',
                            }}
                        >
                            A jelentkezéshez válassz az alábbi opciók közül:
                        </Typography>
                        <Grid
                            container
                            className={`${AdmissionsStyle.contentCenter} ${AdmissionsStyle.flex}`}
                            sx={{
                                gap: matches ? '8px' : '24px',
                                marginTop: matches ? '0.5rem' : '2rem',
                            }}
                        >
                            <SelectElement
                                label="MCC Egyetemi Program hallgatója vagyok"
                                Logo={MccLogo}
                                onClick={handleClick}
                            />
                            <SelectElement
                                label={
                                    tenant === process.env.REACT_APP_DISPLAY_ALUMNI_ENROLLMENT_ON_TENANT
                                        ? 'Új jelentkező vagy Alumni vagyok'
                                        : 'Új jelentkező vagyok'
                                }
                                Logo={UserLogo}
                                onClick={setNewUser}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {status === 'closed' && (
                <div
                    className={`${AdmissionsStyle.flex} ${AdmissionsStyle.contentCenter}`}
                    style={{
                        height: '70vh',
                    }}
                >
                    <Typography
                        className={`${AdmissionsStyle.bottomMargin}`}
                        sx={{
                            textAlign: 'center',
                            fontSize: '24px',
                        }}
                    >
                        Jelenleg nincs felvételi időszak! Részletes információk az{' '}
                        <Link rel="noreferrer" href="https://mcc.hu/kepzes-egyetemi-program">
                            mcc.hu/kepzes-egyetemi-program
                        </Link>{' '}
                        oldalon találhatók!
                    </Typography>
                </div>
            )}
        </>
    );
};

const EmailModal = ({ open, close }: { open: boolean; close: () => void }) => {
    const [email, setEmail] = useState<string | null>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [success, setSucces] = useState<boolean>(false);
    const [disabledCheckBox, setDisabledCheckBox] = useState(true);
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState<boolean>(false);
    const regex = /^\S+@\S+\.\S+$/;
    const isError = (): string => {
        if (email === null) return 'Kötelező kitölteni!';
        if (email && !regex.test(email)) return 'Hibás e-mail formátum!';
        return '';
    };

    const handleOpenPrivacyPolicy = () => {
        setOpenPrivacyPolicy(true);
        setDisabledCheckBox(false);
    };
    const handleClosePrivacyPolicy = () => setOpenPrivacyPolicy(false);

    const handleSendToken = async () => {
        await axios
            .post(endpoints.admission.generateToken, {
                email: email,
            })
            .then((response) => {
                switch (response.data.status) {
                    case 'notActivePeriod':
                        setErrorMessage('Nincs aktív felvételi időszak!');
                        break;
                    case 'existUser':
                        setSucces(true);
                        break;
                    case 'tokenGenerated':
                        setSucces(true);
                        break;
                    default:
                        break;
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    setErrorMessage('Nem valós e-mail cím!');
                }
                if (error.response && error.response.status === 400) {
                    setErrorMessage('Nincs aktív felvételi időszak!');
                }
            });
    };
    return (
        <Dialog open={open} fullWidth>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DialogTitle>Új jelentkezés</DialogTitle>
                </Grid>
                <Grid item xs={12}>
                    <DialogContent sx={{ paddingBottom: 0 }}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                {success
                                    ? 'Sikeres mentés! Amennyiben helyes e-mail címet adtál meg, kérjük, ellenőrizd a postafiókodat, ahová további információkat küldtünk!'
                                    : 'Kérjük, add meg az e-mail címedet, amire a belépési linket küldhetjük!'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                {!success && (
                                    <Grid item xs={12}>
                                        <TextField
                                            label="E-mail cím"
                                            name="email"
                                            margin="normal"
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value) {
                                                    setEmail(e.target.value);
                                                } else {
                                                    setEmail(null);
                                                }
                                                setErrorMessage('');
                                            }}
                                            error={!!isError()}
                                            helperText={isError()}
                                            size="medium"
                                            value={email}
                                            fullWidth
                                        />
                                        <Typography className={AdmissionsStyle.newAdmissionError}>
                                            {errorMessage}
                                        </Typography>
                                        <Tooltip
                                            title={
                                                disabledCheckBox
                                                    ? 'Az adatkezelési nyilatkozat megtekintéséhez és elfogadásához kérlek kattints az “Adatkezelési nyilatkozatot” szövegre!'
                                                    : ''
                                            }
                                            arrow
                                        >
                                            <div>
                                                <Checkbox
                                                    label={
                                                        <span>
                                                            Elfogadom az{' '}
                                                            <a href="#" onClick={handleOpenPrivacyPolicy}>
                                                                Adatkezelési nyilatkozatot
                                                            </a>
                                                            .
                                                        </span>
                                                    }
                                                    checkbox={{
                                                        disabled: disabledCheckBox,
                                                        onChange: () => setDisabledSubmit((prev) => !prev),
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                        <PrivacyPolicyModal open={openPrivacyPolicy} close={handleClosePrivacyPolicy} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Grid>
                <Grid item xs={12}>
                    <DialogActions>
                        {!success && (
                            <Tooltip
                                title={
                                    disabledSubmit
                                        ? 'Az adatkezelési nyilatkozat megtekintéséhez és elfogadásához kérlek kattints az “Adatkezelési nyilatkozatot” szövegre!'
                                        : ''
                                }
                                arrow
                            >
                                <div style={{ marginRight: '10px' }}>
                                    <SubmitButton disabled={!!isError() || disabledSubmit} onClick={handleSendToken}>
                                        Mehet
                                    </SubmitButton>
                                </div>
                            </Tooltip>
                        )}
                        <CancelButton onClick={close}>Vissza</CancelButton>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default function AdmissionLandingComponent() {
    const [status, setStatus] = useState<string>('');
    const [semester, setSemester] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            await axios.get(endpoints.admission.period).then((response) => {
                setStatus(response.data.status);
                if (response.data.semester) {
                    setSemester(response.data.semester);
                }
            });
        })();
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Grid container item xs={12}>
            <Selector status={status} semester={semester} setNewUser={handleOpen} />
            <EmailModal open={open} close={handleClose} />
        </Grid>
    );
}
