import { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PreviewTemplate from '../../../components/PreviewTemplate';
import questionnaireTemplateService from '../../../services/selfEvalution/questionnaireTemplate';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { endpoints } from '../../../utils/endPoints';
import { setRefreshLocalStorage } from '../../../components/PreviewTemplate/utils';
import { IResponse } from '../../../components/PreviewTemplate/types';
import { FormToPrintProps } from '../CellRenderers/Operations/Download/types';
import FormToPrint from '../CellRenderers/Operations/Download/FormToPrint';
import { generatePdf, HiddenFormRenderer } from '../utils';

export default function SelfAssessmentQuestionnaire() {
    const { id } = useParams<{ id: string }>();
    const refToPrint = useRef<HTMLDivElement>(null);
    const [data, setData] = useState<IResponse | null>(null);
    const FormToPrintByType = useCallback(
        (formToPrintProps: Omit<FormToPrintProps, 'type'>) => (
            <FormToPrint type={'selfEvaluation'} {...formToPrintProps} />
        ),
        [],
    );

    const handleGenerateAndUploadPdf = useCallback(
        async (values: IForm, formIndex: number, finalization?: boolean) => {
            try {
                const newData = await questionnaireTemplateService.pdfInfo(id ?? '0', values, formIndex);
                setRefreshLocalStorage();
                setData(newData?.data);

                await generatePdf(
                    refToPrint,
                    id ?? '0',
                    'portrait',
                    'studentSelfEvaluation',
                    values,
                    formIndex,
                    finalization ?? false,
                    (id, values, formIndex, finalization) =>
                        questionnaireTemplateService.fillForm2(id, values, formIndex, !!finalization),
                );
            } catch (error) {
                console.error('Error generating or uploading PDF:', error);
            }
        },
        [id],
    );

    if (!id) return null;
    return (
        <>
            <PreviewTemplate
                url={endpoints.students.selfEvaluation.form(id)}
                keyPrefix="student_preview"
                onSubmit={handleGenerateAndUploadPdf}
                formName="Önértékelés és célkitűzés"
                type="selfEvaluation"
            />
            <HiddenFormRenderer refToPrint={refToPrint} data={data} FormToPrintByType={FormToPrintByType} />
        </>
    );
}
