import { default as API } from '../../api';
import { endpoints } from '../../utils/endPoints';

const careerGuidanceService = {
    initiateExport: (form: { id: number; format: string }) =>
        API.post(endpoints.selfEvaluation.report.careerGuidance.export.initiate, form),
    checkExport: (id: number) =>
        API.get(endpoints.selfEvaluation.report.careerGuidance.export.check(id as unknown as string)),
    downloadExport: (id: number) =>
        API.get(endpoints.selfEvaluation.report.careerGuidance.export.download(id as unknown as string), {
            responseType: 'arraybuffer',
        }),
    initiateStudentExport: (form: { id: number; format: string }) =>
        API.post(endpoints.selfEvaluation.report.careerGuidance.studentExport.initiate, form),
    checkStudentExport: (id: number) =>
        API.get(endpoints.selfEvaluation.report.careerGuidance.studentExport.check(id as unknown as string)),
    downloadStudentExport: (id: number) =>
        API.get(endpoints.selfEvaluation.report.careerGuidance.studentExport.download(id as unknown as string), {
            responseType: 'arraybuffer',
        }),
    initiateDirect: (form: { id: number; format: string }) =>
        API.post(endpoints.selfEvaluation.report.careerGuidance.export.direct, form),
};

export default careerGuidanceService;
