import { Input } from '@silinfo/front-end-template';
import useNameFilterAutoComplete from './NameFilterAutoComplete';
import useGroupNameFilterAutoComplete from './GroupNameFilterAutoComplete';

export const QUESTIONNAIRE_TEMPLATE_TYPE_OPTIONS = [
    { value: 'course_evaluation_questionnaire', label: 'Kurzusértékelési kérdőív' },
    { value: 'self_evaluation_questionnaire_active', label: 'Önértékelés és célkitűzés - Aktív' },
    {
        value: 'self_evaluation_questionnaire_foreign_scholarship',
        label: 'Önértékelés és célkitűzés - Külföldi ösztöndíjas',
    },
    { value: 'self_evaluation_questionnaire_summary', label: 'Összefoglaló dokumentum' },
    { value: 'application_form', label: 'Felvételi kérdőív' },
    { value: 'commission_evaluation_sheet', label: 'Bizottsági értékelő lap' },
];

export const QUESTIONNAIRE_TEMPLATE_STATUS_OPTIONS = [
    { value: 'used', label: 'Használatban' },
    { value: 'unused', label: 'Nincs használatban' },
];

export const QUESTIONNAIRE_TEMPLATE_ARCHIVED_OPTIONS = [
    { value: 'true', label: 'Látható' },
    { value: 'false', label: 'Nem látható' },
];

export const QUESTIONNAIRE_GROUP_TYPE_OPTIONS = [{ value: 'goal_setting_questionnaire', label: 'Célkitűző kérdőív' }];

export const QUESTIONNAIRE_GROUP_STATUS_OPTIONS = [
    { value: 'used', label: 'Használatban' },
    { value: 'unused', label: 'Nincs használatban' },
];

export const QUESTIONNAIRE_GROUP_READABLE_OPTIONS = [
    { value: 'if_first_is_opened', label: 'Ha az első kérdőív megnyitásra került' },
    { value: 'if_first_is_finalized', label: 'Ha az első kérdőív véglegesítésre került' },
];

export const QUESTIONNAIRE_GROUP_WRITEABLE_OPTIONS = [
    { value: 'if_first_is_opened', label: 'Ha az első kérdőív megnyitásra került' },
    { value: 'if_first_is_finalized', label: 'Ha az első kérdőív véglegesítésre került' },
    { value: 'not_writeable', label: 'Nem írható' },
];

export const FINALIZED_CASE_GROUP_WRITEABLE_OPTIONS = [
    { value: 'if_first_is_opened', label: 'Ha az első kérdőív megnyitásra került' },
    { value: 'not_writeable', label: 'Nem írható' },
];

export const QUESTIONNAIRE_GROUP_STUDENT_FINALIZE_OPTIONS = [
    { value: 'student_finalize_yes', label: 'Igen' },
    { value: 'student_finalize_no', label: 'Nem' },
];

export const QUESTIONNAIRE_GROUP_ARCHIVED_OPTIONS = [
    { value: 'true', label: 'Látható' },
    { value: 'false', label: 'Nem látható' },
];

export const useTemplateFields = (): {
    fields: Input[];
} => {
    const nameField = useNameFilterAutoComplete();
    return {
        fields: [
            nameField,
            {
                name: 'type',
                label: 'Kérdőív típusa',
                format: { xs: 12, md: 6, lg: 3 },
                type: 'multiselect',
                options: QUESTIONNAIRE_TEMPLATE_TYPE_OPTIONS,
            },
            {
                name: 'status',
                label: 'Státusz',
                format: { xs: 12, md: 6, lg: 3 },
                type: 'multiselect',
                options: QUESTIONNAIRE_TEMPLATE_STATUS_OPTIONS,
            },
            {
                name: 'archived',
                label: 'Archivált sablonok',
                format: { xs: 12, md: 6, lg: 3 },
                type: 'select',
                options: QUESTIONNAIRE_TEMPLATE_ARCHIVED_OPTIONS,
            },
        ],
    };
};

export const useGroupFields = (): {
    groupFields: Input[];
} => {
    const nameField = useGroupNameFilterAutoComplete();
    return {
        groupFields: [
            nameField,
            {
                name: 'type',
                label: 'Csoport típusa',
                format: { xs: 12, md: 6, lg: 3 },
                type: 'multiselect',
                options: QUESTIONNAIRE_GROUP_TYPE_OPTIONS,
            },
            {
                name: 'status',
                label: 'Státusz',
                format: { xs: 12, md: 6, lg: 3 },
                type: 'multiselect',
                options: QUESTIONNAIRE_GROUP_STATUS_OPTIONS,
            },
            {
                name: 'archived',
                label: 'Archivált sablonok',
                format: { xs: 12, md: 6, lg: 3 },
                type: 'select',
                options: QUESTIONNAIRE_GROUP_ARCHIVED_OPTIONS,
            },
        ],
    };
};
