import { GridRenderCellParams } from '@mui/x-data-grid';
import { TSelfAssessmentDocumentStatus } from '../types';
import { AxiosError, AxiosResponse } from 'axios';
import { create, remove } from '../../../../store/notification';
import { downloadFile } from '../../../../utils/AppConst';
import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import careerGuidanceService from '../../../../services/selfEvalution/careerGuidance';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import DescriptionIcon from '@mui/icons-material/Description';

interface ExportContext {
    initiateExport: (form: { id: number; format: string }) => Promise<AxiosResponse>;
    checkExport: (id: number) => Promise<AxiosResponse>;
    downloadExport: (id: number) => Promise<AxiosResponse>;
}

function createExportHandleClick(
    reportId: number,
    exportContext: ExportContext,
    dispatch: Dispatch<unknown>,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
) {
    return () => {
        dispatch(remove());
        dispatch(create({ type: 'info', message: 'A fájl előállítása folyamatban...' }));
        setIsLoading(true);
        exportContext
            .initiateExport({ id: reportId, format: 'docx' })
            .then((data: AxiosResponse) => {
                const id = data.data.id;
                let ready = false;
                let isError = false;
                let fileName = '';
                let msg = '';

                const interval = setInterval(() => {
                    exportContext
                        .checkExport(id)
                        .then((d: AxiosResponse) => {
                            const status = d.data.status;
                            fileName = d.data.fileName;
                            msg = d.data.message;
                            ready = status === 'finished' || status === 'unknown_error';
                            isError = status !== 'finished';
                        })
                        .catch((error: AxiosError) => console.log(error, 'hiba'));
                    if (ready) {
                        clearInterval(interval);
                        if (!isError) {
                            exportContext
                                .downloadExport(id)
                                .then((response: AxiosResponse) => {
                                    downloadFile(response.data, fileName);

                                    dispatch(remove());
                                    dispatch(create({ type: 'success', message: `A fájl sikeresen előállítva!` }));
                                    setIsLoading(false);
                                })
                                .catch(() => {
                                    dispatch(remove());
                                    dispatch(
                                        create({ type: 'error', message: `Hiba történt a fájl előállítása során` }),
                                    );
                                });
                        } else {
                            dispatch(remove());
                            if (msg.includes('Unable to write file at location')) {
                                dispatch(
                                    create({
                                        type: 'error',
                                        message: `Sikertelen generálás, a generálandó fájl neve túl hosszú!`,
                                    }),
                                );
                            } else {
                                dispatch(create({ type: 'error', message: `Hiba történt a fájl előállítása során` }));
                            }
                            setIsLoading(false);
                            clearInterval(interval);
                        }
                    }
                }, 2000);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };
}

export default function CareerGuidanceSheetCellRenderer(params: GridRenderCellParams<TSelfAssessmentDocumentStatus>) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const exportContext = {
        initiateExport: careerGuidanceService.initiateExport,
        checkExport: careerGuidanceService.checkExport,
        downloadExport: careerGuidanceService.downloadExport,
    };

    return (
        <>
            <IconButton
                size="small"
                onClick={createExportHandleClick(Number(params.id), exportContext, dispatch, setIsLoading)}
                disabled={isLoading}
                style={{ borderWidth: '1px', borderStyle: 'solid' }}
                color="primary"
            >
                {isLoading ? <CircularProgress size={20} /> : <DescriptionIcon />}
            </IconButton>
        </>
    );
}
