import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { CancelButton } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import instance from '../../../../api';
import { FieldGenerator } from '../../../../components/Form/FieldGenerator';
import courseListService from '../../../../services/courseManagement/courseList';
import { RootState } from '../../../../store';
import { Option } from '../../../../utils/AppConst';
import { endpoints } from '../../../../utils/endPoints';
import { ITargetAudienceForm } from './types';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { TrainingProgramOption } from '../../../Admission/Riport/types';

export default function AddTargetAudienceForm({
    submitBtnTitle,
    handleClose,
    ...props
}: FormikProps<ITargetAudienceForm> & { submitBtnTitle: string; handleClose: () => void }) {
    const { count } = useSelector((state: RootState) => state.loading);
    const [tenantOptions, setTenantOptions] = useState<Option[]>([]);
    const [campusOptions, setCampusOptions] = useState<Option[]>([]);
    const [trainingLevelOptions, setTrainingLevelOptions] = useState<Option[]>([]);
    const [trainingProgramOptions, setTrainingProgramOptions] = useState<Option[]>([]);
    const [collegeYearOptions, setCollegeYearOptions] = useState<Option[]>([]);
    const { values } = props;
    const { courseId } = useParams() as { courseId: string };

    // Tenanthoz tartozó campusok és képzési szintek betöltése
    useEffect(() => {
        if (values.tenant) {
            if (+values.tenant === -1) {
                setCampusOptions([]);
                setTrainingLevelOptions([]);
            } else {
                axios
                    .all([
                        courseListService.campusesByTenant(values.tenant),
                        instance.get(endpoints.masterData.trainingLevels.byTenant(values.tenant)),
                    ])
                    .then(
                        axios.spread((campusResponse, trainingLevelResponse) => {
                            const campusOptions = campusResponse.data;
                            const trainingLevelOptions = trainingLevelResponse.data;

                            const updatedCampusOptions = [...campusOptions, { label: 'Összes', value: '-1' }];
                            const updatedTrainingLevelOptions = [
                                ...trainingLevelOptions,
                                { label: 'Összes', value: '-1' },
                            ];

                            setCampusOptions(updatedCampusOptions);
                            setTrainingLevelOptions(updatedTrainingLevelOptions);
                        }),
                    );
            }
        }
    }, [values.tenant]);

    useEffect(() => {
        if (values.tenant && values.trainingLevel.length > 0) {
            if (values.trainingLevel.some((t: Option) => t.value === '-1')) {
                setTrainingProgramOptions([]);
                setCollegeYearOptions([]);
            } else {
                instance
                    .get(
                        endpoints.masterData.trainingPrograms.byTenant(
                            values.tenant,
                            values.trainingLevel.map((t: Option) => t.value),
                        ),
                    )
                    .then((response) => {
                        const options = [...response.data, { label: 'Összes', value: '-1', trainingLevel: 'Összes' }];
                        setTrainingProgramOptions(options);
                    });
            }
        }
    }, [values.trainingLevel, values.tenant]);

    useEffect(() => {
        if (values.tenant && values.trainingLevel.length > 0 && values.trainingProgram.length > 0) {
            instance
                .get(endpoints.masterData.collegeYears.byTrainingProgram(values.trainingProgram))
                .then((response) => {
                    const options = [...response.data, { label: 'Összes', value: '-1' }];
                    setCollegeYearOptions(options);
                });
        }
    }, [values.trainingProgram, values.trainingLevel, values.tenant]);

    useEffect(() => {
        axios.all([
            courseListService.tenantsByCourse('' + courseId).then((res) => {
                setTenantOptions(res.data);
            }),
        ]);
    }, [courseId, values.id, values.tenant]);

    return (
        <>
            {FieldGenerator({
                name: 'tenant',
                label: 'Tenant',
                type: 'select',
                format: { xs: 12 },
                fieldType: 'base',
                options: tenantOptions,
                props: {
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        props.setFieldValue('tenant', e.target.value);
                        props.setFieldValue('campus', []);
                        props.setFieldValue('trainingLevel', []);
                        props.setFieldValue('trainingProgram', []);
                        props.setFieldValue('collegeYear', []);
                    },
                },
                ...props,
            })}

            {FieldGenerator({
                name: 'campus',
                label: 'Campus',
                type: 'multiselect',
                format: { xs: 12 },
                fieldType: 'base',
                options: campusOptions,
                props: {
                    onChange: (_e: unknown, value: Option[]) => {
                        props.setFieldValue('campus', value);
                        props.setFieldValue('trainingLevel', []);
                        props.setFieldValue('trainingProgram', []);
                        props.setFieldValue('collegeYear', []);
                    },
                    disabled: !values.tenant,
                },
                ...props,
            })}

            {!values.campus.some((c: Option) => c.value === '-1') &&
                FieldGenerator({
                    name: 'trainingLevel',
                    label: 'Képzési szint',
                    type: 'multiselect',
                    format: { xs: 12 },
                    fieldType: 'base',
                    options: trainingLevelOptions,
                    props: {
                        onChange: (_e: unknown, value: Option[]) => {
                            props.setFieldValue('trainingLevel', value);
                            props.setFieldValue('trainingProgram', []);
                            props.setFieldValue('collegeYear', []);
                        },
                        disabled: !values.tenant || trainingLevelOptions.length <= 1,
                    },
                    ...props,
                })}

            {!values.campus.some((c: Option) => c.value === '-1') &&
                !values.trainingLevel.some((t: Option) => t.value === '-1') &&
                FieldGenerator({
                    name: 'trainingProgram',
                    label: 'Képzési program',
                    type: 'multiselect',
                    format: { xs: 12 },
                    fieldType: 'base',
                    options: trainingProgramOptions,
                    props: {
                        groupBy: (option: TrainingProgramOption) => option.trainingLevel,
                        renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: Option) => (
                            <li {...props} style={{ color: 'black' }}>
                                {option.label}
                            </li>
                        ),
                        disabled: trainingProgramOptions.length <= 1,
                    },
                    ...props,
                })}

            {!values.campus.some((c: Option) => c.value === '-1') &&
                !values.trainingLevel.some((t: Option) => t.value === '-1') &&
                !values.trainingProgram.some((t: string) => t === '-1') &&
                FieldGenerator({
                    name: 'collegeYear',
                    label: 'Évfolyam',
                    type: 'multiselect',
                    format: { xs: 12 },
                    fieldType: 'base',
                    options: collegeYearOptions,
                    props: {
                        disabled: collegeYearOptions.length <= 1,
                    },
                    ...props,
                })}

            <Grid item container spacing={2} justifyContent="flex-end">
                <Grid item>
                    <LoadingButton loading={count > 0} variant="contained" type="submit">
                        {submitBtnTitle}
                    </LoadingButton>
                </Grid>
                <Grid item>
                    <CancelButton onClick={handleClose}>Bezár</CancelButton>
                </Grid>
            </Grid>
        </>
    );
}
