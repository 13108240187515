import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import '@silinfo/form-builder-2/lib/esm/components/FormPreview/style.css';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useState, SyntheticEvent, useEffect, useCallback } from 'react';
import { IForm2, IFormPreview2Params } from './types';
import { MultiFormRenderForm } from './MultiFormRenderForm';
import { withStyles } from '@mui/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import LinearProgressWithLabel from './LinearProgressWithLabel';
import { InfoBanner } from './utils';

const StyledTab = withStyles((theme: Theme) => ({
    root: {
        color: theme.palette.common.white,
        '&$selected': {
            color: theme.palette.primary.main,
        },
        '&.Mui-disabled': {
            color: theme.palette.common.white,
            WebkitTextFillColor: 'white',
        },
    },
    selected: {
        color: theme.palette.primary.main,
    },
}))(Tab);

export default function MultiFormPreview({
    image,
    dateFilled,
    forms,
    onSubmit,
    formToSet,
    setForm,
    groupDateFilled,
    isPageOneSaved,
    isPageTwoSaved,
    rules,
}: IFormPreview2Params) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const target = params.get('target');
    const [value, setValue] = useState(target === '2' ? '1' : '0');
    const [step, setStep] = useState(0);
    const [dateFilledNew, setDateFilled] = useState<boolean>(dateFilled);
    const [groupDateFilledNew, setGroupDateFilled] = useState<boolean>(groupDateFilled || false);
    const [validated, setValidated] = useState<boolean>(false);
    const th = useTheme();
    const matches = useMediaQuery(th.breakpoints.up('sm'));

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const getStep = useCallback((df: boolean, gdf?: boolean) => {
        if (gdf) return 2;
        if (df) return 1;
        return 0;
    }, []);

    useEffect(() => {
        setStep(getStep(dateFilledNew, groupDateFilledNew));
    }, [dateFilledNew, groupDateFilledNew, getStep]);

    let disabledTab2 = false; // Második kérdőív olvashatósága
    if (rules?.questionnaire2_read === 'if_first_is_finalized') {
        // csak akkor ha az első véglegesítésre került
        disabledTab2 = !dateFilledNew; // A readonly értéke azt mondja meg, hogy ki van-e töltve a fillDate az elsőnél, tehát véglegesítették-e
    }

    return (
        <Grid
            container
            style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                padding: '30px 0',
            }}
        >
            <Container maxWidth="lg">
                <Card variant="outlined" sx={{ bgcolor: 'ButtonShadow' }}>
                    <div style={{ height: '80vh', overflow: 'auto' }}>
                        <div style={{ padding: '16px' }}>
                            {image && <CardMedia component="img" src={image} alt="logo" style={{ width: '30%' }} />}
                            {dateFilledNew && groupDateFilledNew && !validated && <InfoBanner />}
                        </div>
                        <TabContext value={value}>
                            {!validated && (
                                <TabsBox
                                    forms={forms}
                                    handleChange={handleChange}
                                    value={value}
                                    matches={matches}
                                    disabledTab2={disabledTab2}
                                />
                            )}
                            {forms?.map((value: IForm2, index) => {
                                return (
                                    <TabPanel key={index} value={index.toString()}>
                                        <MultiFormRenderForm
                                            dateFilled={dateFilledNew}
                                            setDateFilled={setDateFilled}
                                            formToSet={formToSet[index] ?? {}}
                                            setForm={(form: Record<string, unknown>) =>
                                                setForm((prev) => {
                                                    return prev
                                                        .slice(0, index)
                                                        .concat(form)
                                                        .concat(prev.slice(index + 1));
                                                })
                                            }
                                            form={value}
                                            formIndex={index}
                                            onSubmit={onSubmit}
                                            rules={rules}
                                            setTab={setValue}
                                            groupDateFilled={groupDateFilled}
                                            setGroupDateFilled={setGroupDateFilled}
                                            isPageOneSaved={isPageOneSaved}
                                            isPageTwoSaved={isPageTwoSaved}
                                            validated={validated}
                                            setValidated={setValidated}
                                        />
                                    </TabPanel>
                                );
                            })}
                        </TabContext>
                    </div>
                </Card>
                {groupDateFilled ? null : (
                    <LinearProgressWithLabel
                        value={(forms?.length || 2) < 1 ? 100 : (Number(step) / (forms?.length || 2)) * 100}
                        error={false}
                    />
                )}
            </Container>
        </Grid>
    );
}

function TabsBox({
    forms,
    handleChange,
    value,
    matches,
    disabledTab2,
}: {
    forms: IForm2[] | undefined;
    handleChange: (event: SyntheticEvent, newValue: string) => void;
    value: string;
    matches: boolean;
    disabledTab2: boolean;
}) {
    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style={{ padding: '16px' }}>
            <AppBar position="static">
                <Tabs
                    variant={'fullWidth'}
                    onChange={handleChange}
                    value={value}
                    orientation={matches ? 'horizontal' : 'vertical'}
                    aria-label="Űrlapok"
                    className={'studentProfile'}
                >
                    {forms?.map((value: IForm2, index) => {
                        return (
                            <StyledTab
                                key={index}
                                label={value.name}
                                value={index.toString()}
                                disabled={index > 0 && disabledTab2}
                            />
                        );
                    })}
                </Tabs>
            </AppBar>
        </Box>
    );
}
