import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SubmitButton } from '@silinfo/front-end-template';
import { useNavigate } from 'react-router-dom';
import OffLayout from '../../components/OffLayout';

interface UnexpectedErrorProps {
    reset: () => void;
}

export default function UnexpectedError({ reset }: UnexpectedErrorProps) {
    const navigate = useNavigate();

    const handleClick = () => {
        reset();
        navigate('/');
    };

    return (
        <OffLayout>
            <Grid item container spacing={2}>
                <Grid item xs={12}>
                    <Typography align="center" variant="h2" component="h1">
                        Váratlan hiba
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="center" variant="h4">
                        Váratlan hiba történt, az üzemeltetők értesítést kaptak a hibáról.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <SubmitButton fullWidth size="large" onClick={handleClick}>
                        Vissza a főoldalra
                    </SubmitButton>
                </Grid>
            </Grid>
        </OffLayout>
    );
}
