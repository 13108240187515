import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { DownloadButton, Tooltip } from '@silinfo/front-end-template';
import { Children, Dispatch, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import instance from '../../../../../api';
import { RootState } from '../../../../../store';
import { create } from '../../../../../store/notification';
import { theme } from '../../../../../utils/theme';
import useFetch from './../../../../../utils/useFetch';
import { DynamicSection } from './interfaces';
import { exceptionFields, SectionRow } from './SectionGenerator';
import moment from 'moment';
import { formatDate } from '../../../../../utils/timezoneConverter';

const DataTable = ({
    section,
    setRow,
    refresh,
    setRefresh,
    readOnly,
    responseKey,
    hideDeleteButton,
}: {
    section: DynamicSection;
    setRow: Dispatch<SetStateAction<SectionRow | undefined>>;
    refresh: boolean;
    setRefresh: Dispatch<SetStateAction<boolean>>;
    readOnly?: boolean;
    responseKey?: string;
    hideDeleteButton?: boolean;
}) => {
    const { id } = useParams();
    const { user } = useSelector((state: RootState) => state.auth);
    const { count } = useSelector((state: RootState) => state.loading);
    const { data, loading } = useFetch<SectionRow[]>(
        section.url.replaceAll('{studentId}', id as string),
        responseKey ? responseKey : 'hydra:member',
        refresh,
    );
    const dispatch = useDispatch();

    const getCellValue = (key: string, value: string | Record<string, string>, id: string): string | JSX.Element => {
        if (exceptionFields.includes(key) && typeof value !== 'string') {
            return value?.countryName;
        }
        const f = section.fields.find((field) => field.name === key);
        if (!(f || key.toLowerCase().includes('filename'))) return '';
        if (key.toLowerCase().includes('filename') && typeof value === 'string') {
            const fileField = section.fields.find((field) => field.type === 'file');
            if (!fileField || fileField.type !== 'file') return '';
            return (
                <Tooltip title="Dokumentum letöltése">
                    <DownloadButton
                        url={fileField.downloadUrl.replaceAll('{documentId}', '' + id)}
                        downloadName={value}
                    />
                </Tooltip>
            );
        }

        if (f?.type === 'date' && typeof value === 'string') return moment(value).toDate().toLocaleDateString();
        if (f?.name === 'createdAt' && typeof value === 'string') return formatDate(value);

        return (
            f?.type === 'select' ? f.options.find((option) => option.value === value)?.label || value : value
        ) as string;
    };

    const DeleteButton = ({ row }: { row: SectionRow }) => {
        const [open, setOpen] = useState(false);
        const handleDelete = (row: SectionRow) => {
            if (!section.dynamic) return;
            instance
                .delete(section.url.split('/').slice(0, -1).join('/') + (row ? '/' + row.id : ''))
                .then(() => {
                    setRefresh((prev) => !prev);
                    dispatch(create({ type: 'success', message: 'Sikeres törlés!' }));
                })
                .catch(() => dispatch(create({ type: 'error', message: 'Hiba a törlés során' })))
                .finally(() => setOpen(false));
        };

        return (
            <>
                <Grid item>
                    <IconButton color="error" onClick={() => setOpen(true)}>
                        <Delete />
                    </IconButton>
                </Grid>
                <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                    <DialogTitle mb={3}>Törlés</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Biztosan törli az elemet?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} variant="outlined">
                            Mégsem
                        </Button>
                        <LoadingButton loading={count > 0} onClick={() => handleDelete(row)} variant="contained">
                            Törlés
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </>
        );
    };

    const Content = Children.toArray(
        (data as SectionRow[]).map((currentRow) => (
            <TableRow key={currentRow.id}>
                {Children.toArray(
                    Object.entries(currentRow)
                        .filter(([key, value]) => getCellValue(key, value as string, currentRow.id) !== '')
                        .map(([key, value]) => {
                            if (key === 'jobStartEnd' && value === null) {
                                return <TableCell key={key}>-</TableCell>;
                            }
                            return (
                                <TableCell key={key}>
                                    {getCellValue(key, value as string | Record<string, string>, currentRow.id)}
                                </TableCell>
                            );
                        }),
                )}
                {!readOnly && !user.archive && (
                    <TableCell>
                        <Grid container spacing={1}>
                            <Grid item>
                                <IconButton
                                    onClick={() => {
                                        if (currentRow.jobStartEnd === null) {
                                            currentRow.jobStartEnd = '';
                                        }
                                        setRow(currentRow);
                                    }}
                                    color="warning"
                                >
                                    <Edit />
                                </IconButton>
                            </Grid>
                            {!hideDeleteButton && <DeleteButton row={currentRow} />}
                        </Grid>
                    </TableCell>
                )}
            </TableRow>
        )),
    );

    if (loading) return <Skeleton variant="rectangular" />;
    return data.length > 0 ? (
        <Table>
            <TableHead sx={{ backgroundColor: theme.palette.secondary.main }}>
                <TableRow>
                    {Children.toArray(
                        section.fields.map((field) => (
                            <TableCell key={field.name} sx={{ color: theme.palette.secondary.contrastText }}>
                                {field.label}
                            </TableCell>
                        )),
                    )}
                    {!readOnly && <TableCell sx={{ color: theme.palette.secondary.contrastText }}>Műveletek</TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>{Content}</TableBody>
        </Table>
    ) : (
        <Typography>Nincs megjeleníthető adat</Typography>
    );
};

export default DataTable;
