import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import '@silinfo/form-builder-2/lib/esm/components/FormPreview/style.css';
import { useState } from 'react';
import { IFormPreview2Params, IForm2 } from '../../../../components/PreviewTemplate/types';
import { RenderForm } from './RenderForm';
import { InfoBanner } from '../../../../components/PreviewTemplate/utils';

export default function FormPreview({
    image,
    dateFilled,
    isPageSaved,
    forms,
    onSubmit,
    formToSet,
    setForm,
    setValidated,
    validated,
}: IFormPreview2Params) {
    const [dateFilledNew, setDateFilled] = useState<boolean>(dateFilled);

    return (
        <Grid
            container
            style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                padding: '30px 0',
            }}
        >
            <Container maxWidth="lg">
                <Card variant="outlined" sx={{ bgcolor: 'ButtonShadow' }}>
                    <div style={{ height: '80vh', overflow: 'auto' }}>
                        <div style={{ padding: '16px' }}>
                            {image ? (
                                <CardMedia component="img" src={image} alt="logo" style={{ width: '30%' }} />
                            ) : null}
                            {dateFilledNew && !validated && <InfoBanner />}
                        </div>
                        {forms?.map((value: IForm2, index) => {
                            return (
                                <RenderForm
                                    key={index}
                                    dateFilled={dateFilledNew}
                                    setDateFilled={setDateFilled}
                                    formToSet={formToSet[index] ?? {}}
                                    setForm={(form: Record<string, unknown>) =>
                                        setForm((prev) => {
                                            return prev
                                                .slice(0, index)
                                                .concat(form)
                                                .concat(prev.slice(index + 1));
                                        })
                                    }
                                    form={value}
                                    formIndex={index}
                                    onSubmit={onSubmit}
                                    setValidated={setValidated}
                                    isPageSaved={isPageSaved}
                                />
                            );
                        })}
                    </div>
                </Card>
            </Container>
        </Grid>
    );
}
