import IconButton from '@mui/material/IconButton';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useContext } from 'react';
import PDFDownloader from '../../../../components/PDFDownloader';
import { STATUS } from '../../../../studentPages/SelfAssessment/CellRenderers/Status/utils';
import SemesterContext from '../../../../components/SemesterSelector/SemesterContextProvider';
import { endpoints } from '../../../../utils/endPoints';
import ComponentToPrint from './../../../MasterData/General/Students/StudentView/CollapsibleTable/ComponentToPrint';
import SelfAssessmentQuestionnaireCellRenderer from './SelfAssessmentQuestionnaire';
//import SummaryDocumentCellRenderer from './SummaryDocumentCellRenderer';
import UniversityIndexCellRenderer from './UniversityIndexCellRenderer';
import { componentMap, conversationMapper, dateStyle } from './utils';
import PreviousSummaryDocumentCellRenderer from './PreviousSummaryDocumentCellRenderer';
import CareerGuidanceSheetCellRenderer from './CareerGuidanceSheetCellRenderer';
import CommissionEvaluationCellRenderer from './CommissionEvaluationCellRenderer';

const DocumentCellRenderer = (params: GridRenderCellParams) => {
    const { value } = params;
    const semesterFromContext = useContext(SemesterContext);
    const semester = params.row.semesterId || semesterFromContext;
    const key = params.colDef.field;
    if (value === 'none') {
        return componentMap.none;
    }

    if (key === 'completionSheet') {
        return (
            <PDFDownloader
                url={endpoints.selfEvaluation.report.pdf(semester, '' + params.id)}
                ComponentToPrint={ComponentToPrint}
            />
        );
    }

    if (key === 'previousSummary') {
        return <PreviousSummaryDocumentCellRenderer {...params} />;
    }

    if (key === 'selfAssessmentQuestionnaire') {
        return <SelfAssessmentQuestionnaireCellRenderer {...params} />;
    }

    if (key === 'universityIndex') {
        return <UniversityIndexCellRenderer {...params} />;
    }

    if (key === 'careerGuidanceSheet') {
        return <CareerGuidanceSheetCellRenderer {...params} />;
    }

    if (key === 'selfAssessmentConversation' && params.row.selfAssessmentQuestionnaire === STATUS.COMPLETED) {
        return (
            <div style={{ textAlign: 'center' }}>
                <IconButton>{componentMap[conversationMapper.get(value) || 'none']}</IconButton>
                <div style={dateStyle}>{''}</div>
            </div>
        );
    }

    if (key === 'commissionEvaluation') {
        return <CommissionEvaluationCellRenderer {...params} />;
    }

    return componentMap.none;
};

export default DocumentCellRenderer;
