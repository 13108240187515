import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { TableButton } from '@silinfo/front-end-template';
import useOpen from '../../utils/useOpen';
import Dialog from '@mui/material/Dialog';
import ActionDialogContent from './ActionDialogContent';
import { useCallback, useEffect, useState } from 'react';
import { clientEndPoints } from '../../utils/clientEndPoints';
import Success from '../SelfAssessment/CellRenderers/Status/Icons/Success';
import Error from '../SelfAssessment/CellRenderers/Status/Icons/Error';

export interface Questionnaire {
    courseId: number;
    courseName: string;
    courseCode: string;
    semester: string;
    deadline: string | null;
    anonym: boolean;
    sharedType: boolean;
    isFilled: boolean;
}

export interface OperationsMobileProps {
    row: Questionnaire;
}

const OperationsMobile: React.FC<OperationsMobileProps> = ({ row }) => {
    const { isOpen, open, close } = useOpen();
    const [timeZone, setTimeZone] = useState('Europe/Budapest');

    useEffect(() => {
        setTimeZone(localStorage.getItem('timezone') ?? 'Europe/Budapest');
    }, []);

    const openQuestionnaire = useCallback(() => {
        window.open(clientEndPoints.student_questionnaires_fill.replace(':id', '' + row.courseId), '_blank');
    }, [row.courseId]);

    const handleOpen = () => {
        if (row.anonym) {
            openQuestionnaire();
        } else {
            open();
        }
    };

    return (
        <>
            {(() => {
                if (row.isFilled) {
                    return <Success />;
                }

                if (
                    row.deadline &&
                    new Date(row.deadline).toLocaleDateString('hu-HU', { timeZone: timeZone }) <
                        new Date().toLocaleDateString('hu-HU', { timeZone: timeZone })
                ) {
                    return <Error />;
                }

                return (
                    <TableButton color="primary" onClick={handleOpen}>
                        <DriveFileRenameOutlineIcon />
                    </TableButton>
                );
            })()}
            <Dialog open={isOpen} onClose={close} fullWidth>
                <ActionDialogContent close={close} isAnonymous={!!row.anonym} id={+row.courseId} />
            </Dialog>
        </>
    );
};

export default OperationsMobile;
