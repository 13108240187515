import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

interface StatusResetDialogProps {
    open: boolean;
    onClose: () => void;
    onResetStatus: (status: 'wait_to_fill_student' | 'wait_to_fill_administrator') => void;
    studentName: string;
    studentEmail: string;
    semester: string;
    isGroupTemplate: boolean;
}

const StatusResetDialog: React.FC<StatusResetDialogProps> = ({
    open,
    onClose,
    onResetStatus,
    studentName,
    studentEmail,
    semester,
    isGroupTemplate,
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Önértékelés és célkitűzés visszaállítása</DialogTitle>
            <DialogContent>
                <Typography variant="body1" sx={{ marginTop: '16px', textAlign: 'left' }}>
                    Mely státuszra szeretnéd visszaállítani a kérdőívet?
                </Typography>
                <TableContainer sx={{ marginTop: '12px' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Hallgató</TableCell>
                                <TableCell>
                                    {studentName} ({studentEmail})
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Kérdőív</TableCell>
                                <TableCell>Önértékelés és célkitűzés</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Szemeszter</TableCell>
                                <TableCell>{semester}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{ color: '#f3ca12', borderColor: '#f3ca12' }} variant="outlined">
                    Mégsem
                </Button>
                <Button
                    onClick={() => onResetStatus('wait_to_fill_student')}
                    sx={{
                        backgroundColor: '#ff6961',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#e85b52',
                        },
                    }}
                    variant="contained"
                >
                    Hallgatói kitöltés
                </Button>

                {isGroupTemplate && (
                    <Button
                        onClick={() => onResetStatus('wait_to_fill_administrator')}
                        sx={{ backgroundColor: '#f3ca12', color: 'white' }}
                        variant="contained"
                    >
                        Admin kitöltés
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default StatusResetDialog;
