import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import DoneIcon from '@mui/icons-material/Done';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export default function LinearProgressWithLabel({ value, error }: { value: number; error: boolean }) {
    const isDone = value === 100 || error;
    const doneColor = error ? 'error' : 'success';

    return (
        <Grid item xs={12} style={{ position: 'fixed', left: 0, right: 0, bottom: 0 }}>
            <LinearProgress
                variant={'determinate'}
                value={error ? 100 : value}
                color={isDone ? doneColor : 'info'}
                style={{ height: 25 }}
            />
            {isDone ? (
                <div
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        left: '50%',
                        transform: 'translate(-50%, 0%)',
                        color: 'white',
                        display: 'flex',
                    }}
                >
                    {error ? <PriorityHighIcon /> : <DoneIcon />}
                </div>
            ) : (
                <Typography
                    variant="body1"
                    style={{ position: 'fixed', bottom: 0, left: '50%', transform: 'translate(-50%, 0)' }}
                    color={value >= 50 ? 'white' : 'GrayText'}
                >
                    {`${Math.round(value)}%`}
                </Typography>
            )}
        </Grid>
    );
}
