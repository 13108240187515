import { Tooltip } from '@silinfo/front-end-template';
import { theme } from '../../../../utils/theme';
import { DefaultCellRendererProps, useStylesTooltip } from './SemesterStatusCellRenderer';
import { QuestionnaireTemplateType, TYPE_QUESTIONNAIRE } from '../utils';

export default function QuestionnaireStatusCellRenderer({
    params,
    type,
}: DefaultCellRendererProps & { type: QuestionnaireTemplateType }) {
    const classes = useStylesTooltip();
    const active = type === TYPE_QUESTIONNAIRE ? params.row.active : params.row.evaluationSheetActive;
    const activedAt = type === TYPE_QUESTIONNAIRE ? params.row.activatedAt : params.row.evaluationSheetActivatedAt;

    const label = active ? `Aktiválva ${activedAt ? `(${activedAt})` : ''}` : 'Nincs aktiválva';
    const style = active ? { color: theme.palette.success.main } : undefined;

    return (
        <Tooltip title={label}>
            <div className={classes.truncate} style={style}>
                {label}
            </div>
        </Tooltip>
    );
}
