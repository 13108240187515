import IconButton from '@mui/material/IconButton';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { STATUS } from '../../../../studentPages/SelfAssessment/CellRenderers/Status/utils';
import { TSelfAssessmentDocumentStatus } from '../types';
import { componentMap, dateStyle } from './utils';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import PendingIcon from '@mui/icons-material/Pending';
import EndpointProvider from '../../../../components/EndpointProvider';
import { useState } from 'react';

export default function CommissionEvaluationCellRenderer(params: GridRenderCellParams) {
    const key = (params.row.commissionEvaluationSheet as TSelfAssessmentDocumentStatus) || STATUS.UNAVAILABLE;
    const Core = componentMap[key];

    const handleClick = () => {
        const url =
            params.row.commissionEvaluationSheet === 'wait_to_fill'
                ? clientEndPoints.self_assessment_commission_evaluation_sheet_fill.replace(':id', '' + params.row.id)
                : clientEndPoints.self_assessment_commission_evaluation_sheet_preview.replace(
                      ':id',
                      '' + params.row.id,
                  );

        window.open(url, '_blank');
    };

    const endpoint =
        params.row.commissionEvaluationSheet === STATUS.COMPLETED
            ? 'self_assessment_commission_evaluation_sheet_preview'
            : 'self_assessment_commission_evaluation_sheet_fill';
    const [childHasAccess, setChildHasAccess] = useState<boolean>(false);

    const renderCellWithDate = (icon: JSX.Element, date: string | null, childHasAccess: boolean) => (
        <EndpointProvider endpoints={[endpoint]} onChildAccessChange={setChildHasAccess} forceShow={true}>
            <div style={{ textAlign: 'center' }}>
                <IconButton onClick={handleClick} disabled={childHasAccess}>
                    {icon}
                </IconButton>
                <div style={dateStyle}>{date || ''}</div>
            </div>
        </EndpointProvider>
    );

    if (key === STATUS.UNAVAILABLE) {
        return Core;
    }

    if (params.row.commissionEvaluationSheet === 'wait_to_fill') {
        return renderCellWithDate(
            <PendingIcon style={{ color: childHasAccess ? '#B0B0B0' : '#22344C' }} />,
            params.row?.commissionEvaluationSheetFillDate,
            childHasAccess,
        );
    }

    return renderCellWithDate(Core, params.row?.commissionEvaluationSheetFillDate, childHasAccess);
}
