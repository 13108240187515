import IconButton from '@mui/material/IconButton';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback } from 'react';
import { STATUS } from '../../../../studentPages/SelfAssessment/CellRenderers/Status/utils';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { TSelfAssessmentPreviousDocumentStatus } from '../types';
import { componentMap, dateStyle } from './utils';

const handleClick = (id: string | number) =>
    window.open(clientEndPoints.self_assessment_previous_summary_preview.replace(':id', '' + id), '_blank');

export default function PreviousSummaryDocumentCellRenderer(
    params: GridRenderCellParams<TSelfAssessmentPreviousDocumentStatus>,
) {
    const Core = componentMap[params.value || 'none'];
    const onClick = useCallback(() => {
        handleClick(params.id);
    }, [params.id]);

    return (
        <div style={{ textAlign: 'center' }}>
            {params.value === STATUS.AVAILABLE && (
                <div>
                    <IconButton onClick={onClick}>{Core}</IconButton>
                    <div style={dateStyle}>{params.row.previousSummaryFillDate || ''}</div>
                </div>
            )}
        </div>
    );
}
