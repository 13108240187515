import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { findLabelByValue } from '../../../../utils/AppConst';
import { IUser, TrainingLevel, TrainingProgram, View } from '../../../../utils/Interfaces/interfaces';
import { StatusCellRenderer, TerminatingCellRenderer } from '../Users';
import { actualStatusOptions, genderOptions, semesterStatusOptions } from './Form/options';
import Operations from './Operations';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import Chip from '@mui/material/Chip';

const baseColumns: GridColDef[] = [
    {
        headerName: 'E-mail cím',
        field: 'email',
        valueGetter: (params: GridValueGetterParams<unknown, { user: IUser }>) => params?.row?.user?.email,
    },
    {
        headerName: 'Vezetéknév',
        field: 'lastName',
        valueGetter: (params: GridValueGetterParams<unknown, { user: IUser }>) => params?.row?.user?.lastName,
    },
    {
        headerName: 'Keresztnév',
        field: 'firstName',
        valueGetter: (params: GridValueGetterParams<unknown, { user: IUser }>) => params?.row?.user?.firstName,
    },
    {
        headerName: 'Nem',
        field: 'gender',
        valueGetter: (params: GridValueGetterParams<unknown, { gender: string }>) =>
            findLabelByValue(params?.row?.gender, genderOptions),
        flex: 0.5,
        minWidth: 60,
        align: 'center',
    },
    {
        headerName: 'Campus',
        field: 'campus',
        valueGetter: (params: GridValueGetterParams<unknown, { user: IUser }>) =>
            params?.row?.user?.campus?.name || '-',
    },
    {
        headerName: 'Képzési szint',
        field: 'currentTrainingLevel',
        valueGetter: (params: GridValueGetterParams<unknown, { currentTrainingLevel: TrainingLevel }>) =>
            params.row.currentTrainingLevel?.name,
    },
    {
        headerName: 'Képzési program',
        field: 'currentTrainingProgram',
        valueGetter: (params: GridValueGetterParams<unknown, { currentTrainingProgram: TrainingProgram }>) =>
            params.row.currentTrainingProgram?.name,
    },
    {
        headerName: 'Évfolyam',
        field: 'currentCollegeYear',
        valueGetter: (params: GridValueGetterParams<unknown, { currentCollegeYear: { name: string } }>) =>
            params.row.currentCollegeYear?.name,
        flex: 0.5,
        minWidth: 80,
        align: 'center',
    },
    {
        headerName: 'Szemeszer státusz',
        field: 'semesterStatus',
        valueGetter: (params: GridValueGetterParams<unknown, { semesterStatus: string }>) =>
            findLabelByValue(params.row.semesterStatus, semesterStatusOptions),
        align: 'center',
    },
    {
        headerName: 'Aktuális státusz',
        field: 'currentMembershipStatus',
        flex: 0.5,
        minWidth: 130,
        align: 'center',
        valueGetter: (params: GridValueGetterParams<unknown, { currentMembershipStatus: string }>) =>
            findLabelByValue(params.row.currentMembershipStatus, actualStatusOptions),
    },
    {
        headerName: 'Új hallgató',
        field: 'newStudent',
        renderCell: (params: GridRenderCellParams) => (
            <Chip
                color={params.row.newStudent ? 'secondary' : 'success'}
                label={params.row.newStudent ? 'Igen' : 'Nem'}
            />
        ),
        flex: 0.5,
        minWidth: 100,
        align: 'center',
        sortable: false,
    },
    {
        headerName: 'Letiltva',
        field: 'user.disabled',
        renderCell: (params: GridRenderCellParams) => <StatusCellRenderer params={params} field="disabled" />,
        flex: 0.5,
        minWidth: 100,
        align: 'center',
    },
    {
        headerName: 'Kiiratkozva',
        field: 'terminating',
        renderCell: TerminatingCellRenderer,
        flex: 0.5,
        minWidth: 100,
        align: 'center',
    },
];

export const columns = (setRefresh: () => void, view: View, readOnly: boolean): GridColDef[] =>
    [
        ...baseColumns,
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: (params: GridRenderCellParams) => Operations(params, view, readOnly, setRefresh),
            minWidth: 150,
        },
    ].map((col) => ({ minWidth: 140, flex: 1, headerAlign: 'center', ...col })) as GridColDef[];
