import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

interface CommissionStatusResetDialogProps {
    open: boolean;
    onClose: () => void;
    onResetStatus: (status: 'wait_to_fill_student' | 'wait_to_fill_administrator' | 'wait_to_fill_commission') => void;
    studentName: string;
    studentEmail: string;
    semester: string;
}

const CommissionStatusResetDialog: React.FC<CommissionStatusResetDialogProps> = ({
    open,
    onClose,
    onResetStatus,
    studentName,
    studentEmail,
    semester,
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Bizottsági értékelő lap visszaállítása</DialogTitle>
            <DialogContent>
                <Typography variant="body1" sx={{ marginTop: '16px', textAlign: 'left' }}>
                    Biztosan vissza szeretnéd állítani a véglegesített kérdőívet?
                </Typography>
                <TableContainer sx={{ marginTop: '12px' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Hallgató</TableCell>
                                <TableCell>
                                    {studentName} ({studentEmail})
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Kérdőív</TableCell>
                                <TableCell>Bizottsági értékelő lap</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Szemeszter</TableCell>
                                <TableCell>{semester}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{ color: '#f3ca12', borderColor: '#f3ca12' }} variant="outlined">
                    Mégsem
                </Button>
                <Button
                    onClick={() => onResetStatus('wait_to_fill_commission')}
                    sx={{ backgroundColor: '#f3ca12', color: 'white' }}
                    variant="contained"
                >
                    Megerősítés
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CommissionStatusResetDialog;
