import { endpoints } from '../../../../../utils/endPoints';
import StudentView from '../StudentView';
import {
    competitions,
    residence,
    resume,
    useBaseFormFields,
    useEducation,
    useForeignScholarship,
    useInternship,
    useJobs,
    useLanguageCertification,
    useMentoring,
    usePersonalData,
    useStudies,
} from './fields';
import { DetailedForm, DetailedFormSection } from './interfaces';
import ActualUniversityStudiesSection from './ActualUniversityStudies/ActualUniversityStudiesSection';
import LangExamField from './LangExamField';
import SelfAssessments from '../SelfAssessments';
import Scholarships from './Scholarships';
import { useParams } from 'react-router-dom';
import MentoringField from './MentoringField';

const usePersonalDataSection = (): Omit<DetailedFormSection, 'formikProps'>[] => {
    const { id } = useParams();
    const baseFormFields = useBaseFormFields(undefined, undefined, id ? true : false);
    const personalData = usePersonalData();

    return [
        {
            name: 'Alapadatok',
            fields: baseFormFields,
        },
        ...(personalData.length > 0
            ? [
                  {
                      name: 'Bővített alapadatok',
                      fields: personalData,
                  },
              ]
            : []),
        {
            name: 'Lakhely',
            fields: residence,
        },
    ];
};

const useFurtherStudies = (): DetailedFormSection[] => {
    const education = useEducation();
    const internship = useInternship();
    const foreignerScholarship = useForeignScholarship();
    const languageCertification = useLanguageCertification();
    const jobs = useJobs();
    const studies = useStudies();
    const mentoring = useMentoring();

    return [
        {
            name: 'Aktuális egyetemi tanulmányok',
            fields: studies,
            dynamic: true,
            nameInForm: 'resume',
            url: endpoints.masterData.students.actualUniversityStudies.main,
            custom: <ActualUniversityStudiesSection />,
        },
        {
            name: 'Önéletrajz',
            fields: resume(),
            dynamic: true,
            nameInForm: 'resume',
            url: endpoints.masterData.students.curriculumVitae.main,
        },
        {
            name: 'Végzettség',
            fields: education,
            dynamic: true,
            nameInForm: 'education',
            url: endpoints.masterData.students.certification.main,
        },
        {
            name: 'Nyelvvizsga',
            fields: languageCertification,
            dynamic: true,
            nameInForm: 'languageCertification',
            url: endpoints.masterData.students.languageExams.main,
            custom: <LangExamField />,
        },
        {
            name: 'Külföldi ösztöndíj',
            fields: foreignerScholarship,
            dynamic: true,
            nameInForm: 'foreignerScholarship',
            url: endpoints.masterData.students.foreignScholarship.main,
        },
        {
            name: 'Szakmai gyakorlat',
            fields: internship,
            dynamic: true,
            nameInForm: 'internship',
            url: endpoints.masterData.students.internship.main,
        },
        {
            name: 'Tanulmányi versenyek',
            fields: competitions(),
            dynamic: true,
            nameInForm: 'competitions',
            url: endpoints.masterData.students.competition.main,
        },
        {
            name: 'Munkahely',
            fields: jobs,
            dynamic: true,
            nameInForm: 'jobs',
            url: endpoints.masterData.students.workplace.main,
        },
        {
            name: 'Mentoring',
            fields: mentoring,
            dynamic: true,
            nameInForm: 'mentoring',
            url: endpoints.masterData.students.mentoring.main,
            custom: <MentoringField />,
        },
    ];
};

const trainingDataSection: DetailedFormSection[] = [
    {
        name: '',
        fields: [],
        custom: <StudentView />,
        dynamic: true,
        nameInForm: 'trainingData',
        url: '',
    },
];

const scholarshipSection: DetailedFormSection[] = [
    {
        name: '',
        fields: [],
        custom: <Scholarships />,
        dynamic: true,
        nameInForm: 'scholarship',
        url: '',
    },
];

const selfAssessmentSection: DetailedFormSection[] = [
    {
        name: '',
        fields: [],
        custom: <SelfAssessments />,
        dynamic: true,
        nameInForm: 'selfAssessment',
        url: '',
    },
];

export const useDetailedForm = (): DetailedForm[] => {
    const personalDataSection = usePersonalDataSection();
    const furtherStudies = useFurtherStudies();
    return [
        {
            name: 'Személyes adatok',
            sections: personalDataSection,
        },
        {
            name: 'Képzés és teljesítési adatok',
            sections: trainingDataSection,
        },
        {
            name: 'Ösztöndíjak',
            sections: scholarshipSection,
        },
        {
            name: 'Önértékelések',
            sections: selfAssessmentSection,
        },
        {
            name: 'További tanulmányok',
            sections: furtherStudies,
        },
    ];
};
